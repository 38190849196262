import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import Datatable from "../Components/Datatable"
import { useParams } from 'react-router-dom';

function TeacherListDetails() {

    const { user } = useAuth();
    const { t, i18n } = useTranslation()
    const [msg, setMsg] = useState([]);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const params = useParams();


    const columnsDetail = React.useMemo(
        () => [
            {
                Header: 'ID',
                Footer: 'ID',
                accessor: 'id',
            },
            {
                Header: t('namesurname'),
                Footer: t('namesurname'),
                accessor: 'fullName',
            },
            {
                Header: t('username'),
                Footer: t('username'),
                accessor: 'username',
            },
            {
                Header: t('createdAt'),
                Footer: t('createdAt'),
                accessor: 'createdAt',
            },
            {
                Header: t('lastLogin'),
                Footer: t('lastLogin'),
                accessor: 'lastLogin',
            },
            {
                Header: t('edit'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: t('edit'),
            },
        ],
        [],
    )
    const handleEdit = (row) => {
        window.location.href = "/dashboard/teacher/editstudent/" + row['id'];
    }

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetClassStudents";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        classId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isDeleted'] === false) {
                                    students.push({
                                        "id": res.data['data'][i]['id'],
                                        "fullName": res.data['data'][i]['fullName'],
                                        "username": res.data['data'][i]['username'],
                                        "createdAt": res.data['data'][i]['createdAt'],
                                        "lastLogin": res.data['data'][i]['lastLogin'],
                                    })
                                }

                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);

        })()
    }, [])


    return (
        <div>


            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("studentList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={students} columns={columnsDetail} tableBaslik={t("studentList")} />
                    </div>
                )}
            </div>



        </div>
    );
}

export default TeacherListDetails;

