import React, { useState, useEffect } from "react";
import { useAuth } from '../../context/AuthContext';
import RequestHelper from '../../Helpers/RequestHelper';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TicketDetail = () => {
    const params = useParams();
    const [ticket, setTicket] = useState([]);
    const { t, i18n } = useTranslation();

    function handleSubmit(event) {
        
    }
    const data = [
        {
            "id": 1,
            "tickettitle": "hesabım aktif değil",
            "tickettext": "dün para yatırdım fakat hesabım aktif olmadı",
            "time": "17:10",
            "date": "13:03:2024",
            "isFinished": 1,
            "Conversation": [
                {
                    "id": 1,
                    "answeredName": "admin",
                    "time": "17:15",
                    "date": "13:03:2024",
                    "answer": "efendim ödeme bildirimi yapmamış olarak görüyorum. yaparsanız yardımcı olalım"
                }
                ,
                {
                    "id": 2,
                    "answeredName": "user",
                    "time": "17:20",
                    "date": "13:03:2024",
                    "answer": "nereden yapabilirim"
                }
                ,
                {
                    "id": 3,
                    "answeredName": "admin",
                    "time": "17:25",
                    "date": "13:03:2024",
                    "answer": "/profile/payment  linki üzerinden yapabilirsiniz."
                }
                ,
                {
                    "id": 4,
                    "answeredName": "user",
                    "time": "17:30",
                    "date": "13:03:2024",
                    "answer": "tamamdır yaptım "
                }
                ,
                {
                    "id": 5,
                    "answeredName": "admin",
                    "time": "17:35",
                    "date": "13:03:2024",
                    "answer": "onaylanmıştır. aramıza hoşgeldiniz."
                }
            ]
        }
    ]
    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/tickets", "get", "", params['id'])
            setTicket(res.data);
        })()

    }, [])
    return (
        <div className="container">
            <div>
                <div class="shadow-lg p-3 mb-5 bg-body rounded">
                    <h3> {t("ticketTitle")} : {ticket.ticketTitle}</h3> <br/>
                    <h4>{t("ticketText")} : {ticket.ticketText} </h4><br/>
                    <h4>
                    {t("ticketstate")}: {ticket.isFinished ? <span>{t("ticketfinished")}</span> : <span>{t("ticketwaiting")}</span>}
                    </h4>
                    <br/>
                    <h6>{t("ticketCreatedDate")}:{ticket.createdDate}</h6>
                </div>
                <div class="shadow-lg p-3 mb-5 bg-body rounded">
                    {ticket.Conversation && ticket.Conversation.map((dt) => {
                        if (dt.answeredName === "admin") {
                            return (
                                <div>
                                    <div className="row" key={dt.id}>
                                        <div className="col-3">
                                            <h3> {dt.answeredName}</h3>
                                            <h4>{dt.createdDate}</h4>
                                        </div>
                                        <div className="col-9">
                                            <span>
                                                {dt.answer}
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div>
                                    <div className="row" key={dt.id}>
                                        <div className="col-9">
                                            <span>
                                                {dt.answer}
                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <h3> {dt.answeredName}</h3>
                                            <h4>{dt.createdDate}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            )
                        }
                    })
                    }
                </div>
            </div>            
            <div style={{ textAlign: "center" }}>
                <h1>{t("answer")}</h1>
            </div>

            <form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="text" class="form-label">{t("answer")}</label>
                    <textarea class="form-control" id="text"  />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>

            </form>
        </div>
    );
};
export default TicketDetail;
