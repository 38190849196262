import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';
import axios from "axios";

export const AboutUs = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [about, setAbout] = useState([]);
    const [image, setImage] = useState('');

    function handleChange(e) {
        const formData = new FormData();
        formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
        setImage(formData);
    }

    const aboutImage = async () => {
        const headerss = {
            "accept": "*/*",
            "Content-Type": "image/jpeg"
        }
        await axios.post('https://express.heryerdeingilizce.com/aboutimage', image, {
            headers: headerss
        }).then(res =>{          
                console.log(res);           
        });


        // const formmData = new FormData()
        // formmData.append('image1453', image)
        // axios.post('url', formmData).then((res) => {
        //     console.log(res);
        // })
    }



    function customerCount(e) {
        const datas = {
            "count": document.getElementById("musteriSayisi").value
        }
        RequestHelper("/customerCount", "put", datas, "1").
            then(
                response => {
                    alert(response.statusText)
                });
       // e.preventDefault();   // bu olunca sayfa reflesh olmuyor.
    }






    function aboutus(e) {
        let title = document.getElementById("title").value;
        let mainText = document.getElementById("mainText").value;
        if (title === "") {
            title = about.title;
        }
        if (mainText === "") {
            mainText = about.mainText;
        }
        let subTextss = [];

        let subText1 = document.getElementById("1").value;
        if (subText1 === "") {
            subTextss[0] = {
                "id": 1,
                "text": about.subTexts[0].text
            }
        }
        else {
            subTextss[0] = {
                "id": 1,
                "text": subText1
            }
        }
    
        let subText2 = document.getElementById("2").value;
        if (subText2 === "") {
            subTextss[1] = {
                "id": 2,
                "text": about.subTexts[1].text
            }
        }
        else {
            subTextss[1] = {
                "id": 2,
                "text": subText2
            }
        }

        let subText3 = document.getElementById("3").value;
        if (subText3 === "") {
            subTextss[2] = {
                "id": 3,
                "text": about.subTexts[2].text
            }
        }
        else {
            subTextss[2] = {
                "id": 3,
                "text": subText3
            }
        }



        const datas = {
            "title": title,
            "mainText":mainText,
            "imageUrl": "images/images/about-img.jpg",
            "subTexts": subTextss
        }
        RequestHelper("/aboutus", "put", datas, "1").then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/customerCount", "get", "", "1")
            const about = await RequestHelper("/aboutus", "get", "", "1")
            setAbout(about.data);
            setData(res.data);
            console.log(about.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('customercount')}</h2>
            <form onSubmit={customerCount}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="musteriSayisi" class="form-label">{t('customercount')}</label>
                        <textarea class="form-control" id="musteriSayisi" placeholder={data.count} required />
                    </div>

                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                </div>
            </form>
            <hr />
            <h2>
                {t('aboutussettings')}
            </h2>
            <form onSubmit={aboutus}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">
                            {t('aboutustitle')}
                        </label>
                        <textarea class="form-control" id="title" placeholder={about.title} />
                    </div>
                </div>

                <div class="mb-3">
                    <div class="mb-3">
                        <label for="mainText" class="form-label">
                            {t('aboutustext')}
                        </label>
                        <textarea class="form-control" id="mainText" placeholder={about.mainText} />
                    </div>
                </div>


                {about.subTexts && about.subTexts.map((dt) => {
                    if (dt.id < 4) {
                        return (
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label for={dt.id} class="form-label">{t('features')} {dt.id}</label>
                                    <textarea class="form-control" id={dt.id} placeholder={dt.text} />
                                </div>
                            </div>
                        )
                    }
                })
                }


                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                </div>
            </form>
            <div className='row'>
                <h6>Kapak Resmi</h6><br/>
                    <div className='col-md-6'>
                    <img src="/images/about.png" />
                    </div>
                    <div className='col-md-6'>
                    <div style={{ alignItems: "center" }}>
                        <form onSubmit={aboutImage}>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="aboutImage" required />
                            <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                        </form>
                    </div>
                    </div>
                </div>
        </div>
    )
}
export default AboutUs;