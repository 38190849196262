import React, { useState } from 'react'
import Swal from 'sweetalert2'
import $ from 'jquery';

function Question2(props) {

    return (
        <div>
            cort
            <button onClick={() => props.increment()}>
                zort
            </button>
        </div>
    )
}
export default Question2;