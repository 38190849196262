import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const AdminPaymentEdit = () => {
    const params = useParams();
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        let url = "";
        if (params['type'] === "deposit-inv") {
            url = "https://api.heryerdeingilizce.com/api/admin/Subscription/InvDeposit";
        }
        if (params['type'] === "extend-inv") {
            url = "https://api.heryerdeingilizce.com/api/admin/Subscription/InvExtent";
        }
        if (params['type'] === "deposit-co") {
            url = "https://api.heryerdeingilizce.com/api/admin/Subscription/CoDeposit";
        }


        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            await axios({
                method: 'Get',
                url: url,
                params: {
                    transactionId: params['id'],
                    note: document.getElementById("note").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);

                    }
                }
                else {
                    Swal.fire({
                        title: "hata!",
                        icon: "error",
                        confirmButtonText: `Tamam`
                    });
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }




    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("editPayment")}</h1>
            </div>
            <h4> {msg} </h4>
            <form onSubmit={handleSubmit}>
                <div class="mb-3">
                    <label for="note" class="form-label">{t("transactionNote")}</label>
                    <textarea class="form-control" id="note" required />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("accept")}</button>
                </div>

            </form>
        </div>
    );
};
export default AdminPaymentEdit;


