import React, { Component } from "react";
import img1 from '../../images/1.jpg';
import img2 from '../../images/2.jpg';
import img3 from '../../images/3.jpg';
import img4 from '../../images/4.jpg';

class TeacherCourseHome extends Component {
    componentDidMount() { }
    render() {
        const mystyle = {
            width: '60%',
            margin: "auto"
        };

        return (






            <div class="accordion" id="accordionExample" style={mystyle}>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h4>IELTS 5-7</h4>
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">

                            <div class="row row-cols-1 row-cols-md-3 g-4">
                                <div class="col">
                                    <div class="card">
                                        <img src={img1} class="card-img-top" alt="..." />

                                        <div class="card-body">
                                            <h5 class="card-title">
                                                <a href="/CourseSubjectMainPage">
                                                    Beginner</a>
                                            </h5>
                                        </div>
                                        <button type="button" class="btn btn-primary">
                                            <a href="/teachercourse/subjects/1">
                                            Başlat
                                            </a>
                                            </button>

                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                        <img src={img2} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title" style={{ textAlign: "center" }}>Elementry</h5>
                                        </div>
                                        <button type="button" class="btn btn-primary"><a href="/teachercourse/subjects/1">
                                            Başlat
                                            </a></button>
                                        <div class="card-body">
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                        <img src={img3} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title" style={{ textAlign: "center" }}>Pre İntermediate</h5>
                                        </div>
                                        <button type="button" class="btn btn-primary"><a href="/teachercourse/subjects/1">
                                            Başlat
                                            </a></button>
                                        <div class="card-body">
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                        <img src={img4} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title" style={{ textAlign: "center" }}>Intermediate </h5>
                                        </div>
                                        <button type="button" class="btn btn-primary"><a href="/teachercourse/subjects/1">
                                            Başlat
                                            </a></button>
                                        <div class="card-body">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>








                <br />
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h4>IELTS 3-5</h4>

                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">

                            <div class="row row-cols-1 row-cols-md-3 g-4">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={img1} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={img2} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">This is a short card.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={img3} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={img4} class="card-img-top" alt="..." />
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <h4>IELTS 1-3</h4>
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default TeacherCourseHome;
