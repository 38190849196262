import React from "react";
import data from '../ExampleData/skilldata.json';
import { VictoryPie, VictoryLabel } from 'victory';
import $ from 'jquery';

function SkillsInfo () {
    $(".progress").each(function () {

        var $bar = $(this).find(".bar");
        var $val = $(this).find("span");
        var perc = parseInt($val.text(), 10);

        $({ p: 0 }).animate({ p: perc }, {
            duration: 3000,
            easing: "swing",
            step: function (p) {
                $bar.css({
                    transform: "rotate(" + (45 + (p * 1.8)) + "deg)", // 100%=180° so: ° = % * 1.8
                    // 45 is to add the needed rotation to have the green borders at the bottom
                });
                $val.text(p | 0);
            }
        });
    });
    const handleClick = event => {
        console.log("sa");
        console.log(event.currentTarget.id);
        $('#GrammerButton').css("backgroundColor", "Gainsboro");
        $('#VocabularyButton').css("backgroundColor", "Gainsboro");
        $('#ListeningButton').css("backgroundColor", "Gainsboro");
        $('#ReadingButton').css("backgroundColor", "Gainsboro");
        let buttonBackground = "#" + event.currentTarget.id + "Button";
        $(buttonBackground).css("backgroundColor", "white");
        let detaylar = event.currentTarget.id + "detay"
        {
            data && data.map((dt) => {
                let detay = document.getElementById(dt.typeInfoId);
                detay.style.display = "none";
            })
        }
        let detay = document.getElementById(detaylar);
        detay.style.visibility = "visible";
        detay.style.display = "inline"
    };
    return (
        <div>
        <div class="alert alert-light" role="alert">
            <div className="row">
                <div className="col-sm">
                    <svg viewBox="0 0 400 300">
                        <VictoryPie
                            standalone={false}
                            width={400} height={400}
                            data={[
                                { x: " ", y: 46 }, { x: " ", y: 11 }
                            ]}
                            startAngle={90}
                            endAngle={-90}
                            colorScale={["Blue", "Aqua"]}
                            innerRadius={111} labelRadius={100}
                            style={{ labels: { fontSize: 20, fill: "white" } }}
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            style={{ fontSize: 25 }}
                            x={200} y={200}
                            text="11/57 
                            Grammar"
                        />
                    </svg>




                </div>
                <div className="col-sm">
                    <svg viewBox="0 0 400 300">
                        <VictoryPie
                            standalone={false}
                            width={400} height={400}
                            data={[
                                { x: " ", y: 33 }, { x: " ", y: 22 }
                            ]}
                            startAngle={90}
                            endAngle={-90}
                            colorScale={["Blue", "Aqua"]}
                            innerRadius={111} labelRadius={100}
                            style={{ labels: { fontSize: 20, fill: "white" } }}
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            style={{ fontSize: 25 }}
                            x={200} y={200}
                            text="13/47
                            Vocabulary"
                        />
                    </svg>

                </div>
                <div className="col-sm">
                    <svg viewBox="0 0 400 300">
                        <VictoryPie
                            standalone={false}
                            width={400} height={400}
                            data={[
                                { x: " ", y: 46 }, { x: " ", y: 21 }
                            ]}
                            startAngle={90}
                            endAngle={-90}
                            colorScale={["Blue", "Aqua"]}
                            innerRadius={111} labelRadius={100}
                            style={{ labels: { fontSize: 20, fill: "white" } }}
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            style={{ fontSize: 25 }}
                            x={200} y={200}
                            text="3/59
                            Reading"
                        />
                    </svg>
                </div>
                <div className="col-sm">
                    <svg viewBox="0 0 400 300">
                        <VictoryPie
                            standalone={false}
                            width={400} height={400}
                            data={[
                                { x: " ", y: 24 }, { x: " ", y: 55 }
                            ]}
                            startAngle={90}
                            endAngle={-90}
                            colorScale={["Blue", "Aqua"]}
                            innerRadius={111} labelRadius={100}
                            style={{ labels: { fontSize: 20, fill: "white" } }}
                        />
                        <VictoryLabel
                            textAnchor="middle"
                            style={{ fontSize: 25 }}
                            x={200} y={200}
                            text="9/82
                            Listening"
                        />
                    </svg>

                </div>
            </div>
        </div>
        <div className="row" style={{ backgroundColor: "Gainsboro", margin: "center" }}>
            <div className="row"><br /></div>
            <div className="col-sm">
                <div
                    class="alert alert-light"
                    role="alert" id="GrammerButton"
                    style={{ alignItems: "center", textAlign: "center", backgroundColor: "white" }}
                >
                    <button type="button" id="Grammer" class="btn btndark" onClick={handleClick}>
                        Grammar
                    </button>
                </div>
            </div>

            <div className="col-sm">
                <div
                    class="alert alert-light"
                    role="alert" id="VocabularyButton"
                    style={{ alignItems: "center", textAlign: "center", backgroundColor: "Gainsboro" }}
                >
                    <button type="button" id="Vocabulary" class="btn btndark" onClick={handleClick}>
                        Vocabulary
                    </button>
                </div>
            </div>

            <div className="col-sm">
                <div
                    class="alert alert-light"
                    role="alert" id="ReadingButton"
                    style={{ alignItems: "center", textAlign: "center", backgroundColor: "Gainsboro" }}
                >
                    <button type="button" id="Reading" class="btn btndark" onClick={handleClick}>
                        Reading
                    </button>
                </div>
            </div>

            <div className="col-sm">
                <div id="ListeningButton" class="alert alert-light" role="alert" style={{ alignItems: "center", textAlign: "center", backgroundColor: "Gainsboro" }} >
                    <button type="button" id="Listening" onClick={handleClick} class="btn btndark">
                        Listening
                    </button>
                </div>
            </div>
        </div>
        <br />

        {data && data.map((dt) => {
            if (dt.typeId == 1) {
                return (
                    <div key={dt.typeId} class="alert alert-light" role="alert" id={dt.typeInfoId} style={{ visibility: "visible" }}>
                        {dt.data.map((details) => {
                            return (
                                <div key={details.id} class="alert alert-light" role="alert">
                                    <div className="row">
                                        <div className="col-md-9">
                                            {details.title}
                                        </div>
                                        <div className="col-md-3">
                                            {details.ratio}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="progress-bar" className={details.color} style={{ width: details.realratio }}>{details.realratio}</div>

                                    </div>
                                    <div style={{ textAlign: "center", marginTop: "1%" }}>
                                        <i class="fa fa-arrow-down" style={{ fontSize: "20" }} aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + details.titleaccordion} aria-expanded="true" aria-controls={details.titleaccordion}></i>
                                    </div>

                                    <div id={details.titleaccordion} class="accordion-collapse collapse">
                                        <div class="accordion-body">
                                            {details.details.map((detaylar) => {
                                                return (
                                                    <div className="row" key={detaylar.id}>
                                                        <div className="col-sm" style={{ textAlign: "right" }}>
                                                            {detaylar.name}
                                                        </div>
                                                        <div className="col-sm">
                                                            <div class="progress" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                <div class="progress-bar" className={detaylar.color} style={{ width: detaylar.ratio }}></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }
            else {
                return (
                    <div key={dt.typeId} class="alert alert-light" role="alert" id={dt.typeInfoId} style={{ visibility: "hidden" }}>
                        {dt.data.map((details) => {
                            return (
                                <div key={details.id} class="alert alert-light" role="alert">
                                    <div className="row">
                                        <div className="col-md-9">
                                            {details.title}
                                        </div>
                                        <div className="col-md-3">
                                            {details.ratio}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="progress-bar" className={details.color} style={{ width: details.realratio }}>{details.realratio}</div>

                                    </div>
                                    <div onClick= {handleClick} style={{ textAlign: "center", marginTop: "1%" }}>
                                        <i id="122" class="fa fa-arrow-down" style={{ fontSize: "20" }}  aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + details.titleaccordion} aria-expanded="true" aria-controls={details.titleaccordion}></i>
                                    </div>

                                    <div id={details.titleaccordion} class="accordion-collapse collapse">
                                        <div class="accordion-body">
                                            {details.details.map((detaylar) => {
                                                return (
                                                    <div className="row" key={detaylar.id}>
                                                        <div className="col-sm" style={{ textAlign: "right" }}>
                                                            {detaylar.name}
                                                        </div>
                                                        <div className="col-sm">
                                                            <div class="progress" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                <div class="progress-bar" className={detaylar.color} style={{ width: detaylar.ratio }}></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }

        })
        }

    </div>
    );
}

export default SkillsInfo;
