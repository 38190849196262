import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';

export const CoreFeatures = () => {
    const { t, i18n } = useTranslation();
    const [corefeatures, setCorefeatures] = useState([]);
    function coreFeaturesHandle(e) {
        //gelen text boş ise isactive false yap
        let title = document.getElementById("title").value;
        let subtitle = document.getElementById("subtitle").value;
        let features = [];
        if (title === "") {
            title = corefeatures.title;
        }
        if (subtitle === "") {
            subtitle = corefeatures.subtitle;
        }
        let features1 = document.getElementById("1").value;

        if (corefeatures.features[0].isActive == true) {
            if (features1 === "sil") {
                features[0] = {

                    "id": 1,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features1 === "") {
                features[0] = {

                    "id": 1,
                    "text": corefeatures.features[0].text,
                    "isActive": true
                }
            }
            else {
                features[0] = {

                    "id": 1,
                    "text": features1,
                    "isActive": true
                }
            }
        }
        else {
            if (features1 === "") {
                features[0] = {
                    "id": 1,
                    "text": corefeatures.features[0].text,
                    "isActive": false
                }
            }
            else {
                features[0] = {
                    "id": 1,
                    "text": features1,
                    "isActive": true
                }
            }
        }



        let features2 = document.getElementById("2").value;
        if (corefeatures.features[1].isActive == true) {
            if (features2 === "sil") {
                features[1] = {
                    "id": 2,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features2 === "") {
                features[1] = {
                    "id": 2,
                    "text": corefeatures.features[1].text,
                    "isActive": true
                }
            }
            else {
                features[1] = {
                    "id": 2,
                    "text": features2,
                    "isActive": true
                }
            }
        }
        else {
            if (features2 === "") {
                features[1] = {
                    "id": 2,
                    "text": corefeatures.features[1].text,
                    "isActive": false
                }
            }
            else {
                features[1] = {
                    "id": 2,
                    "text": features2,
                    "isActive": true
                }
            }
        }


        let features3 = document.getElementById("3").value;
        if (corefeatures.features[2].isActive == true) {
            if (features3 === "sil") {
                features[2] = {
                    "id": 3,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features3 === "") {
                features[2] = {
                    "id": 3,
                    "text": corefeatures.features[2].text,
                    "isActive": true
                }
            }
            else {
                features[2] = {
                    "id": 3,
                    "text": features3,
                    "isActive": true
                }
            }
        }
        else {
            if (features3 === "") {
                features[2] = {
                    "id": 3,
                    "text": corefeatures.features[2].text,
                    "isActive": false
                }
            }
            else {
                features[2] = {
                    "id": 3,
                    "text": features3,
                    "isActive": true
                }
            }
        }


        let features4 = document.getElementById("4").value;
        if (corefeatures.features[3].isActive == true) {
            if (features4 === "sil") {
                features[3] = {
                    "id": 4,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features4 === "") {
                features[3] = {
                    "id": 4,
                    "text": corefeatures.features[3].text,
                    "isActive": true
                }
            }
            else {
                features[3] = {

                    "id": 4,
                    "text": features4,
                    "isActive": true
                }
            }
        }
        else {
            if (features4 === "") {
                features[3] = {
                    "id": 4,
                    "text": corefeatures.features[3].text,
                    "isActive": false
                }
            }
            else {
                features[3] = {
                    "id": 4,
                    "text": features4,
                    "isActive": true
                }
            }
        }


        let features5 = document.getElementById("5").value;
        if (corefeatures.features[4].isActive == true) {
            if (features5 === "sil") {
                features[4] = {
                    "id": 5,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features5 === "") {
                features[4] = {
                    "id": 5,
                    "text": corefeatures.features[4].text,
                    "isActive": true
                }
            }
            else {
                features[4] = {
                    "id": 5,
                    "text": features5,
                    "isActive": true
                }
            }
        }
        else {
            if (features5 === "") {
                features[4] = {
                    "id": 5,
                    "text": corefeatures.features[4].text,
                    "isActive": false
                }
            }
            else {
                features[4] = {
                    "id": 5,
                    "text": features5,
                    "isActive": true
                }
            }
        }



        let features6 = document.getElementById("6").value;
        if (corefeatures.features[5].isActive == true) {
            if (features6 === "sil") {
                features[5] = {
                    "id": 6,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features6 === "") {
                features[5] = {
                    "id": 6,
                    "text": corefeatures.features[5].text,
                    "isActive": true
                }
            }
            else {
                features[5] = {
                    "id": 6,
                    "text": features6,
                    "isActive": true
                }
            }
        }
        else {
            if (features6 === "") {
                features[5] = {
                    "id": 6,
                    "text": corefeatures.features[5].text,
                    "isActive": false
                }
            }
            else {
                features[5] = {
                    "id": 6,
                    "text": features6,
                    "isActive": true
                }
            }
        }


        let features7 = document.getElementById("7").value;
        if (corefeatures.features[6].isActive == true) {
            if (features7 === "sil") {
                features[6] = {

                    "id": 7,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features7 === "") {
                features[6] = {
                    "id": 7,
                    "text": corefeatures.features[6].text,
                    "isActive": true
                }
            }
            else {
                features[6] = {
                    "id": 7,
                    "text": features7,
                    "isActive": true
                }
            }
        }
        else {
            if (features7 === "") {
                features[6] = {
                    "id": 7,
                    "text": corefeatures.features[6].text,
                    "isActive": false
                }
            }
            else {
                features[6] = {
                    "id": 7,
                    "text": features7,
                    "isActive": true
                }
            }
        }

        let features8 = document.getElementById("8").value;
        if (corefeatures.features[7].isActive == true) {
            if (features8 === "sil") {
                features[7] = {
                    "id": 8,
                    "text": "Dünya Çapında Meraklı Ve Yaratıcı Bireylerden Oluşan Bir Toplulukla Etkileşime Geçin.",
                    "isActive": false
                }
            }
            else if (features8 === "") {
                features[7] = {
                    "id": 8,
                    "text": corefeatures.features[7].text,
                    "isActive": true
                }
            }
            else {
                features[7] = {

                    "id": 8,
                    "text": features8,
                    "isActive": true
                }
            }
        }
        else {
            if (features8 === "") {
                features[7] = {
                    "id": 8,
                    "text": corefeatures.features[7].text,
                    "isActive": false
                }
            }
            else {
                features[7] = {
                    "id": 8,
                    "text": features8,
                    "isActive": true
                }
            }
        }



        const datas = {
            "title": title,
            "subtitle": subtitle,
            "features": features
        }
        RequestHelper("/coreFeatures", "put", datas, "1").then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    useEffect(() => {
        (async () => {
            const corefeatures = await RequestHelper("/coreFeatures", "get", "", "1")
            setCorefeatures(corefeatures.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('corefeaturessettings')}</h2>
            <form onSubmit={coreFeaturesHandle}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">{t('corefeaturestitle')}</label>
                        <textarea class="form-control" id="title" placeholder={corefeatures.title} />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="subtitle" class="form-label">{t('corefeaturessubtitle')}</label>
                        <textarea class="form-control" id="subtitle" placeholder={corefeatures.subtitle} />
                    </div>
                </div>
                <h5>istemidiğiniz Özelliğin text kısmına <span>sil</span> yazınız</h5>
                {corefeatures.features && corefeatures.features.map((dt) => {
                    if (dt.isActive) {
                        return (
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label for={dt.id} class="form-label">{t('features')} {dt.id}</label>
                                    <textarea class="form-control" id={dt.id} placeholder={dt.text} />
                                    {/* <button className='btn btn-warning' id={"delete"+ dt.id} onClick={handleDelete}>Pasif et</button> */}
                                </div>

                            </div>
                        )
                    }
                    else {
                        return (
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label for={dt.id} class="form-label">{t('features')} {dt.id}</label>
                                    <textarea class="form-control" id={dt.id} placeholder="silindi" />
                                    {/* <button className='btn btn-warning' id={"delete"+ dt.id} onClick={handleDelete}>Pasif et</button> */}
                                </div>

                            </div>
                        )


                    }
                })}
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                </div>
            </form>
            <br />
        </div>
    )
}
export default CoreFeatures;