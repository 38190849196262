import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import examplejson from "../example.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import logo from '../images/logo.png'

class CourseLayout extends Component {
    componentDidMount() { }
    render() {
        function zort() {
            var element = document.getElementById("sidebar");
            element.classList.toggle("active");
            $('.forsidebar').toggle();
        }
        return (
            <div>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">
                        <div className="nav-sidebar-font-right">
                            <FontAwesomeIcon
                                icon="fa-solid fa-arrow-right"
                                className="navbar-toggler"
                                onClick={zort}
                                style={{ backgroundColor: "blue" }}
                            />
                            <FontAwesomeIcon
                                icon="fa-solid fa-toggle-on"
                                className="navbar-toggler"
                                onClick={zort}
                                style={{ backgroundColor: "blue" }}
                            />
                        </div>

                        <a className="navbar-brand" href="#">
                            <img src={logo} />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a
                                        href="/dashboard"
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Go To Rapor Page
                                    </a>
                                </li>
                            </ul>
                            <form className="d-flex" role="search">
                                <button>profil</button>
                                logoooo
                            </form>
                        </div>
                    </div>
                </nav>

                <div class="wrapper">
                    <nav id="sidebar">
                        <div class="sidebar-header">
                            <h3 onClick={zort}>Bootstrap Sidebar</h3>
                            <strong onClick={zort}>BS</strong>
                        </div>

                        <ul class="list-unstyled components">
                            <li>

                                <a href="#pageSubmenu" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#pageSubmenu" aria-expanded="false" aria-controls="pageSubmenu">
                                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    Courses
                                    <i class="fa fa-arrow-down" aria-hidden="true"></i>

                                </a>
                                <ul class="collapse list-unstyled" id="pageSubmenu">

                                {examplejson &&
                                    examplejson.map((example) => {
                                        if (example.id == 1) {
                                            return (
                                                <div key={example.title}>
                                                    {example.subtitles.map((data) => {
                                                        return (
                                                            <li key={data.id}>
                                                                <a
                                                                    class="dropdown-item "
                                                                    href="#"
                                                                    key={data.subtitlename}
                                                                >
                                                                    {data.subtitlename}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        }
                                    })}



                                </ul>
                            </li>
                            <br />
                            <li>
                                {examplejson &&
                                    examplejson.map((example) => {
                                        if (example.id == 1) {
                                            return example.subtitles.map((data) => {
                                                if (data.id == 1) {
                                                    return data.subtitledetail.map((dt) => {
                                                        return (
                                                            <div key={dt.title}>
                                                                <a
                                                                    href={"#" + dt.tit}
                                                                    data-toggle="collapse"
                                                                    className="dropdown-toggle"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={"#" + dt.tit}
                                                                    aria-expanded="false"
                                                                    aria-controls={dt.tit}
                                                                >
                                                                    <i className="fas fa-copy"></i>
                                                                    <b key={dt.tit}>
                                                                        <b>{dt.title}</b>{" "}
                                                                        <b className="forsidebar">{dt.id}</b>{" "}
                                                                    </b>
                                                                </a>
                                                                <ul class="collapse list-unstyled" id={dt.tit}>
                                                                    {dt.subcontent.map((lessons) => {
                                                                        return (
                                                                            <li>
                                                                                <a href="#">
                                                                                    <b>{lessons.title}</b>
                                                                                    <b className="forsidebar">
                                                                                        {lessons.id}
                                                                                    </b>
                                                                                </a>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        );
                                                    });
                                                }
                                            });
                                        }
                                    })}
                            </li>



                        </ul>

                        <div>
                            {/*
                        <ul class="list-unstyled components">
                            <li class="active">
                                <a href="#homeSubmenu" data-toggle="collapse" class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#homeSubmenu" aria-expanded="false" aria-controls="homeSubmenu">
                                    <i class="fas fa-home"></i>
                                    Home
                                </a>
                                <ul class="collapse list-unstyled" id="homeSubmenu">
                                    <li>
                                        <a href="#">Home 1</a>
                                    </li>
                                    <li>
                                        <a href="#">Home 2</a>
                                    </li>
                                    <li>
                                        <a href="#">Home 3</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="fas fa-briefcase"></i>
                                    About
                                </a>
                                <a href="#pageSubmenu" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#pageSubmenu" aria-expanded="false" aria-controls="pageSubmenu">
                                    <i className="fas fa-copy"></i>
                                    Pages
                                </a>
                                <ul class="collapse list-unstyled" id="pageSubmenu">
                                    <li>
                                        <a href="#">Page 1</a>
                                    </li>
                                    <li>
                                        <a href="#">Page 2</a>
                                    </li>
                                    <li>
                                        <a href="#">Page 3</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-image"></i>
                                    Portfolio
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-question"></i>
                                    FAQ
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fas fa-paper-plane"></i>
                                    Contact
                                </a>
                            </li>
                        </ul>

                        <ul class="list-unstyled CTAs">
                            <li>
                                <a href="https://bootstrapious.com/tutorial/files/sidebar.zip" class="download">Download source</a>
                            </li>
                            <li>
                                <a href="https://bootstrapious.com/p/bootstrap-sidebar" class="article">Back to article</a>
                            </li>
                        </ul>
                    */}
                        </div>
                    </nav>

                    <div id="content">
                        <div className="container">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CourseLayout;
