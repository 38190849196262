import React, { useState, useEffect } from "react";
import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const AdminPackageList = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [data, setData] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("packageName"),
                Footer: t("packageName"),
                accessor: 'packageName',
            },
            {
                Header: t("packageDescription"),
                Footer: t("packageDescription"),
                accessor: 'packageDetail',
            },
            {
                Header: t("packagePrice"),
                Footer: t("packagePrice"),
                accessor: 'packagePrice',
            },

            {
                Header: t("studentCount"),
                Footer: t("studentCount"),
                accessor: 'studentLimit',
            },
            {
                Header: t("validityDate"),
                Footer: t("validityDate"),
                accessor: 'howManyDays',
            },
            {
                Header: t("isInvidual"),
                Footer: t("isInvidual"),
                accessor: 'isInvidual',
            },
            {
                Header: t("isPrivate"),
                Footer: t("isPrivate"),
                accessor: 'isItPrivate',
            },
            {
                Header: t("isActive"),
                Footer: t("isActive"),
                accessor: 'isActive',
            },
            {
                Header: t("sortid"),
                Footer: t("sortid"),
                accessor: 'sortNumber',
            },
            {
                Header: t("deleteCourse"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t("delete")}
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: t("deleteCourse"),
            },
            {
                Header: t("editCourse"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("editPackage")}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t("editCourse"),
            }
        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/packageedit/" + row['id'];
    }

    const handleDelete = (row) => {
        window.location.reload();
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Package/GetList";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                let a = "";
                                let b = "";
                                let c = "";

                                if (res.data['data'][i]['isActive']) {
                                    a = t('active');
                                }
                                else {
                                    a = t('passive');
                                }
                                if (res.data['data'][i]['isItPrivate']) {
                                    b = t('yes');
                                }
                                else {
                                    b = t('no');
                                }
                                if (res.data['data'][i]['isInvidual']) {
                                    c = t('yes');
                                }
                                else {
                                    c = t('no');
                                }
                                data.push({
                                    "id": res.data['data'][i]['id'],
                                    "packageName": res.data['data'][i]['packageName'],
                                    "packageDetail": res.data['data'][i]['packageDetail'],
                                    "packagePrice": res.data['data'][i]['packagePrice'],
                                    "studentLimit": res.data['data'][i]['studentLimit'],
                                    "howManyDays": res.data['data'][i]['howManyDays'],
                                    "isInvidual": c,
                                    "isActive": a,
                                    "isItPrivate": b,
                                    "sortNumber": res.data['data'][i]['sortNumber'],

                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);

    return (
        <div>

            <div style={{ textAlign: "center" }}>
                <h1>{t("packageList")}</h1>
                <h4>{msg}</h4>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("packageList")} />
                    </div>
                )}


            </div>


        </div>
    );
};
export default AdminPackageList;
