import React, { useEffect } from "react";
import { useAuth } from '../../context/AuthContext';

function DashboardHome() {
    const { user } = useAuth();

    function Mainpage() {
        return (
            <div>
                <h1>Panele Hoşgeldiniz!</h1>
            </div>
        );
    }

    if (user['role'] === "SysAdmin" || user['roleId'] === 2 || user['roleId'] === 3) {
        return (
            <div>
                <Mainpage />
            </div>
        );
    }
    else if (user['roleId'] === 4 || user['roleId'] === 5 || user['roleId'] === 6) {
        return (
            <div>
                <br />
                <button className="btn btn-info">
                    <a href="/userco">
                        Kurs Sayfasına Geç
                    </a>
                </button>
                <br />
                <Mainpage />
            </div>
        );
    }
    else if (user['roleId'] === 7) {
        return (
            <div>
                <br />
                <button className="btn btn-info">
                    <a href="/teachercourse">
                        Kurs Sayfasına Geç
                    </a>
                </button>
                <br />
                <Mainpage />
            </div>
        );
    }
}
export default DashboardHome;