import React, { useState, useEffect } from "react";
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';

function ReviewList () {
    const [data, setData] = useState([]);

    const { t, i18n } = useTranslation();

    function onClick (event) {
        var exp = [];
        for (var i = 0, len = data.length; i < len; i++) {
            if(data[i]['id'] === event.currentTarget.id)
            {
                exp = data[i];
            }
        }
        if(exp['isSuccess'] === true)
        {
            exp['isSuccess'] = false;
        }
        else{
            exp['isSuccess'] = true;
        }
        RequestHelper("/reviews", "put", exp,event.currentTarget.id).
        then(
            response => {
                alert(response.statusText)
            });
            window.location.reload();

    }


    const handleDelete = (event) => {
        RequestHelper("/reviews", "delete","",event.currentTarget.id).then(response => alert(response.statusText))
        window.location.reload();
    }

    const handleEdit = (event) => {
        window.location.href = "/dashboard/admin/editreview/" + event.currentTarget.id;
    }

    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/reviews", "get","")
            setData(res.data);
        })()
    }, [])


    return (
        <div style={{backgroundColor:"white"}}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("reviewlist")}</h1>
                <br/>
            </div>
            <div style={{ overflowX: "auto", border: "1px solid black",padding:"5px" }}>
            <table class="table table-bordered" >
                    <thead>
                        <tr>
                            <th scope="col">{t("namesurname")}</th>
                            <th scope="col">{t("reviewUserDescription")}</th>
                            
                            <th scope="col">{t("message")}</th>
                            <th scope="col">{t("reviewRatePoint")}</th>
                            <th scope="col">{t("isActive")}</th>
                            <th scope="col">{t("changeActivity")}</th>
                            <th scope="col">{t("edit")}</th>
                            
                            <th scope="col">{t("delete")}</th>

                        </tr>
                    </thead>
                    <tbody>

                        {data && data.map((dt) => {
                            return (
                                <tr key={dt.id}>
                                    <td>{dt.name}</td>
                                    <td>{dt.description}</td>
                                    <td>{dt.text}</td>
                                    <td>{dt.point}</td>
                                    <td>{dt.isActive ? "Aktif":"Pasif"}</td>
                                    <td>
                                        <button className="btn btn-success" id={dt.id} onClick={onClick}>
                                            {t("change")}
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-info" id={dt.id} onClick={handleEdit}>
                                            {t("edit")}
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-danger" id={dt.id} onClick={handleDelete}>
                                        {t("delete")}
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <br/>
            </div> <br/>
        </div>
    );
};
export default ReviewList;
