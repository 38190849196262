import Datatable from "../../Components/Datatable"
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
export const AdminPayment = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [msgg, setMsgg] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingg, setLoadingg] = useState(true);

    const { t, i18n } = useTranslation()
    const [data, setData] = useState([]);
    const [banks, setBanks] = useState([]);
    const [packages, setPackages] = useState([]);
    const [filtereddata, setFiltereddata] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("paymentmethod"),
                Footer: t("paymentmethod"),
                accessor: 'paymentInfo',
            }
            ,
            {
                Header: t("packageName"),
                Footer: t("packageName"),
                accessor: 'packageInfo',
            }
            ,
            {
                Header: t("paymentrequesttype"),
                Footer: t("paymentrequesttype"),
                accessor: 'station',
            }
            ,
            {
                Header: t("paymentStatus"),
                Footer: t("paymentStatus"),
                accessor: 'status',
            }
            ,
            {
                Header: t("transactionDate"),
                Footer: t("transactionDate"),
                accessor: 'transactionDate',
            }
            ,
            {
                Header: t("userEmail"),
                Footer: t("userEmail"),
                accessor: 'username',
            }
            ,
            {
                Header: t("namesurname"),
                Footer: t("namesurname"),
                accessor: 'userFullName',
            }
            ,
            {
                Header: t("edit"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>

                    </div>
                ),
                id: 'actionEdit',
                Footer: t("edit"),
            },
            {
                Header: t("rejectTransaction"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <textarea id={"cancelnote" + originalRow['id']} placeholder="note" required />
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t("cancel")}
                        </button>

                    </div>
                ),
                id: 'actionDelete',
                Footer: t("rejectTransaction"),
            },
        ],
        [],
    )
    const columnsFiltered = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("paymentmethod"),
                Footer: t("paymentmethod"),
                accessor: 'paymentInfo',
            }
            ,
            {
                Header: t("packageName"),
                Footer: t("packageName"),
                accessor: 'packageInfo',
            }
            ,
            {
                Header: t("paymentrequesttype"),
                Footer: t("paymentrequesttype"),
                accessor: 'station',
            }
            ,
            {
                Header: t("paymentStatus"),
                Footer: t("paymentStatus"),
                accessor: 'status',
            }
            ,
            {
                Header: t("transactionDate"),
                Footer: t("transactionDate"),
                accessor: 'transactionDate',
            }
            ,
            {
                Header: t("userEmail"),
                Footer: t("userEmail"),
                accessor: 'username',
            }
            ,
            {
                Header: t("namesurname"),
                Footer: t("namesurname"),
                accessor: 'userFullName',
            }
            ,
            {
                Header: t("transactionNoteList"),
                Footer: t("transactionNoteList"),
                accessor: 'note',
            }
            ,
            {
                Header: t("coupencode"),
                Footer: t("coupencode"),
                accessor: 'cuponCode',
            }



        ],
        [],
    )
    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/editpayment/" + row['id'] + "/" + row['type'];
    }
    const handleDelete = async (row) => {
        let cancelNote = document.getElementById('cancelnote' + row['id']).value;
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            await axios({
                method: 'Get',
                url: "https://api.heryerdeingilizce.com/api/admin/Subscription/Reject",
                params: {
                    transactionId: row['id'],
                    note: cancelNote,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);

                    }
                }
                else {
                    Swal.fire({
                        title: "Silinirken hata ile karşılaşıldı!",
                        icon: "error",
                        confirmButtonText: `Tamam`
                    });
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }

        window.location.reload();
    }

    const handleSubmit = async (e) => {

        setLoadingg(true);

        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/Subscription/GetList";
            await axios({
                method: 'Get',
                url: url,
                params: {
                    start: document.getElementById("startdate").value,
                    end: document.getElementById("finishdate").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let station = "";

                            if (res.data['data'][i]['transaction']['type'] === "deposit-inv") {
                                station = t('invidualBuy');
                            }
                            else if (res.data['data'][i]['transaction']['type'] === "extend-inv") {
                                station = t('invidualExtend');
                            }

                            else if (res.data['data'][i]['transaction']['type'] === "extend-co") {
                                station = t('uninvidualExtend');
                            }
                            else if (res.data['data'][i]['transaction']['type'] === "deposit-co") {
                                station = t('uninvidualBuy');
                            }
                            let paymentInfo = "";
                            if (banks) {
                                for (let j = 0; j < banks.length; j++) {
                                    if (res.data['data'][i]['transaction']['gatewayId'] === banks[j]['id']) {
                                        paymentInfo = banks[j]['gatewayName'];
                                    }
                                }
                            }
                            else {
                                paymentInfo = res.data['data'][i]['gatewayId'];
                            }
                            let packageInfo = "";
                            if (packages) {
                                for (let k = 0; k < packages.length; k++) {
                                    if (res.data['data'][i]['transaction']['package'] === packages[k]['id']) {
                                        packageInfo = packages[k]['packageName'];

                                    }
                                }
                            }
                            else {
                                packageInfo = res.data['data'][i]['transaction']['package'];
                            }
                            let status = "";
                            if (res.data['data'][i]['transaction']['status'] === "requested") {
                                status = t('requested');
                            }
                            else if (res.data['data'][i]['transaction']['status'] === "approved") {
                                status = t('successfull');
                            }
                            else if (res.data['data'][i]['transaction']['status'] === "rejected") {
                                status = t('rejected')
                            }
                            filtereddata.push({
                                "id": res.data['data'][i]['transaction']['id'],
                                "paymentInfo": paymentInfo,
                                "packageInfo": packageInfo,
                                "station": station,
                                "type": res.data['data'][i]['transaction']['type'],
                                "status": status,
                                "transactionDate": res.data['data'][i]['transaction']['transactionDate'],
                                "username": res.data['data'][i]['username'],
                                "userFullName": res.data['data'][i]['userFullName'],
                                "note": res.data['data'][i]['transaction']['note'],
                                "cuponCode": res.data['data'][i]['transaction']['cuponCode'],

                            })
                        }
                        if (res.data['data'].length === 0) {
                            setMsg("Sonuç bulunamadı");

                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });

        } catch (err) {
            setMsg(err);
        }
        setLoadingg(false);

    }

    useEffect(() => {
        const fetchBanks = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Gateway/GetList";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                banks.push({
                                    "id": res.data['data'][i]['id'],
                                    "gatewayName": res.data['data'][i]['gatewayName'],
                                    "typeName": res.data['data'][i]['typeName'],
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            //************************************************************************************************
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Package/GetList";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                packages.push({
                                    "id": res.data['data'][i]['id'],
                                    "packageName": res.data['data'][i]['packageName'],
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            //************************************************************************************************
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Subscription/GetAwaiting";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                let station = "";

                                if (res.data['data'][i]['transaction']['type'] === "deposit-inv") {
                                    station = t('invidualBuy');
                                }
                                else if (res.data['data'][i]['transaction']['type'] === "extend-inv") {
                                    station = t('invidualExtend');
                                }

                                else if (res.data['data'][i]['transaction']['type'] === "extend-co") {
                                    station = t('uninvidualExtend');
                                }
                                else if (res.data['data'][i]['transaction']['type'] === "deposit-co") {
                                    station = t('uninvidualBuy');
                                }
                                let paymentInfo = "";
                                if (banks) {
                                    for (let j = 0; j < banks.length; j++) {
                                        if (res.data['data'][i]['transaction']['gatewayId'] === banks[j]['id']) {
                                            paymentInfo = banks[j]['gatewayName'];
                                        }
                                    }
                                }
                                else {
                                    paymentInfo = res.data['data'][i]['gatewayId'];
                                }
                                let packageInfo = "";
                                if (packages) {
                                    for (let k = 0; k < packages.length; k++) {
                                        if (res.data['data'][i]['transaction']['package'] === packages[k]['id']) {
                                            packageInfo = packages[k]['packageName'];

                                        }
                                    }
                                }
                                else {
                                    packageInfo = res.data['data'][i]['transaction']['package'];
                                }
                                let status = "";
                                if (res.data['data'][i]['transaction']['status'] === "requested") {
                                    status = t('requested')
                                }
                                else if (res.data['data'][i]['transaction']['status'] === "approved") {
                                    status = t('approved')
                                }
                                else if (res.data['data'][i]['transaction']['status'] === "rejected") {
                                    status = t('rejected')
                                }

                                if (res.data['data'][i]['transaction']['status'])

                                    data.push({
                                        "id": res.data['data'][i]['transaction']['id'],
                                        "paymentInfo": paymentInfo,
                                        "packageInfo": packageInfo,
                                        "station": station,
                                        "type": res.data['data'][i]['transaction']['type'],
                                        "status": status,
                                        "transactionDate": res.data['data'][i]['transaction']['transactionDate'],
                                        "username": res.data['data'][i]['username'],
                                        "userFullName": res.data['data'][i]['userFullName'],
                                    })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }

            setLoading(false);
        }
        fetchBanks();


    }, []);

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <br />
                <h1>{t('paymentRequestAndNewRegisters')}</h1>
                <h4> {msg} </h4>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t('waintingpaymentrequests')} />
                    </div>
                )}

            </div>
            <hr />
            <div style={{ textAlign: "center" }}>
                <br />
                <h1>{t('getpaymentrequestwithDate')}</h1>
                <h4> {msgg} </h4>

                <form onSubmit={handleSubmit}>
                    <div class="mb-3">
                        <label class="form-label" for="startdate">{t("startdate")}</label>
                        <input type="datetime-local" class="form-control" id="startdate" name="startdate" required />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="finishdate">{t("finishdate")}</label>
                        <input type="datetime-local" class="form-control" id="finishdate" name="finishdate" required />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("showdetails")}</button>
                    </div>

                </form>

                {loadingg && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loadingg && (
                    <div>
                        <Datatable data={filtereddata} columns={columnsFiltered} tableBaslik={t('getpaymentrequestwithDate')} />
                    </div>
                )}
            </div>

        </div>
    )
}
export default AdminPayment;