import React, { Component, setState } from "react";
import img1 from '../../images/courseicon1.jpg';
import data from './HomeExample.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, PopoverHeader, PopoverBody, UncontrolledPopover, Popover } from 'reactstrap'

class CourseViewHome extends Component {



    componentDidMount() { }
    render() {
        const icons = (typeId, finished, popoverId, subjectId, lessonId) => {
            var icon ="";
            if(typeId == 1)
            {
                icon="fas fa-volume-up";
            }
            else if(typeId == 2)
            {
                icon="fas fa-pencil-alt";
            }
            else if(typeId == 3)
            {
                icon="fas fa-keyboard";
            }
            else if(typeId == 4)
            {
                icon="fas fa-book-open";
            }

            if (finished == true) { 
                return (
                    <Button id={popoverId} style={{ backgroundColor: "blue", border: "solid", borderRadius: "100%", padding: "7px" }}>
                        <a href={"/userCo/subjects/" + subjectId + "/lesson/" + lessonId}>
                        <FontAwesomeIcon icon={icon} style={{ fontSize: "30px" }} />
                        </a>
                        <div style={{ float: "right", marginLeft: "5%" }}>
                            <FontAwesomeIcon icon="fas fa-check" style={{ fontSize: "10", border: "solid", borderRadius: "100%", padding: "2px" }} />
                        </div>
                    </Button>
                )
            }
            else {
                return (
                    <Button id={popoverId} style={{ border: "solid", borderRadius: "100%", padding: "7px" }}>
                        <FontAwesomeIcon icon={icon} style={{ fontSize: "30px" }} />
                        <div style={{ float: "right", marginLeft: "5%" }}>
                            <FontAwesomeIcon icon="fas fa-lock" style={{ fontSize: "10", border: "solid", borderRadius: "100%", padding: "2px" }} />
                        </div>
                    </Button>
                )
            }




        };
        const handleClick = event => {
            console.log(event.currentTarget.id);
            let iconName = event.currentTarget.id + "icon";
            let elmnt = document.getElementById(iconName).classList;
            if (elmnt[1] == "fa-plus-circle") {
                document.getElementById(iconName).classList.remove('fa-plus-circle');
                document.getElementById(iconName).classList.add('fa-pause-circle');
            }
            else if (elmnt[1] == "fa-pause-circle") {
                document.getElementById(iconName).classList.remove('fa-pause-circle');
                document.getElementById(iconName).classList.add('fa-plus-circle');
            }
        };


        return (

            <div className="container">
                <br/>
                <div className="row">
                    <div className="col-md-9">
                        <div class="row">
                            {/* bitmeyen  bölüm için mavi şerit. */}

                            {data && data.map((dt) => {
                                return (
                                    <div class="card card-primary card-outline" key={dt.id}>
                                        <div class="row">                                       
                                            <div class="col-md-9">
                                                <div class="card-body">
                                                    <h5 class="card-title">{dt.name}</h5>
                                                    <p class="card-text">
                                                        {dt.title}
                                                    </p>
                                                    <div id="accordion">
                                                        <button onClick={handleClick} id={dt.nameaccordion} class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + dt.nameaccordionid} aria-expanded="true" aria-controls={dt.nameaccordionid}>
                                                            <i style={{ fontSize: 25, color: "blue" }} id={dt.nameaccordion + "icon"} class="fa fa-plus-circle"></i>
                                                        </button>

                                                        <div class="accordion-collapse collapse " id={dt.nameaccordionid} aria-labelledby="headingOne" data-parent="#accordion" style={{ marginLeft: "10%" }}>
                                                            <div className="row" style={{ alignItems: "center", margin: "1px" }}>

                                                                {dt.details.slice(0, 4).map((detaylar) => {
                                                                    return (
                                                                        <div className="col-sm-2" key={detaylar.id} >
                                                                                <div style={{ alignItems: "center" }}>
                                                                                {icons(detaylar.type, detaylar.completed, detaylar.popoverid, dt.id, detaylar.id)}
                                                                        </div>
                                                                            
                                                                            <UncontrolledPopover
                                                                                placement="bottom"
                                                                                target={detaylar.popoverid}
                                                                                trigger="hover"
                                                                            >
                                                                                <PopoverHeader>
                                                                                    {detaylar.name}
                                                                                </PopoverHeader>
                                                                                <PopoverBody>
                                                                                    {detaylar.description}
                                                                                </PopoverBody>
                                                                            </UncontrolledPopover>

                                                                        </div>
                                                                    )
                                                                })}

                                                                <div className="col-sm-2">
                                                                    {dt.detailCount > 4 &&
                                                                        <a href={"/userCo/subjects/" + dt.id}>
                                                                            <FontAwesomeIcon icon="fas fa-ellipsis-h" style={{ fontSize: 25, opacity: .9, border: "solid", borderRadius: "80%", padding: "2px", backgroundColor: "gray" }} />
                                                                        </a>
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" style={{ maxHeight: "100px" }}>
                                                <img style={{ maxHeight: "100px" }} src={img1} class="img-fluid rounded-start" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                    
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-md-3">
                        15 dk oyun hatırlatma
                        <hr/>
                        genel score
                        <hr/>   
                        sistem duyurusu?
                        <hr/>
                    </div>
                </div>
            </div>



        );
    }
}

export default CourseViewHome;
