import React from "react";

export const AdminEditQuestion = () => {
    const data = 
    [
    {
        "id":1,
        "name":"number",
        "description":"saying numbers",
        "courseId":2,
        "arrangement":1
    }
    ];
    const datalar = [
        {
            "id":1,
            "name":"ielts"
    
        },
        {
            "id":2,
            "name":"general english"
        }
        ,
        {
            "id":3,
            "name":"business english"
        }
    ];

    function handleSubmit(event) {
        console.log(event);
        event.preventDefault();
        console.log(event.target[0].value)
        alert("Succesfully Updated!");
    }
    return (
        <div className="container">
            <div style={{ textAlign: "center" }}>
                <h1>Edit Question</h1>
                <h1>Buralar düzenlenecek!!!</h1>
            </div>
                <form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="courseSelect" class="form-label">Course Name</label>
                    <select id="courseSelect" class="form-select" aria-label="Default select example">

                        {datalar ? (
                            datalar && datalar.map((dt) => {
                                if(dt.id === data[0].courseId)
                                {
                                    return (
                                    
                                        <option key={dt.id} value={dt.id} selected>{dt.name}</option>
                                    );
                                }
                                else{
                                    return (                                
                                        <option key={dt.id} value={dt.id}>{dt.name}</option>
                                    );
                                }
                            })
                        ) : (
                            <p>Please Reflesh page</p>
                        )
                        }
                    </select>
                </div>


                <div class="mb-3">
                    <label for="levelname" class="form-label">Level Name</label>
                    <textarea class="form-control" id="levelname" value={data[0].name} />
                </div>


                <div class="mb-3">
                    <label for="leveldescription" class="form-label">Level Description</label>
                    <textarea class="form-control" id="leveldescription" value={data[0].description}/>
                </div>
                <div class="mb-3">
                    <label for="levelarrangement" class="form-label">Level arrangement</label>
                    <textarea class="form-control" id="levelarrangement" value={data[0].arrangement}/>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Update</button>
                </div>

            </form>
        </div>
    );
};
export default AdminEditQuestion;
