import React from "react";
import data from '../ExampleData/progressdata.json';
import { VictoryPie, VictoryLabel } from 'victory';

function ProgressInfo () {
    return (
        <div>
        <div className="row" style={{ backgroundColor: "DeepSkyBlue" }}>
            <div className="col-md-4" style={{maxHeight:"320px"}}>
                <svg viewBox="0 0 350 350">
                <VictoryPie 
                        standalone={false}
                        width={270} height={270}
                        data={[
                            { x: " ", y: 4 },
                            { x: " ", y: 6 },
                            { x: " ", y: 8 },
                            { x: " ", y: 15 },
                            { x: " ", y: 100 },
                        ]}
                        colorScale={["red", "orange", "blue", "green", "Gainsboro"]}
                        innerRadius={65} labelRadius={100}
                        style={{ labels: { fontSize: 16, fill: "white"} }}
                    />
                    <VictoryLabel
                        textAnchor="middle"
                        style={{ fontSize: 15 }}
                        x={135} y={135}
                        text="11/57 Grammar"
                    />
                </svg>



            </div>
            <div className="col-md-6">
                <h1>Ahmet Faruk</h1>
                <h3>You're a shining star!
                </h3>
                <h6>
                    You are ready to Learn, Let's Start!

                </h6>
            </div>
            <div className="col-md-2" style={{ float: "right" }}>
                tips
                <h6>
                    That's great! Would you like to be much better? Then focus on your errors and go over the feedback sections.
                </h6>
            </div>
        </div>
        <br />
        <div className="row" style={{ backgroundColor: "white" }}>
            {data && data.map((dt) => {
                return (
                    <div key={dt.id} class="alert alert-light" role="alert">
                        <div className="row">
                            <div className="col-md-2"> {dt.name} </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-4"> {dt.time} {dt.ratio} </div>
                        </div>
                        <div class="progress">

                            {dt.state.map((progress) => {
                                return (
                                    <div class="progress-bar" className={progress.color} role="progressbar" style={{ width: progress.ratio }} aria-valuemin="0" aria-valuemax="100"></div>
                                )
                            })}
                        </div>

                        <div style={{ textAlign: "center", marginTop: "1%" }}>
                            <i class="fa fa-arrow-down" style={{ fontSize: "20" }} aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + dt.nameaccordion} aria-expanded="true" aria-controls={dt.nameaccordion}></i>
                        </div>

                        <div id={dt.nameaccordion} class="accordion-collapse collapse">
                            <div class="accordion-body">
                                {dt.details.map((detaylar) => {
                                    return (

                                        <div key={detaylar.id} class="alert alert-light" role="alert">
                                            <div className="row">
                                                <div className="col-md-3" style={{ margin: "auto", float: "center", textAlign: "center", marginTop: "auto" }}>
                                                    <div>{detaylar.logo} {detaylar.title}</div>
                                                </div>
                                                <div className="col-md-9">
                                                    {detaylar.data.map((veri) => {
                                                        return (
                                                            <div className="row" key={veri.id}>
                                                                <div className="col-md-2">
                                                                    {veri.name}
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div class="progress">
                                                                        <div class="progress-bar" className={veri.color} role="progressbar" style={{ width: veri.realratio }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    {veri.ratio} {veri.time}
                                                                </div>

                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>


                                        </div>
                                    )
                                })}

                            </div>
                        </div>

                    </div>
                )
            })}
        </div>
    </div>
    );
}

export default ProgressInfo;
