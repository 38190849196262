import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import TextEditor from './TextEditor';
import TextEditorr from './TextEditorr';
import TextEditorrr from './TextEditorrr';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'

export const AdminCreateQuestion = () => {
    const [tags, setTags] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [cnt1, setcnt1] = useState([]);
    const [cnt2, setcnt2] = useState([]);
    const [cnt3, setcnt3] = useState([]);

    const questionTypes =
        [
            {
                "id": 1,
                "name": "video",
                "description": "eğitim içeriği",
            },
            {
                "id": 2,
                "name": "choice",
                "description": "çoktan seçmeli",
            },
            {
                "id": 3,
                "name": "match",
                "description": "eşleştirme",
            },
            {
                "id": 4,
                "name": "write",
                "description": "yazma",
            },
            {
                "id": 5,
                "name": "trueFalse",
                "description": "doğru yanlış",
            },
            {
                "id": 6,
                "name": "filldd",
                "description": "boşluk doldur",
            },
            {
                "id": 7,
                "name": "sentence",
                "description": "cümle kurma",
            }
        ];

    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];


    const onSubmit = async (e) => {

        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let contentType = document.getElementById('contentType').value;
            let subjectid = document.getElementById('subjectId').value;
            let tagid = document.getElementById('tagId').value;

            let sortId = document.getElementById('sortId').value;
            let contentStr = cnt1;
            let detail = cnt2;
            let notfyText = cnt3;
            let answer = document.getElementById('answer').value;

            //let detail = document.getElementById('detail').value;
            //let notfyText = document.getElementById('notfyText').value;
            //let answer = document.getElementById('answer').value;

            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    contentType: contentType,
                    subjectid: subjectid,
                    tagid: tagid,
                    sortId: sortId,
                    contentStr: contentStr,
                    detail: detail,
                    notfyText: notfyText,
                    answer: answer,
                    isActive: true
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
        window.location.reload();
    }
    const handleProcedureContentChange1 = (content) => {
        console.log("content---->", content);
        setcnt1(content)
    };
    const handleProcedureContentChange2 = (content) => {
        console.log("content---->", content);
        setcnt2(content)
    };
    const handleProcedureContentChange3 = (content) => {
        console.log("content---->", content);
        setcnt3(content)
    };
    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/TagManager/GetTags";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                let activity = "";
                                if (res.data['data'][i]['isActive']) {
                                    activity = t('active');
                                }
                                else {
                                    activity = t('passive');
                                }
                                tags.push({
                                    "id": res.data['data'][i]['id'],
                                    "tagName": res.data['data'][i]['tagName'],
                                    "isActive": activity,

                                })
                            }

                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
        const fetchdataa = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetList";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                let activity = "";
                                if (res.data['data'][i]['isActive']) {
                                    activity = t('active');
                                }
                                else {
                                    activity = t('passive');
                                }
                                subjects.push({
                                    "id": res.data['data'][i]['id'],
                                    "subjectName": res.data['data'][i]['subjectName'],
                                    "isActive": activity,

                                })
                            }

                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }, [])


    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createQuestion")}</h1>
            </div>
            <h4>{msg}</h4>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <form onSubmit={onSubmit} >
                    <div class="mb-3">
                        <label for="tagId" class="form-label">{t("selecttag")}</label>
                        <select id="tagId" class="form-select" aria-label="Default select example" required>
                            <option key="0" >{t("selecttag")}</option>
                            {tags ? (
                                tags && tags.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.tagName}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadPage")}</p>
                            )
                            }
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="subjectId" class="form-label">{t("selectSubject")}</label>
                        <select id="subjectId" class="form-select">
                            <option key="" >{t("selectSubject")}</option>

                            {subjects ? (
                                subjects && subjects.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.subjectName}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="contentType" class="form-label">{t("selectQuestionType")}</label>
                        <select id="contentType" class="form-select">
                            <option key="0" value={0}>{t('selectQuestionType')}</option>

                            {questionTypes ? (
                                questionTypes && questionTypes.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.name}>{dt.name} - {dt.description}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="sortId" class="form-label">{t("sortid")}</label>
                        <textarea class="form-control" id="sortId" required />
                    </div>

                    {/* <div class="mb-3">
                        <label for="contentStr" class="form-label">{t("question")}</label>
                        <textarea class="form-control" id="contentStr" required />
                    </div> */}
                    <div >
                        <h1 style={{ textAlign: "center" }}>Soruyu Oluşturunuz!</h1>
                        <div style={{ display: "grid", justifyContent: "center" }}>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="write your content ...."
                                onChange={handleProcedureContentChange1}
                                style={{ height: "auto", width: "auto" }}
                            >
                            </ReactQuill>
                        </div>
                    </div>
                    <br /><br /><hr /> <br /> <br /> <br />
                    <div >
                        <h1 style={{ textAlign: "center" }}>Cevabı Oluşturunuz!</h1>
                        <div style={{ display: "grid", justifyContent: "center" }}>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="write your content ...."
                                onChange={handleProcedureContentChange2}
                                style={{ height: "auto", width: "auto" }}
                            >
                            </ReactQuill>
                        </div>
                    </div>
                    <br /><br /><hr /> <br /> <br /> <br />

                    <div >
                        <h1 style={{ textAlign: "center" }}>Bilgi Kutucuğunu Oluşturunuz!</h1>
                        <div style={{ display: "grid", justifyContent: "center" }}>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="write your content ...."
                                onChange={handleProcedureContentChange3}
                                style={{ height: "auto", width: "auto" }}
                            >
                            </ReactQuill>
                        </div>
                    </div>
                    {/* <div class="mb-3">
                        <label for="detail" class="form-label">{t("answer")}</label>
                        <textarea class="form-control" id="detail" required />
                    </div> */}


                    {/* <div class="mb-3">
                        <label for="notfyText" class="form-label">{t("informationbox")}</label>
                        <textarea class="form-control" id="notfyText" required />
                    </div>
                    <TextEditorrr /> */}
                    <br /><br /><hr /> <br /> <br /> <br />

                    <div class="mb-3">
                        <label for="answer" class="form-label">{t("selectAnswer")}</label>
                        <textarea class="form-control" id="answer" required />
                    </div>
                    <br />   <br />   <br />
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("startToCreate")}</button>
                    </div>

                </form>
            )}

        </div>

    )
}
export default AdminCreateQuestion;



