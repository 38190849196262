import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";

export const AdminCorporateUserList = () => {
    const [data, setData] = useState([]);
    const [students, setStudents] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);

    const { t, i18n } = useTranslation()

    const columns = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("namesurname"),
                Footer: t("namesurname"),
                accessor: 'name',
            },
            {
                Header: t("email"),
                Footer: t("email"),
                accessor: 'email',
            },
            {
                Header: t("schoolId"),
                Footer: t("schoolId"),
                accessor: 'SchoolId',
            },
            {
                Header: t("createdAt"),
                Footer: t("createdAt"),
                accessor: 'createdAt',
            },
            {
                Header: t("expiryDate"),
                Footer: t("expiryDate"),
                accessor: 'expiryDate',
            },
            {
                Header: t("sectionName"),
                Footer: t("sectionName"),
                accessor: 'sectionName',
            },
            {
                Header: t("isActive"),
                Footer: t("isActive"),
                accessor: 'isTrial',
            },
            {
                Header: t("editUser"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>
                    </div>
                ),
                id: 'actionEdit',
                Footer: t("editUser"),
            },

        ],
        [],
    )
    const handleEdit = (row) => {
        window.location.href = "editcorporateuser/" + row['id'];

    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/User/GetUsers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {

                            for (let i = 0; i < res.data['data'].length; i++) {
                                let a = ""
                                if (res.data['data'][i]['user']['isTrial']) {
                                    a = t('passive')
                                }
                                else {
                                    a = t('active')
                                }
                                if (res.data['data'][i]['user']['isManager']) {
                                    data.push({
                                        "id": res.data['data'][i]['user']['id'],
                                        "name": res.data['data'][i]['user']['fullName'],
                                        "email": res.data['data'][i]['user']['username'],
                                        "SchoolId": res.data['data'][i]['detail']['schoolId'],
                                        "isTrial": a,
                                        "expiryDate": res.data['data'][i]['user']['expiryDate'],
                                        "createdAt": res.data['data'][i]['user']['createdAt'],
                                        "sectionName": res.data['data'][i]['detail']['sectionName'],

                                    })
                                }
                                if (!res.data['data'][i]['user']['isInvidual']) {
                                    if (res.data['data'][i]['detail']['schoolId'] !== "null") {
                                        students.push({
                                            "id": res.data['data'][i]['user']['id'],
                                            "name": res.data['data'][i]['user']['fullName'],
                                            "email": res.data['data'][i]['user']['username'],
                                            "SchoolId": res.data['data'][i]['detail']['schoolId'],
                                            "isTrial": a,
                                            "expiryDate": res.data['data'][i]['user']['expiryDate'],
                                            "createdAt": res.data['data'][i]['user']['createdAt'],
                                            "sectionName": res.data['data'][i]['detail']['sectionName'],


                                        })
                                    }
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);




    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h1>{t('corporateuserList')}</h1>
                {msg}
            </div>

            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}

            {!loading && (
                <div>
                    <Datatable data={data} columns={columns} tableBaslik={t('managerList')} />
                </div>
            )}
            <hr />
            {!loading && (
                <div>
                    <Datatable data={students} columns={columns} tableBaslik={t('nonindividualstudentlist')} />
                </div>
            )}
        </div>
    );
};
export default AdminCorporateUserList;
