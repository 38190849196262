import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';


export const EditReview = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();

    function handleSubmit(event) {

        let form = document.getElementById("reviewform");
        const data = new FormData(form);
        let output = "";
        for (const entry of data) {
            output = `${output}${entry[1]}`;
        }

        const postData = {
            "name": document.getElementById("name").value,
            "description":document.getElementById("description").value,
            "text":document.getElementById("text").value,
            "point":output,
            "isSuccess":true
        }
        RequestHelper("/reviews", "put", postData, params["id"]).
            then(
                response => {
                    alert(response.statusText)
                });
                window.location.reload();

    }

    useEffect(() => {
        (async () => {
            const resdata = await RequestHelper("/reviews", "get", "", params["id"])
            setData(resdata.data);
        
        })()
    }, [])

    return (
        <div style={{backgroundColor:"white",padding:"3%"}}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("editreview")}</h1>
            </div>

            <form onSubmit={handleSubmit} id="reviewform">
                <div class="mb-3">
                    <span>{t("reviewRatePoint")} : {data.point}</span> <br/>
                    <fieldset class="rating" id="rating">
                        <input type="radio" id="star5" value="5" name="rating" />
                        <label for="star5">5 stars</label>
                        <input type="radio" id="star4" name="rating" value="4" />
                        <label for="star4">4 stars</label>
                        <input type="radio" id="star3" name="rating" value="3" />
                        <label for="star3">3 stars</label>
                        <input type="radio" id="star2" name="rating" value="2" />
                        <label for="star2">2 stars</label>
                        <input type="radio" id="star1" name="rating" value="1" />
                        <label for="star1">1 star</label>
                    </fieldset>
                </div>
                <br/><br/>
                <div class="mb-3">
                    <label for="text" class="form-label">{t("message")}</label>
                    <textarea class="form-control" id="text" defaultValue={data.text}/>
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">{t("namesurname")}</label>
                    <textarea class="form-control" id="name" defaultValue={data.name}/>
                </div>
                <div class="mb-3">
                    <label for="description" class="form-label">{t("reviewdescription")}</label>
                    <textarea class="form-control" id="description" defaultValue={data.description}/>
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>

        </div>
    );
};
export default EditReview;
