import axios from "axios";

async function getRequest(url,id) {
    if(id)
    {
        url = url + "/" +id;
    }

    const response = await axios.get(url);

    return response;
}
async function postRequest(url, data,id) {
    if(id)
    {
        url = url + "/" +id;
    }
    const headers = {
        'accept: ': '*/*',
        "Content-Type": "application/json-patch+json",
    }
    let urls = "http://api.heryerdeingilizce.com/api/User/Authorize";

    const response = await axios.post(url, data);


    console.log(response);
    return response;
}

async function updateRequest(url, data,id) {
    if(id)
    {
        url = url + "/" +id;
    }
    const response = await axios.put(url, data);
    return response;
}

async function deleteRequest(url, id) {
    if(id)
    {
        url = url + "/" +id;
    }
    const response = await axios.delete(url);
    return response;
}   

/*
        (async () => {
                const res = await RequestHelper("/posts", "put",ex)
                console.log(res);
            })()
*/


const RequestHelper = async (subUrl, reqType, data,id) => {
    const baseUrl = "http://localhost:5000";
    const url = baseUrl + subUrl;
    //const url = "http://www.api.heryerdeingilizce.com/api/User/Authorize";
    let response = [];
    
    if (reqType === "get") {
        response = await getRequest(url,id);
    }
    else if (reqType === "post") {
        response = await postRequest(url, data,id);
    }
    else if (reqType === "delete") {
        response = await deleteRequest(url, id);
    }
    else if (reqType === "put") {
        response = await updateRequest(url, data,id);
    }
    //console.log(response);
    return response;

    //return status 200 değilse mesajı gelsin buraya
    //localstoragedden baerer token çek
}




export default RequestHelper;
