import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const EditCoupen = () => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams();
    const [description, setdescription] = useState([]);
    const [usage, setusage] = useState([]);
    const [percentage, setpercentage] = useState([]);
    const [expiryDate, setexpiryDate] = useState([]);
    const [code, setcode] = useState([]);
    const [createdAt, setcreatedAt] = useState([]);
    const [id, setid] = useState([]);
    const [isUsed, setisUsed] = useState([]);

    const editcode = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/Cupon/Update";
            await axios({
                method: 'Put',
                url: url,
                data: {
                    id: id,
                    createdAt: createdAt,
                    isUsed: isUsed,
                    cuponCode: document.getElementById("coupencode").value,
                    expiryDate: document.getElementById("expiryDate").value,
                    percentage: document.getElementById("coupenpercentage").value,
                    usageLimit: document.getElementById("coupenusagelimit").value,
                    cuponDescription: document.getElementById("coupendescription").value
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        window.location.reload();

                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Cupon/GetByCode";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        code: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setdescription(res.data['data']['cuponDescription']);
                            setusage(res.data['data']['usageLimit']);
                            setpercentage(res.data['data']['percentage']);
                            setexpiryDate(res.data['data']['expiryDate']);
                            setcode(res.data['data']['cuponCode']);
                            setisUsed(res.data['data']['isUsed']);
                            setcreatedAt(res.data['data']['createdAt']);
                            setid(res.data['data']['id']);
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err);
            }
        })()
    }, [])

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("editcoupen")}</h1>
            </div>
            <h4>{msg}</h4>
            <form onSubmit={editcode} style={{ border: "1px solid gray", padding: "10px", backgroundColor: "white" }}>
                <h5>{t('editcoupen')}</h5>
                <br />
                <div class="mb-3">
                    <label for="coupencode" class="form-label">{t("coupencode")}</label>
                    <textarea class="form-control" id="coupencode" name="coupencode" defaultValue={code} required />
                </div>
                <div class="mb-3">
                    <label for="coupendescription" class="form-label">{t("coupendescription")}</label>
                    <textarea class="form-control" id="coupendescription" name="coupendescription" defaultValue={description} required />
                </div>
                <div class="mb-3">
                    <label class="form-label" for="expiryDate">{t("coupenexpiryDate")}</label>
                    <input type="datetime-local" class="form-control" id="expiryDate" name="expiryDate" defaultValue={expiryDate} required />
                </div>
                <div class="mb-3">
                    <label class="form-label" for="coupenpercentage">{t("coupenpercentage")}</label>
                    <textarea class="form-control" id="coupenpercentage" name="coupenpercentage" defaultValue={percentage} required />
                </div>

                <div class="mb-3">
                    <label class="form-label" for="coupenusagelimit">{t("coupenusagelimit")}</label>
                    <textarea class="form-control" id="coupenusagelimit" name="coupenusagelimit" defaultValue={usage} required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>

            </form>


            {/* 
<form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="username" class="form-label">{t("email")}</label>
                    <textarea class="form-control" id="username" value={userName} />
                </div>

                <div class="mb-3">
                    <label for="useremail" class="form-label">User Email</label>
                    <textarea class="form-control" id="useremail" value={fullName} />
                </div>
                <div class="mb-3">
                    <label for="useremail" class="form-label">Okul İd</label> <br />
                    <i>Değiştirmek için oluşturulan okulun id sini giriniz.</i>
                    <textarea class="form-control" id="useremail" value="3" />
                </div>
                <div class="mb-3">
                    <label for="useremail" class="form-label">Üyelik Tarihi: {data.date}</label>
                </div>
                <div class="mb-3">
                    <label for="useremail" class="form-label">Üyelik Bitiş Tarihi: {data.finishDate}</label>
                </div>
                <div class="mb-3">
                    <label for="subjectId" class="form-label">{t("isActive")}</label>
                    <select id="subjectId" class="form-select" aria-label="Default select example">
                        <option key="0" value="0" selected>Aktif</option>
                        <option key="1" value="1">Pasif</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label for="subjectId" class="form-label">Deneme Kullanıcısı mı?</label>
                    <select id="subjectId" class="form-select" aria-label="Default select example">
                        <option key="0" value="0" selected>Hayır</option>
                        <option key="1" value="1">Evet</option>
                    </select>
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                </div>

            </form>
             */}
        </div>
    );
};
export default EditCoupen;
