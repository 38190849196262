import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import RequestHelper from '../../../../../Helpers/RequestHelper';

export const GeneralSettings = () => {
    const [image, setImage] = useState('');
    const [contact, setContact] = useState([]);


    const { t, i18n } = useTranslation();

    function handleChange(e) {
        const formData = new FormData();
        formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
        setImage(formData);
    }

    const handleFile = async () => {
        const headerss = {
            "accept": "*/*",
            "Content-Type": "image/jpeg"
        }
        await axios.post('https://express.heryerdeingilizce.com/logo', image, {
            headers: headerss
        }).then(res => {
            console.log(res);
        });
    }
    function handleSubmit(event) {
        const data = {
            "id": contact.id,
            "isActive": document.getElementById("activite").value,
            "title": contact.title,
            "subtitle": contact.subtitle

        }
        RequestHelper("/notRegister", "put", data, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }
    function handleregister(event) {
        let title = document.getElementById("title").value;
        let subtitle = document.getElementById("subtitle").value;

        if (title === "") {
            title = contact.title;
        }
        if (subtitle === "") {
            subtitle = contact.subtitle;
        }
        const data = {
            "id": contact.id,
            "isActive": contact.isActive,
            "title": title,
            "subtitle": subtitle

        }
        RequestHelper("/notRegister", "put", data, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }

    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/notRegister", "get", "", "1")
            setContact(res.data);
        })()

    }, [])

    return (
        <div className="row">
            <h2>{t('generalsettingsforhomepage')}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <img src="/images/mainlogo.png" alt="logo" style={{ padding: "10%" }} class="img-fluid" />

                </div>
                <div className='col-md-6'>
                    <br />
                    <span>Logo'da görünecek görsel. -***x*** boyutlarında olmalıdır.</span>
                    <form onSubmit={handleFile}>
                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="aboutImage" required />
                        <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                    </form>


                </div>

            </div>
            <hr />


            <div className='row'>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <h2>{t('NotRegisterSettings')}</h2>
                    <h6>{t('currentStatus')} : {contact.isActive}</h6>
                    <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="activite" class="form-label">{t("sectionActivite")}</label>
                            <select id="activite" class="form-select" aria-label="Default select example">
                                <option key="0" value="0">{t("sectionActivite")}</option>
                                <option key="1" value="pasif">{t('passive')}</option>
                                <option key="2" value="aktif">{t('active')}</option>

                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                    </form>

                </div>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <form onSubmit={handleregister} >
                        <div class="mb-3">
                            <label for="title" class="form-label">Başlık</label>
                            <textarea class="form-control" id="title" placeholder={contact.title} />
                        </div>
                        <div class="mb-3">
                            <label for="subtitle" class="form-label">Alt Başlık</label>
                            <textarea class="form-control" id="subtitle" placeholder={contact.subtitle} />
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default GeneralSettings;