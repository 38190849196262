import React, { Component } from "react";
import './assets/css/style.css';
import './assets/css/icomoon.css'
import './assets/css/vendor.css'
import './assets/css/fonts.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class Reviews extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <section id="testimonials" class="ud-testimonials" style={{ marginTop: "-5%" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ud-section-title mx-auto text-center">
                                <span>Testimonials</span>
                                <h2>What our Customers Says</h2>
                                <p>
                                    There are many variations of passages of Lorem Ipsum available
                                    but the majority have suffered alteration in some form.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>Sabo Masties</h4>
                                        <p>Founder @UIdeck</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".15s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>Margin Gesmu</h4>
                                        <p>Founder @Lineicons</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>William Smith</h4>
                                        <p>Founder @GrayGrids</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>William Smith</h4>
                                        <p>Founder @GrayGrids</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>William Smith</h4>
                                        <p>Founder @GrayGrids</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>William Smith</h4>
                                        <p>Founder @GrayGrids</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>William Smith</h4>
                                        <p>Founder @GrayGrids</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div
                                class="ud-single-testimonial wow fadeInUp"
                                data-wow-delay=".2s"
                            >
                                <div class="ud-testimonial-ratings">
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />
                                    <FontAwesomeIcon icon="fas fa-star" />

                                </div>
                                <div class="ud-testimonial-content">
                                    <p>
                                        “Our members are so impressed. It's intuitive. It's clean.
                                        It's distraction free. If you're building a community.
                                    </p>
                                </div>
                                <div class="ud-testimonial-info">

                                    <div class="ud-testimonial-meta">
                                        <h4>William Smith</h4>
                                        <p>Founder @GrayGrids</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Reviews;