import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';

export const SystemUsage = () => {
    const { t, i18n } = useTranslation();
    const [usage, setUsage] = useState([]);
    // function addParagraph(e) {

    // }
    // function deleteParagraph(e) {

    // }
    function systemusage(e) {
        let title = document.getElementById("title").value;
        if (title === "") {
            title = usage.title;
        }
        let videoLink = document.getElementById("videoLink").value;
        if (videoLink === "") {
            videoLink = usage.videoLink;
        }
        let paragraphss = [];
        let parag1 = document.getElementById("1").value;
        if (parag1 === "") {
            paragraphss[0] = {
                "id": 1,
                "text": usage.paragraphs[0].text,
                "isActive": true
            }
        }
        else {
            paragraphss[0] = {
                "id": 1,
                "text": parag1,
                "isActive": true
            }
        }
    
        let parag2 = document.getElementById("2").value;
        if (parag2 === "") {
            paragraphss[1] = {
                "id": 2,
                "text": usage.paragraphs[1].text,
                "isActive": true
            }
        }
        else {
            paragraphss[1] = {
                "id": 2,
                "text": parag2,
                "isActive": true
            }
        }

        let parag3 = document.getElementById("3").value;
        if (parag3 === "") {
            paragraphss[2] = {
                "id": 3,
                "text": usage.paragraphs[2].text,
                "isActive": true
            }
        }
        else {
            paragraphss[2] = {
                "id": 3,
                "text": parag3,
                "isActive": true
            }
        }

        let parag4 = document.getElementById("4").value;
        if (parag4 === "") {
            paragraphss[3] = {
                "id": 4,
                "text": usage.paragraphs[3].text,
                "isActive": true
            }
        }
        else {
            paragraphss[3] = {
                "id": 4,
                "text": parag4,
                "isActive": true
            }
        }

        const datas = {
            "title": title,
            "videoLink":videoLink,
            "paragraphs": paragraphss
        }
        RequestHelper("/usage", "put", datas, "1").then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    useEffect(() => {
        (async () => {
            const usage = await RequestHelper("/usage", "get", "", "1")
            setUsage(usage.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('systemusage')}</h2>

            <form onSubmit={systemusage}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">Başlık</label>
                        <textarea class="form-control" id="title" placeholder={usage.title} />
                    </div>
                </div>

                <div class="mb-3">
                    <div class="mb-3">
                        <label for="videoLink" class="form-label">{t('videolink')} //Video Linki yerine video yükleme getirilecektir.!</label>
                        <textarea class="form-control" id="videoLink" placeholder={usage.videoLink} />
                    </div>
                </div>
                {usage.paragraphs && usage.paragraphs.map((dt) => {
                    return (
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for={dt.id} class="form-label">{t('paragraphs')} {dt.id}</label>
                                <textarea class="form-control" id={dt.id} placeholder={dt.text} />
                            </div>
                        </div>
                    )
                })
                }
                {/* <div class="mb-3" id='newParagh' style={{visibility:"visible"}}>
                    <div class="mb-3">
                        <label for={usage.paragraphs.length+1} class="form-label">{t('paragraphs')} {usage.paragraphs.length+1}</label>
                        <textarea class="form-control" id={usage.paragraphs.length+1} required />
                    </div>
                </div>
                <button class="btn btn-primary btn-lg" onClick={addParagraph}>Parağraf Ekle</button>
                <button class="btn btn-primary btn-lg" onClick={deleteParagraph}>Parağraf Çıkar</button> */}

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                </div>
            </form>
        </div>
    )
}
export default SystemUsage;