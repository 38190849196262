import React, { useState, useEffect } from "react";
import data from './questions.json'
import Question1 from './Question1'
import Question2 from './Question2'

function PlacementTestHome() {

    const [datas, setDatas] = useState([]);

    /*
        {
            id: 1,
            answer: 0,
            answered: false
        },
        {
            id: 2,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 3,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 4,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 5,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 6,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 7,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 8,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 9,
            answer: 0,
            answered: false
        }
        ,
        {
            id: 10,
            answer: 0,
            answered: false
        }
    */



    function updateFieldChanged(data1, data2) {
        console.log(data[4]);

        //console.log(data1,data2)
        let newArr = [...datas];
        newArr[data1 - 1].answer = data2
        setDatas(newArr);
        //console.log(datas[data1-1])
        if (count == maxQuestion) {
            let newArr = [...datas];
            newArr[count - 1].answered = true;
            setDatas(newArr);
        }
    }
    const [count, setCount] = useState(0);
    const [maxQuestion, setmaxQuestion] = useState(0);
    const increment = () => {
        console.log(data[4]);

        if (datas[count - 1]["answer"] != 0) {
            let newArr = [...datas];
            newArr[count - 1].answered = true;
            setDatas(newArr);

        }
        setCount((c) => c + 1);
    };
    const increments = (a) => {
        setCount((c) => a);
    };
    const decrease = () => {
        if (datas[count - 1]["answer"] != 0) {
            let newArr = [...datas];
            newArr[count - 1].answered = true;
            setDatas(newArr);
        }
        setCount((c) => c - 1);
    };


    useEffect(() => {
        console.log(data[4]);
        data[4]["answered"] = true;
        console.log(data[4]);




        for (let index = 0; index < data.length; index++) {
            setDatas(prevArray => [...prevArray, { id: index + 1, answer: 0, answered: false }])
        }
        setCount((c) => 1);
        setmaxQuestion((c) => data.length);
    }, []);
    function zort() {
        console.log(datas);
    }

    return (
        <div className="container">
            <div className="row">
                Soru:
                {count}
            </div>
            <div className="row">
                <div className="col-md-9">
                    {data &&
                        data.map((dt) => {
                            if (dt.id == count) {
                                if (dt.type == 1) {
                                    return (
                                        <Question1 increment={increment} data={dt} updateFieldChanged={updateFieldChanged} />
                                    );
                                }
                                else if (dt.type == 2) {
                                    return (
                                        <Question2 increment={increment} />
                                    );
                                }
                            }
                        })
                    }
                </div>
                <div className="col-md-3">
                    Kalan süre <br />
                    Tüm Sorular<br />
                    {datas &&
                        datas.map((dt) => {
                            return (
                                <button id={dt.id} onClick={() => increments(dt.id)} style={{ backgroundColor: dt.answered != true ? 'gray' : 'blue' }}>
                                    {dt.id}
                                </button>
                            );
                        })
                    }
                </div>
            </div>
            <br /> <br /> <br />
            <div className="row">
                <div className="col-6" >
                    <button onClick={increment} className={count == maxQuestion ? 'btn btn-primary disabled' : "btn btn-primary"} >
                        Sonraki Soru
                    </button>
                </div>
                <div className="col-6">
                    <button onClick={decrease} className={count != 1 ? 'btn btn-info' : "btn btn-info disabled"}>
                        Önceki Soru
                    </button>
                </div>
                <br />
                <br /><br />
                <button onClick={zort}>
                    Sınavı bitir

                </button>
            </div>
        </div>
    );
}

export default PlacementTestHome;
