import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

export const AdminCreateQuestionDetail = () => {
    const params = useParams()

    //console.log(params["courseid"]);
    useEffect(() => {
        console.log(params["questionTypeid"]);
        console.log(params["subjectid"]);
        console.log(params["tagid"]);

    }, []);





    return (
        //DERS ANLATIM VİDEO İÇİN
        <div>
            sa
        </div>
    )
}
export default AdminCreateQuestionDetail;
