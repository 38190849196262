import React, { Component } from 'react'
import Swal from 'sweetalert2'
import img1 from '../../../images/multiimage1.jpg';
import img2 from '../../../images/multiimage2.jpg';
import img3 from '../../../images/multiimage3.jpg';
import img4 from '../../../images/multiimage4.jpg';




import sound from '../../../images/cr.mp3'
import $ from 'jquery';
import './test.css';
import { BrowserView, MobileView } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class MultiImage extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() { }
    state = {
        audio: new Audio(sound),
        isPlaying: false,
    };

    playPause = () => {
        let isPlaying = this.state.isPlaying;
        if (isPlaying) {
            this.state.audio.pause();
            $('#zort').toggleClass("fas fa-play-circle");
        } else {
            this.state.audio.play();
            $('#zort').toggleClass("fas fa-pause-circle");
        }
        this.setState({ isPlaying: !isPlaying });
        this.state.audio.onended = function () {
            $('#zort').toggleClass("fas fa-play-circle");
        }
    };

    playPause = () => {
        let isPlaying = this.state.isPlaying;
        if (isPlaying) {
            this.state.audio.pause();
            $('#zort').toggleClass("fas fa-play-circle");
        } else {
            this.state.audio.play();
            $('#zort').toggleClass("fas fa-pause-circle");
        }
        this.setState({ isPlaying: !isPlaying });
        this.state.audio.onended = function () {
            $('#zort').toggleClass("fas fa-play-circle");
        }
    };
    render() {

        return (
            <div>

                <nav style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                    <div style={{ position: "absolute", right: "3%", top: "3%" }}>

                        <FontAwesomeIcon icon="fas fa-times-circle" style={{ fontSize: "30" }} />
                    </div>

                </nav>

                <BrowserView>
                    <div className='container'>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "15%", width: "100%", maxWidth: "880px" }}>



                                <div className='row'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ backgroundColor: 'white', fontSize: "20", color: "gray" }}>
                                                    Read the text and complete.
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <span>
                                                        Read the text and complete the centences.

                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: "5%" }}>
                                    <div class="card-group" >
                                        <div class="card">
                                            <img src={img1} style={{ padding: "10px" }} class="card-img-top" alt="..." />
                                        </div>
                                        <div style={{ margin: "3px" }}></div>
                                        <div class="card">
                                            <img src={img2} class="card-img-top" alt="..." />
                                        </div>
                                        <div style={{ margin: "3px" }}></div>

                                        <div class="card">
                                            <img src={img3} class="card-img-top" alt="..." />
                                        </div>
                                        <div style={{ margin: "3px" }}></div>

                                        <div class="card">
                                            <img src={img4} class="card-img-top" alt="..." />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "-35px", marginTop: "2%" }} class="d-flex justify-content-center" id='font' onClick={this.playPause}>
                                    <div style={{ backgroundColor: "white", borderRadius: "50%", border: "10px solid white" }}>
                                        <i id='zort' class="fas fa-play-circle" style={{ color: "blue", fontSize: 30 }}></i>

                                    </div>
                                </div>

                                <div className='row' style={{ backgroundColor: "white", maxHeight: "590", marginTop: "1%" }} >


                                    <br />
                                    <div class="d-flex justify-content-center" style={{ padding: "4%" }}>
                                        <h5>belt</h5>
                                    </div>
                                </div>


                            </div>
                            <div className='col-md-2'></div>
                        </div>




                    </div>
                </BrowserView>
                <MobileView>
                    <br />
                    <div className='container' style={{ marginTop: "5%" }}>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "10%" }}>



                                <div className='row'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ backgroundColor: 'white', fontSize: "20", color: "gray" }}>
                                                    Read the text and complete.
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <span>
                                                        Read the text and complete the centences.

                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='row' style={{ backgroundColor: "white", maxHeight: "590", marginTop: "1%" }} >

                                </div>




                            </div>
                            <div className='col-md-2'></div>
                        </div>

                    </div>
                </MobileView>

                <footer className='justify-content-center' style={{ position: "fixed", bottom: "0", width: "100%", backgroundColor: "white", display: "flex" }} >

                    <div className='row'>


                        <nav aria-label="Page navigation example">
                            <th class="pagination ">
                                <td class="page-item disabled">
                                    <a class="page-link" >Previous</a>
                                </td>
                                <td class="page-item" ><a class="page-link" style={{ backgroundColor: "blue", color: "white" }} href="#">1</a></td>
                                <td class="page-item"><a class="page-link" href="#">2</a></td>
                                <td class="page-item"><a class="page-link" href="#">3</a></td>
                                <td class="page-item">
                                    <a class="page-link" href="#">Next</a>
                                </td>
                            </th>
                        </nav>

                    </div>


                </footer>

            </div>
        )
    }
}
