import React, { useState } from 'react'
import Swal from 'sweetalert2'
import $ from 'jquery';

function Question1(props) {


    return (
        <div>
            

            <h3>{props.data.title}</h3>
            {props.data.options &&
                props.data.options.map((dt) => {
                    return (
                        <div key={dt.id}>
                            <button key={dt.answerId} onClick={() => props.updateFieldChanged(props.data.id,dt.answerId)} >{dt.text}</button>      
                            <br/>   
                            <br/>                
                        </div>    
                    );
                })
            }


        </div>
    )
}
export default Question1;