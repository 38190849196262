import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import RequestHelper from '../../../../Helpers/RequestHelper';
import { useAuth } from '../../../../context/AuthContext';

function TeacherClassSettings() {
    const params = useParams();
    const [data, setData] = useState([]);
    const [courses, setCourses] = useState([]);
    const [levels, setLevels] = useState([]);
    const { user } = useAuth();

    function handleSubmit(event) {
        const data = {
            "classname": document.getElementById("classname").value,
            "courseId":document.getElementById("selectCourse").value,
            "levelId": document.getElementById("selectLevel").value,
            "schoolId":user.id
        }
        RequestHelper("/classes", "put", data,params['id']).
            then(
                response => {
                    alert(response.statusText)
                });
    }
    function onChange(event) {
        let courseId = document.getElementById("selectCourse").value;
        (async () => {
            let url = "/levels?courseId=" + courseId;
            const res = await RequestHelper(url, "get")
            setLevels(res.data);
        })()
    }
    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/classes", "get", "", params['id'])
            setData(res.data);
            const rescourses = await RequestHelper("/courses", "get", "")
            setCourses(rescourses.data);

        })()
    }, [])

    return (
        <div>

            <br />

            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>Class Settings</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div class="mb-3">
                            <label for="classname" class="form-label">Class Name</label>
                            <textarea type="text" class="form-control" id="classname" defaultValue={data.classname} />
                        </div>
                        <div class="mb-3">
                            <label for="selectCourse" class="form-label">Course Name</label>
                            <select id="selectCourse" class="form-select" aria-label="Default select example" onChange={onChange}>
                                {courses ? (
                                    courses && courses.map((dt) => {
                                        if (dt.id === data.courseId) {
                                            return (
                                                <option key={dt.id} value={dt.id} selected>{dt.courseName}</option>
                                            )
                                        }
                                        else {
                                            return (
                                                <option key={dt.id} value={dt.id}>{dt.courseName}</option>
                                            )
                                        }
                                    })
                                ) : (
                                    <p>Please Reflesh page</p>
                                )
                                }
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="selectLevel" class="form-label">Select Level</label>
                            <select id="selectLevel" class="form-select">
                                <option key="" value="">Please select the level</option>
                                {levels ? (
                                    levels && levels.map((dt) => {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.levelName}</option>
                                        )
                                    })
                                ) : (
                                    <p>Please Reflesh page</p>
                                )
                                }
                            </select>
                        </div>            

                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">Update</button>

                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default TeacherClassSettings;
