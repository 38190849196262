import React from "react";
import Datatable from "../Components/Datatable"
import { useTranslation } from 'react-i18next';

function TeacherSearchList() {
    const { t, i18n } = useTranslation();

    const datas = [{
        "id": 1,
        "name": "hello",
        "description": "meetings, etc...",
        "wordtr": "merhaba",
        "levelId": 3
    }, {
        "id": 2,
        "name": "look",
        "description": "meetings, etc...",
        "wordtr": "bak",
        "levelId": 3
    }

    ]
    const dataClass = React.useMemo(() => {
        return datas
    }, [])

    const columnsClass = React.useMemo(
        () => [
            {
                Header: 'ID',
                Footer: 'ID',
                accessor: 'id',
            },
            {
                Header: 'word',
                Footer: 'word',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'description',
                Footer: 'description',
                accessor: 'description',
            },
            {
                Header: 'Delete Word',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            Delete
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: 'Delete Word',
            },
    
            {
                Header: 'Edit Word',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            Edit
                        </button>
                    </div>
                ),
                id: 'actionEdit',
                Footer: 'Edit Word',
            },
        ],
        [],
    )
    const handleDelete = (row) => {

        window.location.href = "/dashboard/teacher/editword/:" + row['id'];
    }
    const handleEdit = (row) => {

        window.location.href = "/dashboard/teacher/editword/:" + row['id'];
    }


    return (
        <div>
            <div className="row">
                <div className="col-md">
                    <Datatable data={dataClass} columns={columnsClass} tableBaslik={t("wordlist")} />
                </div>

            </div>


        </div>
    );
}

export default TeacherSearchList;

