import React from 'react'

import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import logo from '../../pages/images/logo.png'

function zort() {
    var element = document.getElementById("sidebar");
    element.classList.toggle("active");
    $('.forsidebar').toggle();
}

function PlacementTestLayout() {

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <div className="nav-sidebar-font-right">
                        <FontAwesomeIcon
                            icon="fa-solid fa-arrow-right"
                            className="navbar-toggler"
                            onClick={zort}
                            style={{ backgroundColor: "blue" }}
                        />
                        <FontAwesomeIcon
                            icon="fa-solid fa-toggle-on"
                            className="navbar-toggler"
                            onClick={zort}
                            style={{ backgroundColor: "blue" }}
                        />
                    </div>

                    <a className="navbar-brand" href="#">
                        <img src={logo} />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a
                                    href="/teachercourse"
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Go To Course Page
                                </a>
                            </li>.
                            <li className="nav-item">
                                <a
                                    href="/teachercourse"
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Go To Course Page
                                </a>
                            </li>
                        </ul>
                        <form className="d-flex" role="search">
                            <a href="/teacherProfile/profile" style={{ fontSize: 20 }} >
                                <i
                                    class="fa fa-user-circle"

                                    aria-hidden="true"
                                ></i>&nbsp;Ahmet Açıksarı
                            </a>
                        </form>
                    </div>
                </div>
            </nav>

            <div class="wrapper">
                <div id="content">
                    <div className="container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlacementTestLayout