import React, { Component } from "react";
import kvkk from "../../kvkk.json"

class KvkkBilgi extends Component {
    componentDidMount() {
    }
    render() {
        return (
            
<div style={{backgroundColor:"white", marginTop:"-1%"}}>
<div className="container" >
    <div style={{textAlign:"center", padding:"10px"}}>
    <h2>KVKK Bilgilendirme Metni</h2>
    </div>
                
                <p style={{padding:"10px"}}>
                {kvkk.paragp1}   
                </p>
                <p style={{padding:"10px"}}>
                {kvkk.paragp2}   
                </p>
                <p style={{padding:"10px"}}>
                {kvkk.paragp3}   
                </p>
                <p style={{padding:"10px"}}>
                {kvkk.paragp4}   
                </p>
                <p style={{padding:"10px"}}>
                {kvkk.paragp5}   
                </p>
                <hr/>
            </div>  
            </div>
            
        );
    }
}

export default KvkkBilgi;