import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserView, MobileView } from 'react-device-detect';

export default class Video extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }
    render() {
        const myStyle3 = {
            width: "50%",
            margin: "auto",
            marginTop: "10%",
        };
        return (
            <div>
                <nav style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                    <div style={{ position: "absolute", right: "3%", top: "3%" }}>

                        <FontAwesomeIcon icon="fas fa-times-circle" style={{ fontSize: "30" }} />
                    </div>

                </nav>

                <br />
                <BrowserView>
                    <div className='container'>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "15%", width: "100%", maxWidth: "880px" }}>



                                <div className='row'>
                                    <div class="alert alert-light" style={{ width: "100%" }}>
                                        A simple light alert—check it out!
                                    </div>
                                </div>
                                <div className='row' style={{ maxHeight: "590", marginTop: "-3%" }} >
                                    <iframe
                                        src="https://player.vimeo.com/video/346802568?h=fe9c8a401c&color=e89152"
                                        width="100%"
                                        height="580"
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <div className='col-md-2'></div>
                        </div>
                    </div>
                </BrowserView>

                <MobileView>
                    <br />
                    <div className='container' style={{ marginTop: "5%" }}>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "10%" }}>



                            <div className='row'>
                                    <div class="alert alert-light" style={{ width: "100%" }}>
                                        A simple light alert—check it out!
                                    </div>
                                </div>
                                <div className='row' style={{   marginTop: "1%" }} >
                                <iframe
                                        src="https://player.vimeo.com/video/346802568?h=fe9c8a401c&color=e89152"
                                        width="100%"
                                        height="100%"
                                        frameborder="0"
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>




                            </div>
                            <div className='col-md-2'></div>
                        </div>

                    </div>
                </MobileView>




                <footer className='justify-content-center' style={{ position: "fixed", bottom: "0", width: "100%", backgroundColor: "white", display: "flex" }} >

                    <div className='row'>


                        <nav aria-label="Page navigation example">
                            <th class="pagination ">
                                <td class="page-item disabled">
                                    <a class="page-link" >Previous</a>
                                </td>
                                <td class="page-item" ><a class="page-link" style={{ backgroundColor: "blue", color: "white" }} href="#">1</a></td>
                                <td class="page-item"><a class="page-link" href="#">2</a></td>
                                <td class="page-item"><a class="page-link" href="#">3</a></td>
                                <td class="page-item">
                                    <a class="page-link" href="#">Next</a>
                                </td>
                            </th>
                        </nav>

                    </div>


                </footer>

            </div>
        )
    }
}
