import { useAuth } from "../../context/AuthContext"
import { useNavigate, Link } from "react-router-dom"
import './login.css'
import './style.css'
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';


export default function VerificationEmail() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const [msg, setMsg] = useState([]);
    const params = useParams();
    const verify = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false'
            }
            let url = "https://api.heryerdeingilizce.com/api/User/VerifyUser";

            let email = params['email'];

            await axios({
                method: 'Post',
                url: url,
                data: {
                    username: email,
                    hash: document.getElementById("hash").value
                },
                headers: headerss
            }).then(res => {

                if (res.status === 200) {
                    if (res.data['success']) {
                        setMsg("Doğrulandı");
                        window.location.href = "/auth/login";
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }


    return (
        <div class="container-fluid h-custom">

            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                        class="img-fluid" alt="Sample image" />
                </div>

                <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1" id="registerForm" >
                    <form onSubmit={verify}>
                        <div class="divider d-flex align-items-center my-4">
                            <h2 class="text-center fw-bold mx-3 mb-0">
                                {t("verifyEmail")}
                            </h2>
                        </div>
                        <h3>{msg}</h3> <br />
                        <div class="form-outline mb-3">
                            <label class="form-label" for="hash">{t("verifyHash")}</label>
                            <textarea type="name" id="hash" name="hash" class="form-control form-control-lg"
                                placeholder={t("verifyHash")} required />
                        </div>

                        <div class="text-center">
                            <button type="submit" class="btn btn-success btn-lg" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}> {t("verify")}</button>

                        </div>
                    </form>

                    <br /><br />

                </div>
            </div>

        </div>
    )
}