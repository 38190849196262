import React from "react";
import { useTranslation } from 'react-i18next';

function TeacherEditStudent() {
    const { t, i18n } = useTranslation();

    const singledata = {
        "id": 1,
        "name": "hello",
        "description": "meetings, etc...",
        "wordtr": "merhaba",
        "levelId": 3
    }
    function onSubmit(event) {

    }
    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t("editword")}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div class="mb-3">
                            <label for="wordname" class="form-label">Word</label>
                            <textarea type="text" class="form-control" id="wordname" value={singledata.name} />
                        </div>
                        <div class="mb-3">
                            <label for="worddes" class="form-label">word description</label>
                            <textarea type="text" class="form-control" id="worddes" value={singledata.description} />
                        </div>
                        <div class="mb-3">
                            <label for="wordtr" class="form-label">word translate</label>
                            <textarea type="text" class="form-control" id="wordtr" value={singledata.wordtr} />
                        </div>

                        {/* <div class="mb-3">
                            <label for="selectClass" class="form-label">Select Class</label>
                            <select id="selectClass" class="form-select">
                                <option>select class</option>
                                {data && data.map((dt) => {
                                    if (dt.id === singledata.levelId) {
                                        return (
                                            <option key={dt.id} value={dt.id} selected>{dt.name} -- {dt.level}</option>
                                        );
                                    }
                                    else {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.name} -- {dt.level}</option>
                                        );
                                    }
                                })}
                            </select>
                        </div> */}


                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">Update</button>

                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default TeacherEditStudent;
