import React, { useState, useEffect } from "react";
import { useAuth } from '../../context/AuthContext';
import RequestHelper from '../../Helpers/RequestHelper';
import './rating.css'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Datatable from "../../pages/Dashboard/Admin/Components/Datatable";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Profile() {
    const { user } = useAuth();
    const { setUser } = useAuth();
    const [ticketList, setTicketList] = useState([]);
    const [packages, setPackages] = useState([]);
    const { t, i18n } = useTranslation();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingticket, setLoadingticket] = useState(true);
    const [selectpackagemessage, setselectpackagemessage] = useState(true);

    const [loadingg, setLoadingg] = useState(true);
    const [msgstudentticket, setMsgstudentticket] = useState([]);
    const [msgmanagerticket, setMsgmanagerticket] = useState([]);

    const [banksdata, setbanksdata] = useState([]);
    const [sections, setSections] = useState([]);

    const navigate = useNavigate();
    const columnsbanks = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("paymentmethod"),
                Footer: t("paymentmethod"),
                accessor: 'typeName',
            }
            ,
            {
                Header: t("bankName"),
                Footer: t("bankName"),
                accessor: 'gatewayName',
            },

            {
                Header: t('ibanNreceiver'),
                Footer: t('ibanNreceiver'),
                accessor: 'typeDescription',
            }



        ],
        [],
    )


    const columnsticket = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("ticketNo"),
                Footer: t("ticketNo"),
                accessor: 'ticketCode',
            }
            ,
            {
                Header: t("ticketCreatedDate"),
                Footer: t("ticketCreatedDate"),
                accessor: 'creationDate',
            },

            {
                Header: t('ticketTitle'),
                Footer: t('ticketTitle'),
                accessor: 'title',
            }
            , {
                Header: t('ticketstate'),
                Footer: t('ticketstate'),
                accessor: 'status',
            },
            {
                Header: t('ticketdetail'),
                Footer: t('ticketdetail'),
                accessor: 'message',
            }, {
                Header: t("showdetails"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handlegetdetails(originalRow)}
                        >
                            {t("showdetails")}
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: t("showdetails"),
            },
        ],
        [],
    )
    const handlegetdetails = (row) => {
        window.location.href = "profile/ticketdetail/" + row['ticketCode'];
    }
    function handleSubmit(event) {
    }
    function handleLogout() {
        setUser(false);
        navigate("/auth/login");



    }
    const handlePaymentStudent = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Subscription/Apply";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    gatewayId: document.getElementById("studentbankId").value,
                    package: document.getElementById("studentpackageId").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Ödeme Talebiniz Başarıyla Alınmıştır!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handlePaymentStudentCoupen = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Subscription/ApplyCupon";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    packageId: document.getElementById("coupenpackageId").value,
                    cuponCode: document.getElementById("coupencode").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Ödeme Talebiniz Başarıyla Alınmıştır!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handlePaymentManager = async (e) => {
        e.preventDefault();
        alert(document.getElementById("managerpackageId").value);
        alert(document.getElementById("managerpackageId"));
        console.log(document.getElementById("managerpackageId"));
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/Subscription/Apply";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    gatewayId: document.getElementById("managerbankId").value,
                    package: document.getElementById("managerpackageId").value,
                },
                headers: headerss
            }).then(res => {
                console.log(res.data);

                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Ödeme Talebiniz Başarıyla Alınmıştır!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handlemanagercreateticket = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/Opt/TicketDetail";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    title: document.getElementById("managertickettitle").value,
                    detail: document.getElementById("managerticketdetail").value,
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Destek talebiniz oluşturulmuştur!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Destek talebiniz oluşturulamadı!",
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsgmanagerticket(res.data['message']);
                    }
                }
                else {
                    setMsgmanagerticket("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsgmanagerticket(err.message);
        }
    }
    const handlestudentcreateticket = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/CreateTicket";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    title: document.getElementById("studenttickettitle").value,
                    detail: document.getElementById("studentticketdetail").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Destek talebiniz oluşturulmuştur!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Destek talebiniz oluşturulamadı!",
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsgstudentticket(res.data['message']);
                    }
                }
                else {
                    setMsgstudentticket("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsgstudentticket(err.message);
        }

    }

    function handleReview(event) {
        let form = document.getElementById("reviewform");
        const data = new FormData(form);
        let output = "";
        for (const entry of data) {
            output = `${output}${entry[1]}`;
        }

        const postData = {
            "name": document.getElementById("name").value,
            "description": document.getElementById("description").value,
            "text": document.getElementById("text").value,
            "point": output,
            "isSuccess": false
        }
        RequestHelper("/reviews", "post", postData).
            then(
                response => {
                    alert(response.statusText)
                });
        document.getElementById("name").value = "";
        document.getElementById("description").value = "";
        document.getElementById("text").value = "";
        event.preventDefault();
    }

    const changePassword = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/UpdatePassword";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    oldPassword: document.getElementById("currentpassword").value,
                    newPassword: document.getElementById("newpassword").value,
                    newPasswordVerify: document.getElementById("newpasswordcheck").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Şifreniz Başarıyla Değiştirilmiştir.",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handleselectsection = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/UpdatePassword";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    oldPassword: document.getElementById("currentpassword").value,
                    newPassword: document.getElementById("newpassword").value,
                    newPasswordVerify: document.getElementById("newpasswordcheck").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Şifreniz Başarıyla Değiştirilmiştir.",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    function handleBack() {
        navigate("/Dashboard");
    }

    function onClick(event) {

        let account = document.getElementById("account");
        let bank = document.getElementById("bank");
        if (event.currentTarget.id === "profile") {
            document.getElementById("profile").classList.add("active");
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("review").classList.remove("active");
            document.getElementById("profiledetail").style.display = "inline";
            document.getElementById("reviewdetail").style.display = "none";


            if (user.user.roleName != "Student") {
                document.getElementById("ticket").classList.remove("active");
                document.getElementById("ticketdetail").style.display = "none";
            }
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
        else if (event.currentTarget.id === "editprofile") {
            document.getElementById("profile").classList.remove("active");
            document.getElementById("editprofile").classList.add("active");
            document.getElementById("review").classList.remove("active");
            document.getElementById("ticket").classList.remove("active");

            document.getElementById("editprofilee").style.display = "inline";
            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticketdetail").style.display = "none";
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
        else if (event.currentTarget.id === "account") {
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("profile").classList.remove("active");
            document.getElementById("account").classList.add("active");
            document.getElementById("bank").classList.remove("active");
            document.getElementById("review").classList.remove("active");

            document.getElementById("editprofilee").style.display = "none";

            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("accountdetail").style.display = "inline";
            document.getElementById("bankdetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticket").classList.remove("active");
            document.getElementById("ticketdetail").style.display = "none";
        }
        else if (event.currentTarget.id === "bank") {
            document.getElementById("profile").classList.remove("active");
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("account").classList.remove("active");
            document.getElementById("bank").classList.add("active");
            document.getElementById("review").classList.remove("active");

            document.getElementById("editprofilee").style.display = "none";
            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("accountdetail").style.display = "none";
            document.getElementById("bankdetail").style.display = "inline";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticket").classList.remove("active");
            document.getElementById("ticketdetail").style.display = "none";
        }
        else if (event.currentTarget.id === "ticket") {
            document.getElementById("profile").classList.remove("active");
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("review").classList.remove("active");

            document.getElementById("editprofilee").style.display = "none";
            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticket").classList.add("active");
            document.getElementById("ticketdetail").style.display = "inline";
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
        else if (event.currentTarget.id === "review") {
            document.getElementById("editprofile").classList.remove("active");

            document.getElementById("profile").classList.remove("active");
            document.getElementById("review").classList.add("active");
            document.getElementById("editprofilee").style.display = "none";

            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "inline";
            document.getElementById("ticket").classList.remove("active");
            document.getElementById("ticketdetail").style.display = "none";
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
    }

    useEffect(() => {
        if (user.user.isTrial) {
            if (user.userDetail.sectionId === 1) {
                setselectpackagemessage("Lütfen bölümünüzü seçiniz.");
            }
        }

        document.getElementById("profile").classList.add("active");
        document.getElementById("profiledetail").style.display = "inline";
        (async () => {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/User/GetGateways";
                    await axios({
                        method: 'GET',
                        url: url,
                        headers: headerss
                    }).then(res => {

                        if (res.status === 200) {
                            if (res.data['success']) {
                                for (let i = 0; i < res.data['data'].length; i++) {
                                    let a = "";
                                    if (res.data['data'][i]['isActive']) {

                                        banksdata.push({
                                            "id": res.data['data'][i]['id'],

                                            "gatewayName": res.data['data'][i]['gatewayName'],
                                            "typeName": res.data['data'][i]['typeName'],
                                            "typeDescription": res.data['data'][i]['typeDescription'],
                                        })
                                    }
                                }
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }
                setLoading(false);
            }
            fetchData();

            const fetchDataPackages = async () => {
                setLoadingg(true);
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/User/GetPackages";
                    await axios({
                        method: 'GET',
                        url: url,
                        headers: headerss
                    }).then(res => {
                        if (res.status === 200) {
                            if (res.data['success']) {
                                for (let i = 0; i < res.data['data'].length; i++) {
                                    let a = user.role;
                                    if (a === "Manager") {
                                        if (res.data['data'][i]['isActive']) {
                                            if (!res.data['data'][i]['isInvidual']) {
                                                packages.push({
                                                    "id": res.data['data'][i]['id'],
                                                    "packageName": res.data['data'][i]['packageName'],
                                                    "packageDetail": res.data['data'][i]['packageDetail'],
                                                    "packagePrice": res.data['data'][i]['packagePrice'],
                                                    "howManyDays": res.data['data'][i]['howManyDays'],
                                                })
                                            }
                                        }
                                    }
                                    else if (a === "Student") {
                                        if (res.data['data'][i]['isActive']) {
                                            if (res.data['data'][i]['isInvidual']) {
                                                packages.push({
                                                    "id": res.data['data'][i]['id'],
                                                    "packageName": res.data['data'][i]['packageName'],
                                                    "packageDetail": res.data['data'][i]['packageDetail'],
                                                    "packagePrice": res.data['data'][i]['packagePrice'],
                                                    "howManyDays": res.data['data'][i]['howManyDays'],
                                                })
                                            }
                                        }
                                    }

                                }
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }
                setLoadingg(false);
            }
            fetchDataPackages();

            const fetchTickets = async () => {
                setLoadingticket(true)
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/user/Opt/TicketList";
                    await axios({
                        method: 'GET',
                        url: url,
                        headers: headerss
                    }).then(res => {

                        if (res.status === 200) {
                            if (res.data['success']) {
                                for (let i = 0; i < res.data['data'].length; i++) {
                                    let a = "";
                                    if (res.data['data'][i]['status'] === "open") {
                                        a = t('openedTicket');
                                    }
                                    else if (res.data['data'][i]['status'] === "waiting") {
                                        a = t('waitingticket');
                                    }
                                    else if (res.data['data'][i]['status'] === "answered") {
                                        a = t('answeredTickets');
                                    }
                                    ticketList.push({
                                        "id": res.data['data'][i]['id'],
                                        "ticketCode": res.data['data'][i]['ticketCode'],
                                        "creationDate": res.data['data'][i]['creationDate'],
                                        "title": res.data['data'][i]['title'],
                                        "message": res.data['data'][i]['message'],
                                        "status": a,
                                    })
                                }
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }
                setLoadingticket(false)

            }
            fetchTickets();

            // const fetchsection = async () => {
            //     setLoading(true);

            //     try {
            //         const headerss = {
            //             "accept": "*/*",
            //             'Content-Type': 'application/json',
            //             'Access-Control-Allow-Credentials': 'false',
            //             'Authorization': 'Bearer ' + user.token
            //         }
            //         let url = "https://api.heryerdeingilizce.com/api/api/user/Content/GetSections";

            //         await axios({
            //             method: 'GET',
            //             url: url,
            //             headers: headerss
            //         }).then(res => {

            //             if (res.status === 200) {
            //                 for (let i = 0; i < res.data['data'].length; i++) {
            //                     if (res.data['data'][i]['isDeleted'] === false) {
            //                         let activity = "";
            //                         if (res.data['data'][i]['isActive']) {
            //                             activity = t('active');
            //                         }
            //                         else {
            //                             activity = t('passive');
            //                         }
            //                         sections.push({
            //                             "id": res.data['data'][i]['id'],
            //                             "sectionName": res.data['data'][i]['sectionName'],
            //                             "sectionDescription": res.data['data'][i]['sectionDescription'],
            //                         })
            //                     }

            //                 }
            //                 if (res.data['success']) {

            //                 }
            //                 else {
            //                     setMsg(res.data['message']);
            //                 }
            //             }
            //             else {
            //                 setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
            //             }
            //         });
            //     }
            //     catch (err) {
            //         setMsg(err);
            //     }
            //     setLoading(false);
            // }
            // fetchsection();

        })()
    }, []);


    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-3" style={{ marginTop: "3%" }}>
                    <div class="list-group list-group-flush" >
                        <button style={{ marginBottom: "5%" }} className="btn btn-info" onClick={handleBack}>Panele Dön</button>

                        <a class="list-group-item list-group-item-action" id="profile" onClick={onClick} >{t("profile")}</a>
                        <a class="list-group-item list-group-item-action" id="editprofile" onClick={onClick} >{t("editProfile")}</a>

                        <a class="list-group-item list-group-item-action" id="ticket" onClick={onClick}>{t("ticketssettings")}</a>
                        <a class="list-group-item list-group-item-action" id="review" onClick={onClick}>{t("reviewsettings")}</a>
                        <a class="list-group-item list-group-item-action" id="account" onClick={onClick}>{t("packages&prices")}   </a>
                        <a class="list-group-item list-group-item-action" id="bank" onClick={onClick}>{t("financialoperations")}</a>
                        <button style={{ marginTop: "5%", marginBottom: "3%" }} className="btn btn-secondary" onClick={handleLogout}> {t("singout")}</button>

                    </div>
                </div>

                <div className="col-sm-9" style={{ backgroundColor: "white", marginTop: "3%" }}>
                    <br />
                    <h3>{msg}</h3>
                    <h4> {selectpackagemessage} </h4>
                    <br />
                    <div id="profiledetail" style={{ display: "none" }}>
                        <div style={{ textAlign: "center" }}>
                            <h3>{t("profile")}</h3>
                        </div>
                        <form onSubmit={handleSubmit} style={{ border: "1px solid black", padding: "2%" }}>
                            <div class="mb-3">
                                <label for="name" class="form-label">{t("namesurname")}</label>
                                <textarea class="form-control" id="name" defaultValue={user.user.fullName} />
                            </div>

                            <div class="mb-3">
                                <label for="email" class="form-label">{t("email")}</label>
                                <textarea class="form-control" id="email" defaultValue={user.user.username} />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                            </div>

                        </form>
                        <br />
                        <hr />
                        {/* <form onSubmit={handleselectsection} style={{ border: "1px solid black", padding: "2%" }}>
                            <div class="mb-3">
                                <label for="sectionId" class="form-label">{t('selectBank')}</label>
                                <select id="sectionId" class="form-select" aria-label="Default select example">
                                    <option key="" value="">{t('selectSection')}</option>
                                    {sections && sections.map((dt) => {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.sectionName} & {dt.sectionDescription}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                            </div>

                        </form> */}
                    </div>
                    {
                        <div id="editprofilee" style={{ display: "none" }}>
                            <div style={{ textAlign: "center" }}>
                                <h3>{t("changepassword")}</h3>
                            </div>
                            <form onSubmit={changePassword} style={{ border: "1px solid black", padding: "2%" }}>
                                <div class="mb-3">
                                    <label for="currentpassword" class="form-label">{t("currentpassword")}</label>
                                    <input type="password" class="form-control" id="currentpassword" required />
                                </div>

                                <div class="mb-3">
                                    <label for="newpassword" class="form-label">{t("newpassword")}</label>
                                    <input type="password" class="form-control" id="newpassword" required />
                                </div>

                                <div class="mb-3">
                                    <label for="newpasswordcheck" class="form-label">{t("newpassword")}</label>
                                    <input type="password" class="form-control" id="newpasswordcheck" required />
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <button type="submit" class="btn btn-primary btn-lg">{t("changepassword")}</button>
                                </div>

                            </form>
                            <br />
                        </div>
                    }

                    <div id="accountdetail" style={{ display: "none" }}>
                        <div style={{ textAlign: "center" }}>
                            <h3>{t('packages&prices')}</h3>
                        </div>
                        <br />
                        <hr />
                        <div style={{ textAlign: "center" }}>
                            <h4>{t("packagesList")}</h4>
                        </div>
                        {loadingg && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                        {!loadingg && (
                            <div style={{ overflowX: "auto", border: "1px solid black" }}>

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">{t("id")}</th>

                                            <th scope="col">{t("packageName")}</th>
                                            <th scope="col">{t("packageDescription")}</th>
                                            <th scope="col">{t("packagePrice")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packages && packages.map((dt) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{dt.id}</th>
                                                    <td>{dt.packageName}</td>
                                                    <td>{dt.packageDetail}</td>
                                                    <td>{dt.packagePrice}</td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}


                        <hr />
                        <br />  <br />
                    </div>
                    <div id="bankdetail" style={{ display: "none" }}>
                        <h1>{t("paymentmethodlist")}</h1>

                        {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                        {!loading && (
                            <div>
                                <Datatable data={banksdata} columns={columnsbanks} tableBaslik={t("paymentmethodlist")} />
                            </div>
                        )}
                        <hr />
                        <div style={{ textAlign: "center", backgroundColor: "white", border: "1px solid black", padding: "1%", margin: "3%" }}>
                            <h4>{t('paymentRequest')}</h4>
                            <br />
                            <div className="row">
                                <div className="col-md-6" style={{ border: "1px solid gray", padding: "3%" }}>
                                    <h5>{t('usingpaymentmethod')}</h5>
                                    <br />
                                    {
                                        (() => {
                                            if (user.user.roleName === "Student") {
                                                return (
                                                    <form onSubmit={handlePaymentStudent} >
                                                        <h6>{msg}</h6>
                                                        <div class="mb-3">
                                                            <label for="studentbankId" class="form-label">{t('selectBank')}</label>
                                                            <select id="studentbankId" class="form-select" aria-label="Default select example">
                                                                <option key="" value="">{t('selectBank')}</option>
                                                                {banksdata && banksdata.map((dt) => {
                                                                    return (
                                                                        <option key={dt.id} value={dt.id}>{dt.gatewayName} & {dt.typeName}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label for="studentpackageId" class="form-label">{t('selectPackage')}</label>
                                                            <select id="studentpackageId" class="form-select" aria-label="Default select example">
                                                                <option key="" value="">{t('selectPackage')}</option>
                                                                {packages && packages.map((dt) => {
                                                                    return (
                                                                        <option key={dt.id} value={dt.id}>{dt.packageName}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>


                                                        <div className='d-flex justify-content-center'>
                                                            <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                                        </div>
                                                        <br />
                                                    </form>
                                                );
                                            }
                                        })()
                                    }

                                    {
                                        (() => {
                                            if (user.user.roleName === "Manager") {
                                                return (
                                                    <form onSubmit={handlePaymentManager} >
                                                        <div class="mb-3">
                                                            <label for="managerbankId" class="form-label">{t('selectBank')}</label>
                                                            <select id="managerbankId" class="form-select" aria-label="Default select example">
                                                                <option key="" value="">{t('selectBank')}</option>
                                                                {banksdata && banksdata.map((dt) => {
                                                                    return (
                                                                        <option key={dt.id} value={dt.id}>{dt.gatewayName} & {dt.typeName}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label for="managerpackageId" class="form-label">{t('selectSection')}</label>
                                                            <select id="managerpackageId" class="form-select" aria-label="Default select example">
                                                                <option key="" value="">{t('selectSection')}</option>
                                                                {packages && packages.map((dt) => {
                                                                    return (
                                                                        <option key={dt.id} value={dt.id}>{dt.packageName}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                        <span>Paket seçiniz yada size özel iletilen paket id yi aşağıya yazıp kaydı tamamlayınız.</span>
                                                        <div class="mb-3">
                                                            <label for="managerpackageId" class="form-label">{t("packageId")}</label>
                                                            <textarea class="form-control" id="managerpackageId" />
                                                        </div>
                                                        <div className='d-flex justify-content-center'>
                                                            <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                                        </div>
                                                        <br />
                                                    </form>
                                                );
                                            }
                                        })()
                                    }
                                </div>
                                <div className="col-md-6" style={{ border: "1px solid gray", padding: "3%" }}>
                                    <h5>{t('usingcoupencode')}</h5>
                                    <br />
                                    {
                                        (() => {
                                            if (user.user.roleName === "Student") {
                                                return (
                                                    <form onSubmit={handlePaymentStudentCoupen} >
                                                        <div class="mb-3">
                                                            <label for="coupenpackageId" class="form-label">{t('selectPackage')}</label>
                                                            <select id="coupenpackageId" class="form-select" aria-label="Default select example">
                                                                <option key="" value="">{t('selectPackage')}</option>
                                                                {packages && packages.map((dt) => {
                                                                    return (
                                                                        <option key={dt.id} value={dt.id}>{dt.packageName}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="coupencode" class="form-label">{t('coupencode')}</label>
                                                            <textarea class="form-control" id="coupencode" required />
                                                        </div>

                                                        <div className='d-flex justify-content-center'>
                                                            <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                                        </div>
                                                        <br />
                                                    </form>
                                                );
                                            }
                                        })()
                                    }

                                </div>
                            </div>
                            <br />


                        </div>
                        <br />
                    </div>

                    <div id="ticketdetail" style={{ display: "none" }}>
                        <div style={{ textAlign: "center" }}>
                            <h3>{t("ticketssettings")}</h3>
                        </div>
                        {
                            (() => {
                                if (user.user.roleName === "Manager") {
                                    return (
                                        <div style={{ overflowX: "auto", border: "1px solid black" }}>
                                            {loadingticket && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                                            {!loadingticket && (
                                                <div>
                                                    <Datatable data={ticketList} columns={columnsticket} tableBaslik={t("ticketlist")} />
                                                </div>
                                            )}
                                            <hr /><br />
                                            <div style={{ textAlign: "center", backgroundColor: "white" }}>
                                                <h4>{t("answerTicket")}</h4>
                                                <h6> {msgmanagerticket} </h6>

                                                <form onSubmit={handlemanagercreateticket} style={{ border: "1px solid black", padding: "2%" }} >
                                                    <div class="mb-3">
                                                        <label for="managertickettitle" class="form-label">{t("ticketTitle")}</label>
                                                        <textarea class="form-control" id="managertickettitle" required />
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="managerticketdetail" class="form-label">{t("ticketText")}</label>
                                                        <textarea class="form-control" id="managerticketdetail" required />
                                                    </div>

                                                    <div className='d-flex justify-content-center'>
                                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                                    </div>
                                                </form>
                                                <br />
                                            </div>
                                        </div>
                                    );
                                }
                                else if (user.user.roleName === "Student") {
                                    return (
                                        <div style={{ overflowX: "auto", border: "1px solid black", padding: "2px" }}>
                                            {loadingticket && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                                            {!loadingticket && (
                                                <div>
                                                    <Datatable data={ticketList} columns={columnsticket} tableBaslik={t("ticketlist")} />
                                                </div>
                                            )}
                                            <br />
                                            <hr />
                                            <div style={{ textAlign: "center", backgroundColor: "white" }}>
                                                <h4>{t("createticket")}</h4>
                                                <h6> {msgstudentticket} </h6>
                                                <form onSubmit={handlestudentcreateticket} style={{ padding: "2%" }} >
                                                    <div class="mb-3">
                                                        <label for="studenttickettitle" class="form-label">{t("ticketTitle")}</label>
                                                        <textarea class="form-control" id="studenttickettitle" required />
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="studentticketdetail" class="form-label">{t("ticketText")}</label>
                                                        <textarea class="form-control" id="studentticketdetail" required />
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                                    </div>
                                                </form>
                                                <br />
                                            </div>
                                        </div>

                                    );

                                }
                            })()
                        }

                        <br />
                    </div>

                    <div id="reviewdetail" style={{ display: "none" }}>
                        <div style={{ textAlign: "center", border: "3px solid white", backgroundColor: "white" }}>
                            <h3>{t("createreview")}</h3>
                            <form onSubmit={handleReview} id="reviewform" style={{ border: "1px solid black", padding: "2%" }}>
                                <div class="mb-3">
                                    <label for="rating">{t("reviewRate")}</label><br />
                                    <fieldset class="rating" id="rating">
                                        <input type="radio" id="star5" value="5" name="rating" />
                                        <label for="star5">5 stars</label>
                                        <input type="radio" id="star4" name="rating" value="4" />
                                        <label for="star4">4 stars</label>
                                        <input type="radio" id="star3" name="rating" value="3" />
                                        <label for="star3">3 stars</label>
                                        <input type="radio" id="star2" name="rating" value="2" />
                                        <label for="star2">2 stars</label>
                                        <input type="radio" id="star1" name="rating" value="1" />
                                        <label for="star1">1 star</label>
                                    </fieldset>
                                </div>
                                <br /><br />
                                <div class="mb-3">
                                    <label for="text" class="form-label">{t("message")}</label>
                                    <textarea class="form-control" id="text" required />
                                </div>
                                <div class="mb-3">
                                    <label for="name" class="form-label">{t("namesurname")}</label>
                                    <textarea class="form-control" id="name" required />
                                </div>
                                <div class="mb-3">
                                    <label for="description" class="form-label">{t("reviewdescription")}</label>
                                    <textarea class="form-control" id="description" required />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button type="submit" class="btn btn-primary btn-lg">Create</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}
export default Profile;