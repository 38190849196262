import React, { Component } from "react";

class Howtouse extends Component {
    componentDidMount() {
    }
    render() {
        return (
            
            <div className="container" style={{padding:"2%"}}>
                <h2>Sistem Nasıl kullanılır? </h2>
                <p>
                Lorem Ipsum
"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit..."
"There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain..."
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce cursus est non fringilla auctor. Nam hendrerit scelerisque elementum. Nam rhoncus a tortor quis mollis. Integer vitae dapibus urna, in efficitur metus. Praesent ex arcu, molestie sit amet consectetur non, convallis vel arcu. Nulla nec augue quis dui posuere vulputate. Duis a augue at ex molestie tincidunt et et sem. Vestibulum consequat, nisl eu bibendum tincidunt, massa tellus consequat orci, sed pulvinar nisl sem vitae nisi. Sed risus metus, vehicula eget eros nec, viverra viverra metus.

Sed convallis metus consectetur aliquet maximus. <br/>Proin pulvinar, purus vitae ultrices mollis, quam nunc finibus tortor, et eleifend ligula ligula id risus. Mauris in blandit ante. Praesent sed iaculis enim. Nunc ut tincidunt mauris. Curabitur non imperdiet mi. Nulla at laoreet felis. Integer sem ante, porta a sagittis quis, porttitor ac nibh.

Etiam facilisis imperdiet tortor. Morbi ultrices, felis non porttitor semper, lacus ipsum rutrum erat, eget varius massa lacus a ante. Integer sed consequat neque, ut lobortis enim. Integer porttitor massa et semper mattis. Sed placerat eleifend euismod. Nunc vel ex dolor. Quisque est mauris, porta ut velit at, rhoncus varius lorem. Aliquam efficitur sodales diam at malesuada. Proin aliquam efficitur rhoncus. Phasellus elementum risus non nisi auctor rutrum. Integer porttitor massa posuere felis fermentum, sed scelerisque ligula vulputate. Nulla tincidunt feugiat tellus eu consequat. In vel velit auctor, luctus ipsum id, iaculis nisl. Nam vestibulum sollicitudin quam, sed maximus nunc vehicula vitae. Etiam orci nibh, ultricies sit amet purus ut, dapibus iaculis sem.

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean scelerisque velit quis turpis hendrerit dictum. Mauris facilisis commodo sapien, consequat elementum erat malesuada mattis. Pellentesque molestie ex vitae libero dapibus cursus. Etiam tellus diam, consequat in sem at, tempor consectetur mauris. Nullam sed dui metus. Aenean eleifend est augue, vitae viverra lacus convallis nec. Maecenas eget nibh magna. Mauris posuere efficitur eleifend. Mauris ut lorem in quam elementum viverra.

                </p>
            </div>
        );
    }
}

export default Howtouse;