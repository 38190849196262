import React from "react";
import { Chart } from "react-google-charts";

export const datatimespent = [
    ["Date  ", "Hour"],
    ["2023-11-03", 0.2],
    ["2023-12-03", 0.4],
    ["2023-13-03", 0.6],
    ["2023-14-03", 0.8],
];
export const optionstimespent = {
    title: "Time Spent",
    curveType: "function",
    series: [{ color: "#E7711B" }],
    intervals: { style: "area" },
    legend: "none",
};
function TimeInfo () {
    return (
        <div>
            <Chart
                chartType="LineChart"
                width="100%"
                height="300px"
                data={datatimespent}
                options={optionstimespent}
            />
        </div>
    );
}

export default TimeInfo;
