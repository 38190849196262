import React, { useState, useEffect } from "react";
import RequestHelper from '../../../../Helpers/RequestHelper';
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";


function ManagerCreateClass() {
    const [sections, setSections] = useState([]);
    const [teachers, setTeachers] = useState([]);

    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/CreateClass";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    isDeleted: false,
                    schoolId: user.userDetail.schoolId,
                    className: document.getElementById("className").value,
                    description: document.getElementById("classdescription").value,
                    lecturerId: document.getElementById("lecturerId").value,
                    sectionId: document.getElementById("sectionId").value
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }




    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetSections";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data']['sections'].length; i++) {
                                if (!res.data['data']['sections'][i]['isDeleted']) {
                                    if (res.data['data']['sections'][i]['isActive']) {
                                        sections.push({
                                            id: res.data['data']['sections'][i]['id'],
                                            sectionName: res.data['data']['sections'][i]['sectionName'],
                                            sectionDescription: res.data['data']['sections'][i]['sectionDescription'],
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetLecturers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isDeleted'] === false) {
                                    teachers.push({
                                        "id": res.data['data'][i]['id'],
                                        "fullName": res.data['data'][i]['fullName'],
                                    })
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, [])




    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t("createclass")}</h2>
                </div>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div className="card-body">
                        <form onSubmit={handleSubmit} >
                            <div class="mb-3">
                                <label for="className" class="form-label"> {t('className')} </label>
                                <textarea type="text" class="form-control" name="className" id="className" required />
                            </div>
                            <div class="mb-3">
                                <label for="classdescription" class="form-label"> {t('classdescription')} </label>
                                <textarea type="text" class="form-control" name="classdescription" id="classdescription" required />
                            </div>

                            <div class="mb-3">
                                <label for="lecturerId" class="form-label">{t('teacherId')}</label>
                                <select id="lecturerId" name="lecturerId" class="form-select" required>
                                    <option key="" value="">{t('pleaseselectteacher')}</option>
                                    {teachers ? (
                                        teachers && teachers.map((dt) => {
                                            return (
                                                <option key={dt.id} value={dt.id}>{dt.fullName}</option>
                                            )
                                        })
                                    ) : (
                                        <p>Please Reflesh page</p>
                                    )
                                    }
                                </select>
                            </div>

                            <div class="mb-3">
                                <label for="sectionId" class="form-label">{t('selectSection')}</label>
                                <select id="sectionId" name="sectionId" class="form-select" required>
                                    <option key="" value="">{t('selectSection')}</option>
                                    {sections ? (
                                        sections && sections.map((dt) => {
                                            return (
                                                <option key={dt.id} value={dt.id}>{dt.sectionName} <br />  {dt.sectionDescription} </option>
                                            )
                                        })
                                    ) : (
                                        <p>Please Reflesh page</p>
                                    )
                                    }
                                </select>
                            </div>

                            <div className="d-flex justify-content-center">
                                <button type="submit" class="btn btn-primary btn-lg"> {t('create')} </button>
                            </div>



                        </form>
                    </div>
                )}
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default ManagerCreateClass;
