import React from "react";

function UserVocabulary () {

    return (
        <div className="card">

            <div className="card-body" style={{float:"left", marginBottom:"1%"}}>
                <div class="row-md" style={{position:"fixed"}}>
                    <div class="alert alert-light" role="alert" >
                        Live fas sadasd 
                    </div>
                </div>
            </div>
            <br/>
            <hr />
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2" style={{ margin: "auto" }}>
                        <div class="alert alert-light" role="alert" style={{ textAlign: "center" }}>
                            Live
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <p class="card-text">to spend your life in a certain way</p>
                            <p class="card-text">to have your home somewhere
                            </p>
                            <p class="card-text">to be or stay alive
                            </p>

                        </div>
                    </div>
                    <div class="col-md-2" style={{ textAlign: "center", margin: "auto" }}>
                        verb
                    </div>
                </div>
            </div>
            <hr />
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2" style={{ margin: "auto" }}>
                        <div class="alert alert-light" role="alert" style={{ textAlign: "center" }}>
                            Live
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <p class="card-text">to spend your life in a certain way</p>
                            <p class="card-text">to have your home somewhere
                            </p>
                            <p class="card-text">to be or stay alive
                            </p>

                        </div>
                    </div>
                    <div class="col-md-2" style={{ textAlign: "center", margin: "auto" }}>
                        verb
                    </div>
                </div>
            </div>

        </div>
    );
}
export default UserVocabulary;
