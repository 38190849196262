import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import logo from '../../images/logo.png'
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from "react-router-dom"

function TeacherCourseLayout () {

    const navigate = useNavigate()
    const { user } = useAuth();
    if (!user) {
        navigate("/login");
    }
    else {
        if (user['roleId'] !== 7) {
            alert("yetki");
            navigate("/index");
        }
        else{
            return (
                <div>
                    <nav className="navbar navbar-expand-lg bg-body-tertiary">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#"><img src={logo} />  </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a href="/teacher" type="button" className="btn btn-primary"> Go To Report Page</a>
                                    </li>
    
                                </ul>
                                <form className="d-flex" role="search">
                                <a href="/profile"   style={{ fontSize: 20 }} >
                                        <i
                                            class="fa fa-user-circle"
                                            
                                            aria-hidden="true"
                                        ></i>&nbsp;Ahmet Açıksarı
                                    </a>
                                </form>
                            </div>
                        </div>
                    </nav>
    
                    <div>
                        <Outlet />
                    </div>
    
    
    
    
                </div>
            );
        }
    }

}

export default TeacherCourseLayout;
