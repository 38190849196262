import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";

export const CoupenList = () => {
    const [data, setData] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()



    const columns = React.useMemo(
        () => [
            {
                Header: t("coupencode"),
                Footer: t("coupencode"),
                accessor: 'coupencode',
            }
            ,
            {
                Header: t("coupendescription"),
                Footer: t("coupendescription"),
                accessor: 'coupendescription',
            }
            ,
            {
                Header: t("coupenexpiryDate"),
                Footer: t("coupenexpiryDate"),
                accessor: 'coupenexpiryDate',
            }
            ,
            {
                Header: t("coupenusage"),
                Footer: t("coupenusage"),
                accessor: 'coupenusage',
            }
            ,
            {
                Header: t("coupenpercentage"),
                Footer: t("coupenpercentage"),
                accessor: 'coupenpercentage',
            }
            ,
            {
                Header: t("coupenusagelimit"),
                Footer: t("coupenusagelimit"),
                accessor: 'coupenusagelimit',
            }
            ,

            {
                Header: t("edit"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>
                    </div>
                ),
                id: 'actionEdit',
                Footer: t("edit"),
            },
        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "editcoupen/" + row['coupencode'];

    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Cupon/AllList";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {

                            for (let i = 0; i < res.data['data'].length; i++) {
                                let a = "";
                                if (res.data['data'][i]['isUsed']) {
                                    a = "kullanıldı";
                                }
                                else {
                                    a = "kullanılabilir";
                                }
                                data.push({

                                    "id": res.data['data'][i]['id'],
                                    "coupencode": res.data['data'][i]['cuponCode'],
                                    "coupendescription": res.data['data'][i]['cuponDescription'],
                                    "coupenexpiryDate": res.data['data'][i]['expiryDate'],
                                    "coupenpercentage": res.data['data'][i]['percentage'],
                                    "coupenusagelimit": res.data['data'][i]['usageLimit'],
                                    "coupenusage": a,

                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);




    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>

            <div style={{ textAlign: "center" }}>

                <h1>{t("coupenlist")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("coupenlist")} />
                    </div>
                )}
            </div>
        </div>
    )
}
export default CoupenList;