import React, { Component } from 'react';
import question from '../images/question.jpg';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import sound from '../images/cr.mp3'
import './test.css';



class CourseHome3 extends Component {
    state = {
        audio: new Audio(sound),
        isPlaying: false,
    };
    playPause = () => {
        console.log("sa");
        let isPlaying = this.state.isPlaying;
        if (isPlaying) {
            this.state.audio.pause();
        } else {
            this.state.audio.play();
        }
        this.setState({ isPlaying: !isPlaying });
        let strt = document.getElementById("fontstart");
        let stop = document.getElementById("fontstop");
        //console.log(strt.icon);
        //const au= new Audio(sound);



        if (strt.style.visibility == "visible") {

            $('#fontstart').css("visibility", "hidden");
            $('#fontstop').css("visibility", "visible");

        }
        else {
            $('#fontstart').css("visibility", "visible");
            $('#fontstop').css("visibility", "hidden");
        }
    };
    render() {
        function play() {
            let strt = document.getElementById("fontstart");
            let stop = document.getElementById("fontstop");
            //console.log(strt.icon);
            //const au= new Audio(sound);



            if (strt.style.visibility == "visible") {

                $('#fontstart').css("visibility", "hidden");
                $('#fontstop').css("visibility", "visible");

            }
            else {
                $('#fontstart').css("visibility", "visible");
                $('#fontstop').css("visibility", "hidden");
            }
            console.log();
            //new Audio(sound).play();
        }
        const handleClick = event => {

        };
        return (
            <div class="d-flex justify-content-center" >
                {/* */}
                <div>
                    <br />
                    <div class="accordion" id="accordionExample" style={{ textAlign: "center", alignItems: "center" }}>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Look at the photo and complete sentence.
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>
                                        Look at the photo and complete sentence. Im or You re
                                    </strong>
                                </div>
                            </div>

                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                    </div>
                    <br />
                    <div style={{ alignItems: "center" }}>
                        <img src={question} style={{ width: "100%" }} />
                    </div>
                    <br />
                    <div class="d-flex justify-content-center"  >
                        {/*<i class="fa fa-play" style={{display:"inline-block",fontSize:25,color:"blue", borderRadius: "50%",border:"1px solid blue"}} aria-hidden="true"></i>*/}
                        <FontAwesomeIcon onClick={this.playPause} icon="fa-solid fa-play" id="fontstart" size='lg' style={{ color: "blue", visibility: "visible" }} />
                        <FontAwesomeIcon onClick={this.playPause} icon="fa-solid fa-pause" id='fontstop' size='lg' style={{ color: "blue", visibility: "hidden" }} />
                    </div>
                    <br />
                    <div class="d-flex justify-content-center">
                        <input type="text" name="sa" className="test" />
                    </div>
                </div>
            </div>  
        );
    }
}
export default CourseHome3;