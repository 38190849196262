

import React,{ useParams } from "react-router-dom"
import cat from "../../images/cat.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Carousel.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import exampledata from "./exampledata.json";

import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
export default function CourseDetailPage(){

    let { id } = useParams();

    return(
        <div style={{
            backgroundImage: `url(${cat})`,
            backgroundSize: "cover",
            height: "100%",
        }}>
            <div style={{ textAlign: "center" }}>
                {exampledata && exampledata.map((dt) => {
                    return (
                        <h1>{dt.title}</h1>
                    )
                })}
                <h3>You are ready to Learn, Let's Start!
                </h3>
            </div>

            <Swiper
                effect={"coverflow"}
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={30}
                grabCursor={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation, EffectCoverflow]}
                navigation={true}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 50,
                    },
                }}
                className="mySwiper"
            >

                {exampledata && exampledata.map((dt) => {
                    return (
                        <div key={dt.id}>
                            {dt.data.map((aa) => {
                                return (
                                    <SwiperSlide key={aa.id} className="SwiperSlide">
                                        <div class="card">


                                            {aa.typeId == 1 &&
                                                <div style={{ alignItems: "center" }}>
                                                    <FontAwesomeIcon icon="fas fa-volume-up" className="faCircle" style={{ fontSize: "25px", border: "solid", borderRadius: "80%", padding: "2px", backgroundColor: "green" }} /> <br />
                                                </div>
                                            }

                                            {aa.typeId == 2 &&
                                                <div style={{ alignItems: "center" }}>
                                                    <FontAwesomeIcon icon="fas fa-pencil-alt" className="faCircle" style={{ fontSize: "25px", border: "solid", borderRadius: "80%", padding: "2px", backgroundColor: "green" }} /> <br />
                                                </div>
                                            }
                                            {aa.typeId == 3 &&
                                                <div style={{ alignItems: "center" }}>
                                                    <FontAwesomeIcon icon="fas fa-keyboard" className="faCircle" style={{ fontSize: "25px", border: "solid", borderRadius: "80%", padding: "2px", backgroundColor: "green" }} /> <br />
                                                </div>
                                            }
                                            {aa.typeId == 4 &&
                                                <div style={{ alignItems: "center" }}>
                                                    <FontAwesomeIcon icon="fas fa-book-open" className="faCircle" style={{ fontSize: "25px", border: "solid", borderRadius: "80%", padding: "2px", backgroundColor: "green" }} /> <br />
                                                </div>
                                            }

                                            <div className="card-header">
                                                {aa.name}
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">{aa.detail}<br />{aa.type}</h5>
                                                <p class="card-text">
                                                    {dt.name}
                                                    {id}
                                                </p>
                                            </div>

                                            {aa.state == 0 &&
                                                <div className="card-footer" style={{ backgroundColor: "blue" }}>
                                                    <a href={id+"/lesson/"+aa.id} >
                                                        <FontAwesomeIcon icon="fas fa-check" style={{ fontSize: "30px" }} />
                                                    </a>
                                                </div>
                                            }
                                            {aa.state == 1 &&
                                                <div className="card-footer">
                                                    <a href={id+"/lesson/"+aa.id} style={{ color: "blue" }}>
                                                        Continue lesson
                                                        <span style={{ float: "right" }}>
                                                            <FontAwesomeIcon icon="fas fa-long-arrow-alt-right" style={{ fontSize: "25" }} />
                                                        </span>                                                        
                                                        </a>
                                                </div>
                                            }
                                            {aa.state == 2 &&
                                                <div className="card-footer">
                                                    <a style={{ alignItems: "center" }}>
                                                        start lesson
                                                        <span style={{ float: "right" }}>
                                                            <FontAwesomeIcon icon="fas fa-long-arrow-alt-right" style={{ fontSize: "25" }} />
                                                        </span>
                                                    </a>
                                                </div>
                                            }



                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </div>
                    )
                })}


            </Swiper>
        </div>
    )
}


