import YouTube from "react-youtube";
import React, { Component } from 'react';
import question from '../../images/question.jpg';
//import examplequestion1 from "../examplequestion1.json";
import Swal from 'sweetalert2'

import sound from '../../images/cr.mp3'
class QuestionTypeTwo extends Component {
    componentDidMount() {

    }


    render() {

        function play() {
            console.log(); 
            new Audio(sound).play();
        }


        let counter = 0;
        const handleClick = event => {
            var txt = document.getElementById(event.currentTarget.id);
            if (txt.innerText != ".") {
                counter = counter + 1;
                if (counter == 1) {
                    var item = document.getElementById("firstu");
                    item.innerText = txt.innerText;
                    txt.innerText = ".";

                } else if (counter == 2) {
                    var item = document.getElementById("secondu");
                    item.innerText = txt.innerText;
                    txt.innerText = ".";

                }
                else if (counter == 3) {
                    var item = document.getElementById("thirdu");
                    item.innerText = txt.innerText;
                    txt.innerText = ".";

                } else {
                    var item = document.getElementById("fourthu");
                    item.innerText = txt.innerText;
                    txt.innerText = ".";

                    //bitti doğru yanlış kt 
                    /*    
                    Swal.fire({
                        title: "Good job!",
                        text: "You clicked the button!",
                        icon: "success"
                    });
*/



                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    });

                    
                    play();


                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    }

                    );
                }
            }


        };

        return (
            <div class="d-flex justify-content-center" >


                {/* */}
                <div>
                    <div class="accordion" id="accordionExample" style={{ textAlign: "center", alignItems: "center" }}>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Look at the photo and complete sentence.
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>
                                        Look at the photo and complete sentence. Im or You re
                                    </strong>
                                </div>
                            </div>

                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                    </div>
                    <br />
                    <div style={{ alignItems: "center" }}>
                        <img src={question} style={{ width: "100%" }} />
                    </div>
                    <br />

                    <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                        <span>&nbsp;</span>
                        <span><u id="firstu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                        <span>&nbsp;</span>
                        <span><u id="secondu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                        <span>&nbsp;</span>
                        <span><u id="thirdu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                        <span>&nbsp;</span>
                        <span><u id="fourthu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                        <span>&nbsp;</span>
                    </div>
                    <div class="d-flex justify-content-center">

                        <button className="btn btn-primary" id="first" onClick={handleClick}>
                            i
                        </button>&nbsp;
                        <button className="btn btn-primary" id="second" onClick={handleClick}>
                            am
                        </button>&nbsp;
                        <button className="btn btn-primary" id="third" onClick={handleClick}>
                            twenty
                        </button>&nbsp;
                        <button className="btn btn-primary" id="fourth" onClick={handleClick}>
                            years old
                        </button>


                    </div>
                </div>


            </div>


        );
    }

}

export default QuestionTypeTwo;