import React, { Component } from "react";
class PlacementTestHome extends Component {
    componentDidMount() { }
    render() {
        return (
            <div>
                <br/>
                    <h1>SinaV Sayfasi</h1>
                    <div style={{alignItems:"center"}}>
                        <button>
                            <a href="/placement/exam"> 
                                Sınava Başla!
                            </a>
                        </button>
                    </div>
            </div>
        );
    }
}

export default PlacementTestHome;
