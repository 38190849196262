import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import axios from "axios";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function SchoolEditTeacher() {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams();
    const [data, setData] = useState([]);


    const onSumbit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/UpdateLecturer";
            await axios({
                method: 'Put',
                url: url,
                data: {
                    username: document.getElementById("username").value,
                    fullName: document.getElementById("fullName").value,
                    id: data.id,
                    isDeleted: data.isDeleted,
                    password: data.password,
                    roleId: data.roleId,
                    createdAt: data.createdAt,
                    lastLogin: data.lastLogin,
                    expiryDate: data.expiryDate,
                    isTrial: data.isTrial,
                    isVerified: data.isVerified,
                    isInvidual: data.isInvidual,
                    isStudent: data.isStudent,
                    isManager: data.isManager,
                    schoolId: data.schoolId,
                    classId: data.classId,
                    sectionId: data.sectionId,
                    userNote: data.userNote,
                    status: data.status,
                    refreshToken: data.refreshToken

                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        alert(res.data)
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetLecturerById";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        lecturerId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        setData(res.data['data'])
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err);
            }
        })()
    }, [])

    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2> {t('editteacher')} </h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSumbit}>
                        <br />
                        <h4> {msg} </h4>
                        <h5> {t('userId')} : {data.id}</h5>
                        <div class="mb-3">
                            <label for="username" class="form-label">{t('email')}</label>
                            <textarea type="email" class="form-control" name="username" id="username" defaultValue={data.username} />
                        </div>
                        <div class="mb-3">
                            <label for="fullName" class="form-label"> {t('namesurname')} </label>
                            <textarea class="form-control" name="fullName" id="fullName" defaultValue={data.fullName} />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">Update</button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default SchoolEditTeacher;
