import React, { useState, useEffect } from "react";
import Datatable from "../Components/Datatable"
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ManagerClassList() {
    const [classes, setClasses] = useState([]);
    const { t, i18n } = useTranslation();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [teachers, setTeachers] = useState([]);
    const [sections, setSections] = useState([]);
    const { user } = useAuth();

    const columnsDetail = React.useMemo(
        () => [
            {
                Header: t('id'),
                Footer: t('id'),
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: t('className'),
                Footer: t('className'),
                accessor: 'className', // accessor is the "key" in the data
            },
            {
                Header: t('classdescription'),
                Footer: t('classdescription'),
                accessor: 'description',
            },
            {
                Header: t('teacherId'),
                Footer: t('teacherId'),
                accessor: 'lecturerId',
            },
            {
                Header: t('sectionId'),
                Footer: t('sectionId'),
                accessor: 'sectionId',
            },
            {
                Header: t('deleteclass'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t('delete')}
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: t('deleteclass'),
            },
            {
                Header: t('editclass'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editclass'),
            },
        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/school/editclass/" + row['id'];
    }
    const handleDelete = (row) => {
        Swal.fire({
            title: t('areyousureyouwanttodelete'),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: t('yes'),
            denyButtonText: t('no'),
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/DeleteClass";
                    axios({
                        method: 'DELETE',
                        url: url,
                        params: {
                            classId: row['id'],
                        },
                        headers: headerss
                    }).then(res => {
                        if (res.status === 200) {
                            if (res.data['success']) {
                                Swal.fire(t('successfull'), '', 'success');
                            }
                            else {
                                setMsg(res.data['message']);
                                Swal.fire({
                                    title: res.data['message'],
                                    icon: "error",
                                    timer: 3000,
                                    confirmButtonText: `Tamam`
                                }).then(() => {
                                    window.location.reload();
                                });
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }
            } else if (result.isDenied) {
                Swal.fire(t('itsCanceled'), '', 'info')
            }
        })
    }

    useEffect(() => {

        const fetchteacher = async () => {
            setLoading(true);
            let teacher = "";
            let section = "";

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetLecturers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isDeleted'] === false) {
                                    teachers.push({
                                        "id": res.data['data'][i]['id'],
                                        "fullName": res.data['data'][i]['fullName'],
                                    })
                                }

                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetSections";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data']['sections'].length; i++) {
                                if (!res.data['data']['sections'][i]['isDeleted']) {
                                    if (res.data['data']['sections'][i]['isActive']) {
                                        sections.push({
                                            id: res.data['data']['sections'][i]['id'],
                                            sectionName: res.data['data']['sections'][i]['sectionName'],
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetClasses";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {

                            for (let i = 0; i < res.data['data'].length; i++) {
                                for (let j = 0; j < teachers.length; j++) {
                                    if (teachers[j]['id'] === res.data['data'][i]['lecturerId']) {
                                        teacher = teachers[j]['fullName'];
                                    }
                                }

                                for (let k = 0; k < sections.length; k++) {
                                    if (sections[k]['id'] === res.data['data'][i]['sectionId']) {
                                        section = sections[k]['sectionName'];
                                    }
                                }

                                classes.push({
                                    "id": res.data['data'][i]['id'],
                                    "className": res.data['data'][i]['className'],
                                    "description": res.data['data'][i]['description'],
                                    "lecturerId": teacher,
                                    "sectionId": section,
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }

            setLoading(false);
        }
        fetchteacher();
    }, [])
    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("classlist")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={classes} columns={columnsDetail} tableBaslik={t("classlist")} />
                    </div>
                )}
            </div>



        </div>
    );
}
export default ManagerClassList;
