import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import axios from "axios";
import { useTranslation } from 'react-i18next';

export const FooterSettings = () => {
    const { t, i18n } = useTranslation();
    const [contact, setContact] = useState([]);
    const [socails, setSocials] = useState([]);

    function handleTwitter(event) {
        const data = {
            "twitter": document.getElementById("twitter").value,
            "id":socails.id,
            "youtube":socails.youtube,
            "instagram":socails.instagram
        }
        RequestHelper("/socialMedia", "put", data,"1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handleYoutube(event) {
        const data = {
            "youtube": document.getElementById("youtube").value,
            "id":socails.id,
            "twitter":socails.twitter,
            "instagram":socails.instagram
        }
        RequestHelper("/socialMedia", "put", data,"1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handleInstagram(event) {
        const data = {
            "instagram": document.getElementById("instagram").value,
            "id":socails.id,
            "twitter":socails.twitter,
            "youtube":socails.youtube
        }
        RequestHelper("/socialMedia", "put", data,"1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handleSubmit(e) {
        let title = document.getElementById("title").value;
        let email = document.getElementById("email").value;
        let phonenumber = document.getElementById("phonenumber").value;
        let address = document.getElementById("address").value;
        if (title === "") {
            title = contact.title;
        }
        if (email === "") {
            email = contact.email;
        }
        if (phonenumber === "") {
            phonenumber = contact.phonenumber;
        }        
        if (address === "") {
            address = contact.address;
        }
        const data = {
            "id":contact.id,
            "title": title,
            "phonenumber":phonenumber,
            "email":email,
            "address":address,
        }
        RequestHelper("/contact", "put", data,"1").then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    useEffect(() => {
        (async () => {
            const contactt = await RequestHelper("/contact", "get", "", "1")
            const social = await RequestHelper("/socialMedia", "get", "", "1")
            setSocials(social.data);
            setContact(contactt.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('socialmediasettings')}</h2>
            <br/>
            <h4>linkler örnektekiler gibi olmalıdır.</h4>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>INSTAGRAM</h3>
                    <form onSubmit={handleInstagram} >
                        <div class="mb-3">
                            <label for="instagram" class="form-label">Instagram Hesabı URL'i</label>
                            <textarea class="form-control" id="instagram" placeholder={socails.instagram}/>
                        </div>
                        
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">Kaydet</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>YOUTUBE</h3>
                    <form onSubmit={handleYoutube} >
                        <div class="mb-3">
                            <label for="youtube" class="form-label">Youtube Hesabı URL'i</label>
                            <textarea class="form-control" id="youtube" placeholder={socails.youtube}/>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">Kaydet</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>TWITTER</h3>
                    <form onSubmit={handleTwitter} >
                        <div class="mb-3">
                            <label for="twitter" class="form-label">Twitter Hesabı URL'i</label>
                            <textarea class="form-control" id="twitter" placeholder={socails.twitter}/>
                        </div>
                        {/* <div class="mb-3">
                            <label for="isActive" class="form-label">Aktif/Pasif</label>
                            <select id="isActive" class="form-select" aria-label="Default select example">
                                <option key="0" value="0" selected>Pasif</option>
                                <option key="1" value="1" selected>Aktif</option>
                            </select>
                        </div> */}
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">Kaydet</button>
                        </div>
                    </form>
                </div>
            </div>
            <hr/> <br/> <hr/> <br/>
            <h2>{t('contactsettings')}</h2>
            <form onSubmit={handleSubmit} >
                    <div class="mb-3">
                        <label for="title" class="form-label">Başlık</label>
                        <textarea class="form-control" id="title" placeholder={contact.title} />
                    </div>       
                    <div class="mb-3">
                        <label for="email" class="form-label">{t("email")}</label>
                        <textarea class="form-control" id="email" placeholder={contact.email} />
                    </div>
                    <div class="mb-3">
                        <label for="phonenumber" class="form-label">{t("phone")}</label>
                        <textarea class="form-control" id="phonenumber" placeholder={contact.phonenumber} />
                    </div>
                    <div class="mb-3">
                        <label for="address" class="form-label">{t("address")}</label>
                        <textarea class="form-control" id="address" placeholder={contact.address} />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                    </div>

            </form>
        </div>
    )
}
export default FooterSettings;