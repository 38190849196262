import { useParams } from "react-router-dom"
import React, { useEffect } from 'react';
import './Lesson.css'
import data from './LessonDataEx.json'
import Video from "./QuestionTypes/Video";
import PickImage from "./QuestionTypes/PickImage";
import Voice from "./QuestionTypes/Voice";
import Read from "./QuestionTypes/Read";
import MultiImage from "./QuestionTypes/MultiImage";


export default function CourseLessonDetail() {
    useEffect(() => {
        console.log("Component mounted or updated!");
    });
    const params = useParams();
    console.log(params);
    //bu paramlara göre ilgili kısmı sorgulara ve tüm bölümleri al 
    //sonra step step göster ekranda
    const myStyle = {
        position: "relative"
    };
    const myStyle2 = {
        position: "absolute",
        top: 15,
        right: 15
    };
    const myStyle3 = {
        width: "50%",
        margin: "auto",
        marginTop: "10%",
    };


    return (
        <div>

            {data &&
                data.map((dt) => {
                    if (dt.type == 1) {
                        return (
                            <Video name="sa" />
                        );
                    }
                    else if(dt.type == 2) {
                        return (
                            <PickImage name="sa" />
                        );
                    }
                    else if(dt.type == 3) {
                        return (
                            <Voice name="sa" />
                        );
                    }
                    else if(dt.type == 4) {
                        return (
                            <Read name="sa" />
                        );
                    }
                    else if(dt.type == 5) {
                        return (
                            <MultiImage name="sa" />
                        );
                    }
                })}
        </div>

    )
}