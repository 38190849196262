import React from "react";
import Datatable from "../List/Datatable"
import dataClasss from '../ExampleData/dataClass.json'
import dataSchooll from '../ExampleData/dataSchool.json'
import dataAlll from '../ExampleData/dataAll.json'

function UserList () 
{
    const dataClass = React.useMemo(() => {
        return dataClasss
    }, [])
    const dataSchool = React.useMemo(() => {
        return dataSchooll
    }, [])
    const dataAll = React.useMemo(() => {
        return dataAlll
    }, [])
    const columnsClass = React.useMemo(
        () => [
            {
                Header: 'Öğrenci Adı',
                Footer: 'Öğrenci Adı',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Sıralaması',
                Footer: 'Sıralaması',
                accessor: 'arrangement',
            }
        ],
        [],
    )
    const columnsSchool = React.useMemo(
        () => [
            {
                Header: 'Öğrenci Adı',
                Footer: 'Öğrenci Adı',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Sıralaması',
                Footer: 'Sıralaması',
                accessor: 'arrangement',
            }
            ,
            {
                Header: 'Sınıfı',
                Footer: 'Sınıfı',
                accessor: 'class',
            }
        ],
        [],
    )
    const columnsAll = React.useMemo(
        () => [
            {
                Header: 'Öğrenci Adı',
                Footer: 'Öğrenci Adı',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Sıralaması',
                Footer: 'Sıralaması',
                accessor: 'arrangement',
            }            
            ,
            {
                Header: 'Sınıfı',
                Footer: 'Sınıfı',
                accessor: 'school',
            }
        ],
        [],
    )
    return (

        <div className="container-fluid">
            <Datatable data={dataClass} columns={columnsClass} tableBaslik="Sınıf Listesi" />
            <Datatable data={dataSchool} columns={columnsSchool} tableBaslik="Okul Listesi" />
            <Datatable data={dataAll} columns={columnsAll} tableBaslik="Tüm Kullanıcıların Listesi" />

        </div>

    );
}
export default UserList;
