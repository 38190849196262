import React, { Component } from 'react';
import question from '../../images/question.jpg';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import sound from '../../images/cr.mp3'
import '../CoursePanel/test.css';


class QuestionTypeThree extends Component {
    state = {
        audio: new Audio(sound),
        isPlaying: false,
    };

    playPause = (   ) => {
        let isPlaying = this.state.isPlaying;
        if (isPlaying) {
            this.state.audio.pause();
            $('#zort').toggleClass("fas fa-play-circle");
        } else {
            this.state.audio.play();
            $('#zort').toggleClass("fas fa-pause-circle");
        }   
        this.setState({ isPlaying: !isPlaying });
        this.state.audio.onended=function()
        {
            $('#zort').toggleClass("fas fa-play-circle");         
        }
    };

    render() {
    

        return (
            <div class="d-flex justify-content-center" >
                <div>
                    <br />
                    <div class="accordion" id="accordionExample" style={{ textAlign: "center", alignItems: "center" }}>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Look at the photo and complete sentence.
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <strong>
                                        Look at the photo and complete sentence. Im or You re
                                    </strong>
                                </div>
                            </div>

                        </div>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                    </div>
                    <br />
                
                    <div style={{ alignItems: "center" }}>
                        <img src={question} style={{ width: "100%" }} />
                    </div>
                    <br />
                    <div class="d-flex justify-content-center" id='font'  onClick={this.playPause}>
                        <i id='zort' class="fas fa-play-circle" style={{ color: "blue",fontSize:30}}></i>
                    </div>
                    <br />
                    <div class="d-flex justify-content-center">
                        <input type="text" name="sa" className="test" />
                    </div>
                </div>
            </div>  
        );
    }
}
export default QuestionTypeThree;