import React, { useState, useEffect } from "react";
import { useAuth } from '../../context/AuthContext';
import RequestHelper from '../../Helpers/RequestHelper';
import './rating.css'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Datatable from "../../pages/Dashboard/Admin/Components/Datatable";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useParams } from 'react-router-dom';

export const UserTicketDetail = () => {
    const params = useParams();
    const { user } = useAuth();
    const [ticketDetail, setticketDetail] = useState([]);
    const [ticketAnswers, setticketAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [msg, setMsg] = useState([]);
    const [answermsg, setanswerMsg] = useState([]);
    const [answerloading, setanswerLoading] = useState(true);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user.user.roleName === "Student") {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token

                }
                let url = "https://api.heryerdeingilizce.com/api/user/Opt/ReplyTicket";
                await axios({
                    method: 'Get',
                    url: url,
                    params: {
                        message: document.getElementById("answer").value,
                        ticketId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            Swal.fire({
                                title: "Başırılı!",
                                icon: "success",
                                confirmButtonText: `Tamam`
                            });
                            setanswerMsg("Başarıyla Cevaplandı.");

                        }
                        else {
                            setanswerMsg(res.data['message']);

                            Swal.fire({
                                title: res.data['message'],
                                icon: "error",
                                confirmButtonText: `Tamam`
                            });
                        }
                    }
                    else {
                        setanswerMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });

            } catch (err) {
                setanswerMsg(err);
            }
        }
        else if (user.user.roleName === "Manager") {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token

                }
                let url = "https://api.heryerdeingilizce.com/api/manager/Opt/ReplyTicket";
                await axios({
                    method: 'Get',
                    url: url,
                    params: {
                        message: document.getElementById("answer").value,
                        ticketId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            Swal.fire({
                                title: "Başırılı!",
                                icon: "success",
                                confirmButtonText: `Tamam`
                            });
                            setanswerMsg("Başarıyla Cevaplandı.");

                        }
                        else {
                            setanswerMsg(res.data['message']);

                            Swal.fire({
                                title: res.data['message'],
                                icon: "error",
                                confirmButtonText: `Tamam`
                            });
                        }
                    }
                    else {
                        setanswerMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });

            } catch (err) {
                setanswerMsg(err);
            }
        }
        setanswerLoading(false);

    }
    useEffect(() => {
        (async () => {
            setLoading(true);
            if (user.user.roleName === "Student") {
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/user/Opt/TicketDetail";
                    await axios({
                        method: 'GET',
                        url: url,
                        params: {
                            ticketId: params["id"]
                        },
                        headers: headerss
                    }).then(res => {
                        if (res.status === 200) {
                            if (res.data['success']) {
                                console.log(res.data['data'])
                                let a = "";
                                if (res.data['data']['ticket']['status'] === "open") {
                                    a = t('openedTicket');
                                }
                                ticketDetail.push({
                                    clientName: res.data['data']['clientName'],
                                    ticket: {
                                        message: res.data['data']['ticket']['message'],
                                        userId: res.data['data']['ticket']['userId'],
                                        title: res.data['data']['ticket']['title'],
                                        creationDate: res.data['data']['ticket']['creationDate'],
                                        ticketCode: res.data['data']['ticket']['ticketCode'],
                                        status: a,
                                    }
                                })
                                for (let i = 0; i < res.data['data']['ticketMessages'].length; i++) {
                                    let c = "";
                                    if (res.data['data']['ticketMessages'][i]['isItAnswer']) {
                                        c = t('userAnswer')
                                    }
                                    else {
                                        c = t('adminAnswer')

                                    }
                                    ticketAnswers.push({
                                        messageDate: res.data['data']['ticketMessages'][i]['messageDate'],
                                        message: res.data['data']['ticketMessages'][i]['message'],
                                        isItAnswer: c,

                                    })
                                }
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                } catch (err) {
                    setMsg(err);
                }
            }
            else if (user.user.roleName === "Manager") {
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/manager/Opt/TicketDetail";
                    await axios({
                        method: 'GET',
                        url: url,
                        params: {
                            ticketId: params["id"]
                        },
                        headers: headerss
                    }).then(res => {
                        console.log(res.data)
                        if (res.status === 200) {
                            if (res.data['success']) {
                                console.log(res.data['data'])
                                let a = "";
                                if (res.data['data']['ticket']['status'] === "open") {
                                    a = t('openedTicket');
                                }
                                else if (res.data['data']['ticket']['status'] === "waiting") {
                                    a = t('waitingticket');
                                }
                                else if (res.data['data']['ticket']['status'] === "answered") {
                                    a = t('answeredTickets');
                                }
                                ticketDetail.push({
                                    clientName: res.data['data']['clientName'],
                                    ticket: {
                                        message: res.data['data']['ticket']['message'],
                                        userId: res.data['data']['ticket']['userId'],
                                        title: res.data['data']['ticket']['title'],
                                        creationDate: res.data['data']['ticket']['creationDate'],
                                        ticketCode: res.data['data']['ticket']['ticketCode'],
                                        status: a,
                                    }
                                })
                                for (let i = 0; i < res.data['data']['ticketMessages'].length; i++) {
                                    ticketAnswers.push({
                                        messageDate: res.data['data']['ticketMessages'][i]['messageDate'],
                                        message: res.data['data']['ticketMessages'][i]['message'],
                                    })
                                }
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                } catch (err) {
                    setMsg(err);
                }
            }


            setLoading(false);


        })()
    }, [])






    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h2>{t('ticketDetail')}</h2>

                <h3>{msg}</h3>
            </div>
            {!loading && (
                <div>
                    {ticketDetail[0]['clientName']}
                    <br />
                    <div>
                        <h3>{t('ticketText')}</h3>
                        <b> {t('ticketNo')} :</b> <span>{ticketDetail[0]['ticket']['ticketCode']}</span> <br />
                        <b> {t('userId')} :</b> <span>{ticketDetail[0]['ticket']['userId']}</span><br />
                        <b> {t('ticketCreatedDate')} :</b> <span>{ticketDetail[0]['ticket']['creationDate']}</span><br />
                        <b> {t('ticketTitle')} :</b> <span>{ticketDetail[0]['ticket']['title']}</span><br />
                        <b> {t('ticketdetail')} :</b> <span>{ticketDetail[0]['ticket']['message']}</span><br />
                    </div>

                    <hr />
                    <div>
                        <h4>{t('ticketconversation')}</h4>
                        {ticketAnswers.map(dt => (
                            <li>
                                <b>{dt.isItAnswer} </b>
                                <br />
                                <b>{t('message')}</b> <br />
                                <span>
                                    {dt.message}
                                </span>
                                <br />
                                <b>{t('ticketanswerdate')}</b> <br />
                                <span>{dt.messageDate}</span>
                                <hr />
                            </li>
                        ))}



                    </div>
                </div>

            )}
            <br />
            <h4> {t('ticketanswer')} </h4>
            {answermsg}
            <form onSubmit={handleSubmit} style={{ border: "1px solid white", padding: "10px", backgroundColor: "white" }}>
                <div class="mb-3">
                    <label class="form-label" for="answer">{t("ticketanswer")}</label>
                    <textarea class="form-control" id="answer" name="answer" placeholder={t('answer')} required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                </div>
            </form>
        </div>
    );
};
export default UserTicketDetail;
