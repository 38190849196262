import React from "react";
import Datatable from "../List/Datatable"

function UserLibrary() {
    const datas = [{
        "id": 1,
        "name": "hello",
        "description": "meetings, etc...",
        "wordtr": "merhaba",
        "isSuccesfull":1

    }, {
        "id": 2,
        "name": "look",
        "description": "meetings, etc...",
        "wordtr": "bak",
        "isSuccesfull":0
    }

    ]
    const dataClass = React.useMemo(() => {
        return datas
    }, [])

    const columnsClass = React.useMemo(
        () => [
            {
                Header: 'ID',
                Footer: 'ID',
                accessor: 'id',
            },
            {
                Header: 'word',
                Footer: 'word',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'description',
                Footer: 'description',
                accessor: 'description',
            },
            {
                Header: 'Got it?',
                Footer: 'Got it?',
                accessor: 'isSuccesfull',
            },
            {
                Header: 'Delete Word',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            Delete
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: 'Delete Word',
            },
    
            {
                Header: 'Edit Word',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            Edit
                        </button>
                    </div>
                ),
                id: 'actionEdit',
                Footer: 'Edit Word',
            },
        ],
        [],
    )
    const handleDelete = (row) => {

        window.location.href = "/dashboard/teacher/editword/:" + row['id'];
    }
    const handleEdit = (row) => {

        window.location.href = "/dashboard/teacher/editword/:" + row['id'];
    }

    return (
        <div>
            <div className="row">
                <div className="col-md">
                    <Datatable data={dataClass} columns={columnsClass} tableBaslik="Kelime Listesi" />


                </div>

            </div>


        </div>
    );
}

export default UserLibrary;

