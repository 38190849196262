import { useAuth } from '../../context/AuthContext';
import $ from "jquery";
import { BrowserView, MobileView } from 'react-device-detect';
import './login.css'
import './style.css'
import { useTranslation } from 'react-i18next';
import { Outlet, Link } from "react-router-dom";

import React, { useState, useEffect } from "react";


function AuthLayout() {
    const { user } = useAuth();
    const { t, i18n } = useTranslation();
    //i18n.changeLanguage("tr")
    console.log(user['token']);
    console.log(user);

    function onClick(event) {
        if (event.currentTarget.id === "arrow" || event.currentTarget.id === "arroww") {
            var element = document.getElementById("sidebar");
            element.classList.toggle("active");
            $(".forsidebar").toggle();
        }
        else if (event.currentTarget.id === "arrowww") {
            var element = document.getElementById("sidebar");
            element.classList.toggle("active");
            //$(".forsidebar").toggle();
        }
    }

    return (
        <html>
            <head>
                <title>Heryerde İngilizce</title>
            </head>
            <body style={{ backgroundColor: "white" }}>
                <nav class="main-menu d-flex navbar navbar-expand-lg p-2 py-3 p-lg-4 py-lg-4 ">
                    <div class="container-fluid">
                        <div class="main-logo d-lg-none">
                            <a href="/">
                                <img src="images/logo.png" style={{ maxWidth: "200px" }} alt="logo" class="img-fluid" />
                            </a>
                        </div>
                        <button class="navbar-toggler shadow-none" type="button">
                            <Link to="/" class="nav-link mx-2 text-decoration-underline" style={{ textAlign: "center" }}>
                                <button style={{ fontSize: "20px", borderRadius: "50px" }} type="button" class="btn btn-info">Ana Sayfa</button>
                            </Link>
                        </button>

                        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

                            <div class="offcanvas-header mt-3">
                                <button type="button" class="btn-close shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body justify-content-between">
                                <div class="main-logo">
                                    <a href="/">
                                        <img src="../images/mainlogo.png" alt="logo" style={{ maxWidth: "220px", marginLeft: "20%" }} class="img-fluid" />
                                    </a>
                                </div>
                                <div class="d-none d-lg-flex align-items-center">
                                    <Link to="/ " class="nav-link mx-2 text-decoration-underline" style={{ textAlign: "center" }}>
                                        <button style={{ fontSize: "20px", borderRadius: "50px" }} type="button" class="btn btn-info">Ana Sayfa</button>
                                    </Link>
                                </div>
                            </div>
                        </div >

                    </div >
                </nav>
                <br />

                <section class="vh-100">
                    <Outlet />
                </section>
            </body>
        </html>

    );

}


export default AuthLayout;
