import React, { useState, useEffect } from "react";
import RequestHelper from '../../../../Helpers/RequestHelper';
import { useAuth } from '../../../../context/AuthContext';
import { useParams } from 'react-router-dom'; import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
function EditSchool() {

    const { t, i18n } = useTranslation()
    const [school, setSchool] = useState([]);
    const params = useParams();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const [file, setFile] = useState()
    const [imgPath, setimgPath] = useState()


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setimgPath(res.data['data']['path']);
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/UpdateSchool";

            await axios({
                method: 'Post',
                url: url,
                data: {
                    id: school.id,
                    isDeleted: school.isDeleted,
                    schoolLogo: school.schoolLogo,
                    //schoolLogo: imgPath,

                    studentLimit: school.studentLimit,
                    createdAt: school.createdAt,
                    expiryDate: school.expiryDate,
                    isActive: school.isActive,
                    schoolName: document.getElementById("schoolname").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Güncellendi!",
                            icon: "success",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message);
            setMsg(err);
        }
    }


    useEffect(() => {
        (async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetSchoolDetail";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setSchool(res.data['data']);

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err);
            }
        })()
    }, [])



    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t("editSchool")}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div class="mb-3">
                            <label for="schoolname" class="form-label">{t("schoolName")}</label>
                            <textarea class="form-control" name="schoolname" id="schoolname" defaultValue={school.schoolName} />
                        </div>
                        <div class="mb-3">
                            <input
                                accept="image/png"
                                type="file"
                                name="file"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">{t("update")}</button>
                        </div>
                    </form>
                </div></div></div>

    );
}

export default EditSchool;
