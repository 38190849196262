
import React, { Component } from "react";
import img1 from '../../images/1.jpg';
import img2 from '../../images/2.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'





const mystyle = {
    //sayfa mobil tablet değilse. öyle full yap?    
    width: '50%',
    margin: "auto",
    align: "center"
};

function sayHello(name) {
    alert(`hello, ${name}`);
}
class CourseDetail extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div class="accordion" id="accordionExample" style={mystyle}>
                    <div class="accordion-item">
                        <div class="card mb-2" style={{ width: "auto" }}>
                            <div class="row g-4">
                                <div class="col-md-2">
                                    <img src={img1} class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-1">
                                </div>
                                <div class="col-md-9">
                                    <div class="card-body">
                                        <h5 class="card-title">UNIT 1- 1A</h5>
                                        <p class="card-text">
                                            <h5 class="card-title">Hello!</h5>
                                        </p>
                                        <FontAwesomeIcon type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" icon="fa-solid fa-plus" style={{ fontSize: 30, color: "Mediumslateblue", boxSizing: 3, }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">



                                <div class="alert alert-light" role="alert">
                                    <div class="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-1">
                                                <FontAwesomeIcon icon="fa-play fa-solid" type="button" onClick={() => sayHello('James')} style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                            </div>
                                            <div className="col-2"> Lesson 1</div>

                                            <div className="col-4">
                                                <button type="button" style={{ color: "white", backgroundColor: "purple" }} class="btn">Grammer</button>
                                            </div>
                                            <div className="col-5">
                                                verb be (singular): i and love<br />  positive and negative statements
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-light" role="alert">
                                    <div class="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-1">
                                                <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                            </div>
                                            <div className="col-2"> Lesson 2</div>

                                            <div className="col-4">
                                                <button type="button" style={{ color: "white", backgroundColor: "coral" }} class="btn">Vocabulary</button>
                                            </div>
                                            <div className="col-5">
                                                numbers 0 to 10
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-light" role="alert">
                                    <div class="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-1">
                                                <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                            </div>
                                            <div className="col-2"> Lesson 3</div>

                                            <div className="col-4">
                                                <button type="button" style={{ color: "white", backgroundColor: "DarkOrchid" }} class="btn">Listening</button>
                                            </div>
                                            <div className="col-5">
                                                Listening: Hello!
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="accordion" id="accordionExample" style={mystyle}>
                    <div class="accordion-item">
                        <div class="card mb-2" style={{ width: "auto" }}>
                            <div class="row g-4">
                                <div class="col-md-2">
                                    <img src={img2} class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-1">
                                </div>
                                <div class="col-md-9">
                                    <div class="card-body">
                                        <h5 class="card-title">UNIT 1- 1B</h5>
                                        <p class="card-text">
                                            <h5 class="card-title">Where Are You From?</h5>
                                        </p>{/*
                                    <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p>
                                    
                                    <button type="button" className="btn btn-info" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h4>Konu detay </h4>
                                    </button>
                                     */}
                                        <FontAwesomeIcon type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" icon="fa-solid fa-plus" style={{ fontSize: 30, color: "Mediumslateblue", boxSizing: 3, }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample2">
                            <div class="accordion-body">
                                <div class="alert alert-light" role="alert">
                                    <div class="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-1">
                                                <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                            </div>
                                            <div className="col-2"> Lesson 1</div>

                                            <div className="col-4">
                                                <button type="button" style={{ color: "white", backgroundColor: "purple" }} class="btn">Grammer</button>
                                            </div>
                                            <div className="col-5">
                                                verb be (singular): i and love<br />  positive and negative statements
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-light" role="alert">
                                    <div class="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-1">
                                                <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                            </div>
                                            <div className="col-2"> Lesson 2</div>

                                            <div className="col-4">
                                                <button type="button" style={{ color: "white", backgroundColor: "coral" }} class="btn">Vocabulary</button>
                                            </div>
                                            <div className="col-5">
                                                numbers 0 to 10
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-light" role="alert">
                                    <div class="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-1">
                                                <FontAwesomeIcon icon="fa-play fa-solid" style={{ fontSize: 30, color: "Mediumslateblue" }} />
                                            </div>
                                            <div className="col-2"> Lesson 3</div>

                                            <div className="col-4">
                                                <button type="button" style={{ color: "white", backgroundColor: "DarkOrchid" }} class="btn">Listening</button>
                                            </div>
                                            <div className="col-5">
                                                Listening: Hello!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CourseDetail;