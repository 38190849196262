import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

export const AdminCreateSection = () => {
    const { user } = useAuth();
    const { t, i18n } = useTranslation()
    const [msg, setMsg] = useState([]);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    sectionName: document.getElementById("sectionName").value,
                    sectionDescription: document.getElementById("sectionDescription").value,
                    sortId: document.getElementById("sortId").value,
                    isActive: true
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        document.getElementById("sectionName").value = "";
                        document.getElementById("sectionDescription").value = "";
                        document.getElementById("sortId").value = "";
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    useEffect(() => {
        (async () => {
        })()

    }, [])
    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t('createSection')}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>

                        <div class="mb-3">
                            <label for="sectionName" class="form-label">{t('sectionName')}</label>
                            <textarea class="form-control" name="sectionName" id="sectionName" required />
                        </div>
                        <div class="mb-3">
                            <label for="sectionDescription" class="form-label">{t('sectionDescription')}</label>
                            <textarea class="form-control" name="sectionDescription" id="sectionDescription" required />
                        </div>
                        <div class="mb-3">
                            <label for="sortId" class="form-label">{t('sortid')}</label>
                            <input class="form-control" name="sortId" id="sortId" required />
                        </div>

                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary"> {t('create')} </button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default AdminCreateSection;