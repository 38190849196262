import React, { Component } from 'react'
import Swal from 'sweetalert2'
import question from '../../../images/question.jpg';
import sound from '../../../images/cr.mp3'
import { BrowserView, MobileView } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';

export default class Video extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }
    state = {
        audio: new Audio(sound),
        isPlaying: false,
    };

    playPause = () => {
        let isPlaying = this.state.isPlaying;
        if (isPlaying) {
            this.state.audio.pause();
            $('#zort').toggleClass("fas fa-play-circle");
        } else {
            this.state.audio.play();
            $('#zort').toggleClass("fas fa-pause-circle");
        }
        this.setState({ isPlaying: !isPlaying });
        this.state.audio.onended = function () {
            $('#zort').toggleClass("fas fa-play-circle");
        }
    };
    render() {
        function play() {
            console.log();
            new Audio(sound).play();
        }
        let counter = 0;
        const handleClick = event => {
            var txt = document.getElementById(event.currentTarget.id);
            if (txt.innerText != ".") {
                counter = counter + 1;
                if (counter == 1) {
                    var item = document.getElementById("firstu");
                    var content = "&nbsp;"+txt.innerText+"&nbsp;";
                    item.innerHTML = content;
                    txt.style.backgroundColor="gray";
                    item.style.marginLeft="5%";
                    item.style.marginRight="5%";
                    item.style.textAlign = "center";

                } else if (counter == 2) {
                    var item = document.getElementById("secondu");
                    item.innerText = txt.innerText;
                    txt.style.backgroundColor="gray";

                }
                else if (counter == 3) {
                    var item = document.getElementById("thirdu");
                    item.innerText = txt.innerText;
                    txt.style.backgroundColor="gray";

                } else {
                    var item = document.getElementById("fourthu");
                    item.innerText = txt.innerText;
                    txt.style.backgroundColor="gray";

                    //bitti doğru yanlış kt 
                    /*    
                    Swal.fire({
                        title: "Good job!",
                        text: "You clicked the button!",
                        icon: "success"
                    });
*/



                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    });


                    play();


                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                    }

                    );
                }
            }


        };

        return (


            <div>
                <nav style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                    <div style={{ position: "absolute", right: "3%", top: "3%" }}>
                        <FontAwesomeIcon icon="fas fa-times-circle" style={{ fontSize: "30" }} />
                    </div>
                </nav>
                <BrowserView>
                    <div className='container'>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "5%", width: "100%", maxWidth: "880px" }}>



                                <div className='row'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ backgroundColor: 'white', fontSize: "20", color: "gray" }}>
                                                    Read the text and complete.
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <span>
                                                        Read the text and complete the centences.

                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='row' style={{ marginTop: "1%" }}>
                                    <div style={{ alignItems: "center" }}>
                                        <img src={question} style={{ width: "100%" }} />
                                    </div>
                                </div>


                                <div style={{ marginBottom: "-35px", marginTop: "1%" }} class="d-flex justify-content-center" id='font' onClick={this.playPause}>
                                    <div style={{ backgroundColor: "white", borderRadius: "50%", border: "10px solid white" }}>
                                        <i id='zort' class="fas fa-play-circle" style={{ color: "blue", fontSize: 30 }}></i>

                                    </div>
                                </div>

                                <div className='row' style={{ backgroundColor: "white", maxHeight: "590", marginTop: "1%" }} >
                                    <div class="d-flex justify-content-center" style={{ padding: "3%" }}>
                                        <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                                            <span>&nbsp;</span>
                                            <span>
                                                <u id="firstu" style={{border:"1px solid black",padding:"1%",borderRadius:"0%"}}>&nbsp;&nbsp;&nbsp;&nbsp;</u>
                                            </span>
                                            <span>&nbsp;</span>
                                            <span><u id="secondu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                                            <span>&nbsp;</span>
                                            <span><u id="thirdu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                                            <span>&nbsp;</span>
                                            <span><u id="fourthu">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                                            <span>&nbsp;</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center" style={{marginTop:"-1%"}}>

                                        <button className="btn btn-primary" id="first" onClick={handleClick}>
                                            a
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="second" onClick={handleClick}>
                                            n
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="third" onClick={handleClick}>
                                            r
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="fourth" onClick={handleClick}>
                                            r
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="fifth" onClick={handleClick}>
                                            t
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="sixth" onClick={handleClick}>
                                            e
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="eightth" onClick={handleClick}>
                                            s
                                        </button>&nbsp;
                                        <button className="btn btn-primary" id="nineth" onClick={handleClick}>
                                            i
                                        </button>&nbsp;

                                    </div>


                                </div>
                            </div>
                            <div className='col-md-2'></div>
                        </div>




                    </div>
                </BrowserView>




                <footer className='justify-content-center' style={{ position: "fixed", bottom: "0", width: "100%", backgroundColor: "white", display: "flex" }} >

                    <div className='row'>


                        <nav aria-label="Page navigation example">
                            <th class="pagination ">
                                <td class="page-item disabled">
                                    <a class="page-link" >Previous</a>
                                </td>
                                <td class="page-item" ><a class="page-link" style={{ backgroundColor: "blue", color: "white" }} href="#">1</a></td>
                                <td class="page-item"><a class="page-link" href="#">2</a></td>
                                <td class="page-item"><a class="page-link" href="#">3</a></td>
                                <td class="page-item">
                                    <a class="page-link" href="#">Next</a>
                                </td>
                            </th>
                        </nav>

                    </div>


                </footer>

            </div>













        )
    }
}
