import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import { useParams } from 'react-router-dom';

function TeacherEditStudent() {
    const { user } = useAuth();
    const { t, i18n } = useTranslation()
    const [msg, setMsg] = useState([]);
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const params = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/UpdateStudent";
            await axios({
                method: 'Put',
                url: url,
                data: {
                    id: data.id,
                    isDeleted: data.isDeleted,
                    schoolId: data.schoolId,
                    username: document.getElementById("username").value,
                    password: data.password,
                    fullName: document.getElementById("fullName").value,
                    roleId: data.roleId,
                    createdAt: data.createdAt,
                    lastLogin: data.lastLogin,
                    expiryDate: data.expiryDate,
                    isTrial: data.isTrial,
                    isVerified: data.isVerified,
                    classId: document.getElementById("classId").value,
                    isInvidual: data.isInvidual,
                    isStudent: data.isStudent,
                    isManager: data.isManager,
                    sectionId: data.sectionId,
                    userNote: data.userNote,
                    userEducational: data.userEducational,
                    status: data.status,
                    refreshToken: data.refreshToken,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }



    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetClasses";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {

                            for (let i = 0; i < res.data['data'].length; i++) {

                                classes.push({
                                    "id": res.data['data'][i]['id'],
                                    "className": res.data['data'][i]['className'],
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }

            //-----------
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetStudentById";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        studentId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setData(res.data['data']);
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        })()
    }, [])

    return (
        <div>
            <br />

            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t('editStudent')}</h2>
                </div>
                <h3>{msg}</h3>

                <div className="card-body">
                    {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                    {!loading && (

                        <form onSubmit={handleSubmit}>
                            <div class="mb-3">
                                <label for="username" class="form-label">{t('userEmail')}</label>
                                <input type="email" class="form-control" id="username" defaultValue={data.username} />
                            </div>

                            <div class="mb-3">
                                <label for="fullName" class="form-label">{t('namesurname')}</label>
                                <textarea class="form-control" id="fullName" defaultValue={data.fullName} />
                            </div>

                            <div class="mb-3">
                                <label for="classId" class="form-label">{t('teacherName')}</label>
                                <select id="classId" class="form-select" required >
                                    {classes ? (
                                        classes && classes.map((dt) => {
                                            if (dt.id === data.classId) {
                                                return (
                                                    <option key={dt.id} value={dt.id} selected>{dt.className}</option>
                                                )
                                            }
                                            else {
                                                return (
                                                    <option key={dt.id} value={dt.id}>{dt.className}</option>
                                                )
                                            }
                                        })
                                    ) : (
                                        <p>{t('refleshPage')}</p>
                                    )
                                    }
                                </select>
                            </div>


                            <div className="d-flex justify-content-center">
                                <button type="submit" class="btn btn-primary">{t('update')}</button>

                            </div>
                        </form>
                    )}

                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default TeacherEditStudent;
