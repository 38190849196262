import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";

import { useForm } from "react-hook-form";
import { Form, json } from "react-router-dom";

export const Slider = () => {
    const [image, setImage] = useState('');
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [data, setData] = useState('');


    const [file, setFile] = useState()
    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                console.log(res.data['data']['path']);
                console.log("zort");
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (!file) {
            alert("Lütfen Resim Seçiniz");
        }
        const formData = new FormData();
        formData.append('FormFile', file);

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                console.log(res.data);
            });
        } catch (err) {
            setMsg(err.message);
        }


    }

    const sliderImage1 = async (e) => {
    }

    const sliderImage2 = async () => {
    }

    const sliderImage3 = async () => {
    }

    const sliderImage4 = async () => {
    }
    const sliderImage5 = async () => {
    }



    return (
        <div className="row">

            <h2>Mevcut Görseller Listesi</h2>
            <div className='row' >
                <h4>Yüklenecek olan resimler 1920*1080 boyutlarında olmalıdır.</h4>
                <hr />
                <div className='col'>
                    <h6>1. Resim</h6><br />
                    <img src="/images/slider1.png" />
                    <hr />
                    <div style={{ alignItems: "center" }}>
                        <form onSubmit={sliderImage1}>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="slider1" required />
                            <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                        </form>
                        <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                            <h1>React File Upload</h1>
                            {/* <input type="file" onChange={handleChange} /> */}
                            <input
                                type="file"
                                name="file"
                                onChange={handleChange}
                            />
                            <button type="submit">Upload</button>
                        </form>




                    </div>
                </div>
                <div className='col'>
                    <h6>2. Resim</h6><br />
                    <img src="/images/slider2.png" />
                    <hr />
                    <div style={{ alignItems: "center" }}>
                        <form onSubmit={sliderImage2}>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="slider2" required />
                            <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                        </form>
                    </div>
                </div>
                <div className='col'>
                    <h6>3. Resim</h6><br />
                    <img src="/images/slider3.png" />
                    <hr />
                    <div style={{ alignItems: "center" }}>
                        <form onSubmit={sliderImage3}>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath3" name="slider3" required />
                            <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                        </form>

                    </div>
                </div>
                <div className='col'>
                    <h6>4. Resim</h6><br />
                    <img src="/images/slider4.png" />
                    <hr />
                    <div style={{ alignItems: "center" }}>
                        <form onSubmit={sliderImage4}>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath4" name="slider4" required />
                            <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                        </form>

                    </div>
                </div>
                <div className='col'>
                    <h6>5. Resim</h6><br />
                    <img src="/images/slider5.png" />
                    <hr />
                    <div style={{ alignItems: "center" }}>
                        <form onSubmit={sliderImage5}>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath5" name="slider5" required />
                            <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                        </form>

                    </div>
                </div>

            </div>
        </div>
    )
}
export default Slider;