
import { Chart } from "react-google-charts";
import React from "react";
import { VictoryPie, VictoryLabel } from 'victory';
import { BrowserView, MobileView } from 'react-device-detect';

export const datageneral = [
    ["Language", "Speakers (in millions)"],
    ["Starter", 5.85],
    ["Average", 1.66],
    ["Advancing", 0.916],
    ["Mastering", 0.9791],
    ["OutOf", 4],
];

export const optionsgeneral = {
    legend: "none",
    pieSliceText: "label",
    title: "General Progress",
    pieStartAngle: 100,
};

export const dataVocabulary = [
    ["Language", "Speakers (in millions)"],
    ["Starter", 5.85],
    ["Average", 1.66],
    ["Advancing", 0.916],
    ["Mastering", 0.9791],
    ["OutOf", 4],
];
export const optionsVocabulary = {
    legend: "none",
    pieSliceText: "label",
    title: "Vocabulary",
    pieStartAngle: 100,
};
export const datatimespent = [
    ["Date  ", "Hour"],
    ["2023-11-03", 0.2],
    ["2023-12-03", 0.4],
    ["2023-13-03", 0.6],
    ["2023-14-03", 0.8],
];
export const optionstimespent = {
    title: "Time Spent",
    curveType: "function",
    series: [{ color: "#E7711B" }],
    intervals: { style: "area" },
    legend: "none",
};

export const data2 = [
    [
        "Element",
        "Density",
        { role: "style" },
        {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
        },
    ],
    ["Grammar", 8.94, "#b87333", null],
    ["Vocabulary", 10.49, "silver", null],
    ["Reading", 19.3, "gold", null],
    ["Listening", 21.45, "color: #e5e4e2", null],
];

export const options2 = {
    title: "Density of Precious Metals, in g/cm^3",
    bar: { groupWidth: "75%" },
    legend: { position: "none" },
};


function UserGeneral () 
{

    return (
        <div className="row">
            <div className="col">
                <MobileView>

                    <div class="row">

                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card" >

                                    <h5 class="card-header" style={{ backgroundColor: "white" }}>
                                        Skills
                                    </h5>
                                    <div className="card-body">
                                        <br />
                                        <div className="row">
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h6>Grammar
                                                </h6>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style={{ width: "75%" }}>75%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h6>Vocabulary
                                                </h6>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style={{ width: "100%" }}>100%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h6>Reading
                                                </h6>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-warning" style={{ width: "25%" }}>25%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h6>Listening
                                                </h6>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-info" style={{ width: "25%" }}>25%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8"></div>
                                            <div className="col-4">

                                                <a href="/user/skills" class="btn btn-primary">Details</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card">
                                    <h5 class="card-header" style={{ backgroundColor: "white" }}>
                                        General Progress
                                    </h5>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-8" style={{ float: "left" }}>
                                                <VictoryPie
                                                    data={[
                                                        { x: " ", y: 2 },
                                                        { x: " ", y: 5 },
                                                        { x: " ", y: 8 },
                                                        { x: " ", y: 15 },
                                                        { x: " ", y: 300 },
                                                    ]}
                                                    width={570}
                                                    innerRadius={120}
                                                    colorScale={["red", "orange", "blue", "green", "Gainsboro"]}
                                                />

                                            </div>
                                            <div className="col-md-4">
                                                <span style={{ fontSize: 30, color: "red" }}>
                                                    &#8226;

                                                    Starter
                                                </span>
                                                <span style={{ fontSize: 30, color: "orange" }}>
                                                    &#8226;

                                                    Average
                                                </span>
                                                <span style={{ fontSize: 30, color: "blue" }}>
                                                    &#8226;

                                                    Advancing
                                                </span>
                                                <span style={{ fontSize: 30, color: "green" }}>
                                                    &#8226;

                                                    Mastering
                                                </span>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8"></div>
                                            <div className="col-4">

                                                <a href="/user/progress" class="btn btn-primary">Details</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" >
                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card" >
                                    <h5 class="card-header" style={{ backgroundColor: "white" }}>
                                        Time Spent
                                    </h5>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <Chart
                                                    chartType="LineChart"
                                                    width="100%"
                                                    height="auto"
                                                    data={datatimespent}
                                                    options={optionstimespent}
                                                />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8">
                                            </div>
                                            <div className="col-4">

                                                <a href="/user/time" class="btn btn-primary">Details</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: "2%" }} className="col-sm">
                            <div className="row">
                                <div class="card">
                                    <h5 class="card-header" style={{ backgroundColor: "white" }}>
                                        Vocabulary
                                    </h5>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <VictoryPie
                                                    data={[
                                                        { x: " ", y: 69 },
                                                        { x: " ", y: 680 },
                                                    ]}
                                                    width={"525"}
                                                    innerRadius={() => {
                                                        return 100;
                                                    }}
                                                    colorScale={["red", "Gainsboro"]}
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <ul style={{ fontSize: 20 }}>
                                                    <li style={{ color: "blue" }}>
                                                        69 Until Now
                                                    </li>
                                                    <li style={{ color: "BlueViolet" }}>
                                                        0
                                                        Last Lesson
                                                    </li>
                                                    <li style={{ color: "CornflowerBlue" }}>
                                                        0
                                                        Oxford 3000
                                                    </li>
                                                </ul>

                                            </div>



                                        </div>

                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8">
                                            </div>
                                            <div className="col-4">

                                                <a href="/user/vocabulary" class="btn btn-primary">Details</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MobileView>
                <BrowserView>

                    <div class="row">
                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card" style={{ height: "400px" }}>

                                    <h4 class="card-header" style={{ backgroundColor: "white", textAlign: "center" }}>
                                        Skills
                                    </h4>
                                    <div className="card-body">

                                        <div className="row" style={{ marginTop: "5%", height: "36px" }} >
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h4 style={{ textAlign: "left" }}>
                                                    Grammar
                                                </h4>
                                            </div>
                                            <div className="col-md-9" >
                                                <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="progress-bar bg-success" style={{ width: "75%" }}>75%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: "5%", height: "36px" }}>
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h5 style={{ textAlign: "left" }}>Vocabulary
                                                </h5>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar">
                                                    <div class="progress-bar bg-success" style={{ width: "100%" }}>100%</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: "5%", height: "36px" }}>
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h4 style={{ textAlign: "left" }}>Reading
                                                </h4>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar" aria-label="Success example" >
                                                    <div class="progress-bar bg-warning" style={{ width: "25%" }}>25%</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: "5%", height: "36px" }}>
                                            <div className="col-md-3" style={{ textAlign: "center" }}>
                                                <h4 style={{ textAlign: "left" }}>Listening
                                                </h4>
                                            </div>
                                            <div className="col-md-9">
                                                <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar" aria-label="Success example">
                                                    <div class="progress-bar bg-info" style={{ width: "40%" }}>40%</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8"></div>
                                            <div className="col-4" style={{ textAlign: "right" }}>

                                                <a href="/user/skills" style={{ fontSize: "20px" }} class="btn btn-primary">Details</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card" style={{ height: "400px" }}>
                                    <h4 class="card-header" style={{ backgroundColor: "white", textAlign: "center" }}>
                                        General Progress
                                    </h4>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-8">


                                                <svg width={300} height={250}>
                                                    <VictoryPie
                                                        standalone={false}
                                                        width={300}
                                                        height={300}
                                                        innerRadius={80}
                                                        data={[ { x: " ", y: 2 },
                                                        { x: " ", y: 5 },
                                                        { x: " ", y: 8 },
                                                        { x: " ", y: 15 },
                                                        { x: " ", y: 300 },]}
                                                        colorScale={["red", "orange", "blue", "green", "Gainsboro"]}
                                                        labels={() => null}
                                                    />
                                                    <VictoryLabel
                                                        textAnchor="middle"
                                                        verticalAnchor="middle"
                                                        style={[
                                                            {
                                                                fontSize: 42,
                                                                color: "#28323B",
                                                                fontWeight: "550",
                                                                lineHeight: 30
                                                            },
                                                            { fontWeight: "550", fontSize: 16 }
                                                        ]}
                                                        x={300 * 0.5}
                                                        y={300 * 0.525}
                                                        text={["%12", "General Progress"]}
                                                    />
                                                </svg>






                                            </div>
                                            <div className="col-4">
                                                <span style={{ fontSize: 28, color: "red" ,marginTop:"25%" }}>
                                                    &#8226;

                                                    Starter
                                                </span>
                                                <span style={{ fontSize: 28, color: "orange",marginTop:"5%" }}>
                                                    &#8226;

                                                    Average
                                                </span>
                                                <span style={{ fontSize: 28, color: "blue",marginTop:"5%" }}>
                                                    &#8226;

                                                    Advancing
                                                </span>
                                                <span style={{ fontSize: 28, color: "green",marginTop:"5%" }}>
                                                    &#8226;

                                                    Mastering
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8"></div>
                                            <div className="col-4" style={{ textAlign: "right" }}>

                                                <a href="/user/progress" style={{ fontSize: "20px" }} class="btn btn-primary">Details</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" >
                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card" style={{ height: "400px" }}>
                                    <h4 class="card-header" style={{ backgroundColor: "white", textAlign: "center" }}>
                                        Time Spent

                                    </h4>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <Chart
                                                    chartType="LineChart"
                                                    width="100%"
                                                    height="250px"
                                                    data={datatimespent}
                                                    options={optionstimespent}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row" >
                                            <div className="col-8">
                                            </div>
                                            <div className="col-4" style={{ textAlign: "right" }}>
                                                <a href="/user/time" style={{ fontSize: "20px" }} class="btn btn-primary">
                                                    <span style={{ padding: "4px" }}>
                                                        Details
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div style={{ padding: "2%" }} className="col-md">
                            <div className="row">
                                <div class="card" style={{ height: "400px" }}>
                                    <h4 class="card-header" style={{ backgroundColor: "white", textAlign: "center" }}>
                                        Vocabulary
                                    </h4>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <svg width={300} height={250}>
                                                    <VictoryPie
                                                        standalone={false}
                                                        width={300}
                                                        height={300}
                                                        innerRadius={80}
                                                        data={[{ x: 1, y: 79 }, { x: 2, y: 10 }, { x: 3, y: 661 }]}
                                                        colorScale={["red", "orange", "Gainsboro"]}
                                                        labels={() => null}
                                                    />
                                                    <VictoryLabel
                                                        textAnchor="middle"
                                                        verticalAnchor="middle"
                                                        style={[
                                                            {
                                                                fontSize: 42,
                                                                color: "#28323B",
                                                                fontWeight: "550",
                                                                lineHeight: 30
                                                            },
                                                            { fontWeight: "550", fontSize: 16 }
                                                        ]}
                                                        x={300 * 0.5}
                                                        y={300 * 0.525}
                                                        text={["750", "Total Words"]}
                                                    />
                                                </svg>
                                            </div>
                                            <div className="col-md-4">
                                                <ul style={{ fontSize: 20, marginTop: "8%" }}>
                                                    <li style={{ color: "blue", marginTop: "10%" }}>
                                                        69 <br />Until Now
                                                    </li>
                                                    <li style={{ color: "BlueViolet", marginTop: "10%" }}>
                                                        10<br />
                                                        Last Lesson
                                                    </li>
                                                    <li style={{ color: "CornflowerBlue", marginTop: "10%" }}>
                                                        0<br />
                                                        Oxford 3000
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ backgroundColor: "white" }}>
                                        <div className="row">
                                            <div className="col-8">
                                            </div>
                                            <div className="col-4" style={{ textAlign: "right" }}>
                                                <a href="/user/time" style={{ fontSize: "20px" }} class="btn btn-primary">
                                                    <span style={{ padding: "4px" }}>
                                                        Details
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BrowserView>

            </div>
        </div>
    );
}
export default UserGeneral;