import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function AdminEditSchool() {

    const params = useParams();
    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);

    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setImagePath(res.data['data']['path'])
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/School/Update";

            await axios({
                method: 'Put',
                url: url,
                data: {
                    id: data.id,
                    isDeleted: document.getElementById("isDeleted").value,
                    isActive: document.getElementById("isActive").value,
                    schoolName: document.getElementById("schoolName").value,
                    schoolLogo: imagePath,
                    studentLimit: document.getElementById("studentLimit").value,
                    expiryDate: document.getElementById("expiryDate").value,
                    createdAt: data.createdAt
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        console.log(res.data);
                        Swal.fire({
                            title: "Güncellendi!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }





    useEffect(() => {
        (async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/School/GetById";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        schoolId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setData(res.data['data']);
                            setMsg(res.data['message']);
                            setImagePath(data['schoolLogo']);

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err);
            }
        })()
    }, [])


    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t("editSchool")}</h2>
                </div>
                <h4> {msg} </h4>
                <div className="card-body">
                    <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="schoolName" class="form-label">{t("schoolName")}</label>
                            <textarea class="form-control" id="schoolName" defaultValue={data.schoolName} />
                        </div>
                        <div class="mb-3">
                            <label for="isActive" class="form-label">{t('isActive')}</label>
                            {data.isActive ? (
                                <select id="isActive" class="form-select" required >
                                    <option key="1" value="true" selected>{t('active')}</option>
                                    <option key="0" value="false" >{t('passive')}</option>
                                </select>

                            ) : (
                                <select id="isActive" class="form-select" required >
                                    <option key="0" value="false" selected>{t('passive')}</option>
                                    <option key="1" value="true" >{t('active')}</option>
                                </select>
                            )}
                        </div>

                        <div style={{ alignItems: "center" }}>
                            <h6>{t('schoolLogo')}</h6>
                            <img src={"https://api.heryerdeingilizce.com/" + data.schoolLogo} alt="logo" style={{ width: "300px", height: "300px" }} />
                            <br />
                            <label for="schoollogo" class="form-label">{t("schoolImage")}</label>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" />
                        </div>
                        <br />
                        <div class="mb-3">
                            <label class="form-label" for="expiryDate">{t("expiryDate")}</label>
                            <input type="datetime-local" class="form-control" id="expiryDate" name="expiryDate" defaultValue={data.expiryDate} />
                        </div>
                        <div class="mb-3">
                            <label class="form-label" for="studentLimit">{t("studentLimit")}</label>
                            <textarea type="text" class="form-control" id="studentLimit" name="studentLimit" defaultValue={data.studentLimit} />
                        </div>
                        <div class="mb-3">
                            <label for="idDeleted" class="form-label">{t('idDeleted')}</label>
                            {data.isDeleted ? (

                                <select id="isDeleted" class="form-select" required >
                                    <option key="0" value="true" selected>{t('deleted')}</option>
                                    <option key="1" value="false" >{t('active')}</option>
                                </select>
                            ) : (


                                <select id="isDeleted" class="form-select" required >
                                    <option key="1" value="false" selected>{t('active')}</option>
                                    <option key="0" value="true" >{t('deleted')}</option>
                                </select>
                            )}
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">{t("update")}</button>
                        </div>
                    </form>
                </div></div></div>

    );
}

export default AdminEditSchool;
