import React from "react";

function TeacherInfoSearch() {
    const handleSelect = event => {

        console.log(event);
        //console.log(event.currentTarget.value); // selectbox
        //console.log(event.currentTarget.id);//option value
        let html = "";
        let stu = document.getElementById("studentselect");
        var x = require('../ExampleData/StudentListExampleData.json');
        //console.log(x);
        x && x.map((dt) => {
            html += "<option value='" + dt.id + "'>" + dt.name + "</option>"
        })
        //console.log(html);
        stu.innerHTML = html;

    };
    const handleSelect2 = event => {
        let lvl = document.getElementById("levelselect");
        lvl.innerHTML = "<option value='1'>Beginner</option><option value='2'>elementry</option>"

    };

    function handleSubmit(event) {
        let cllval = document.getElementById("classselect").value;
        let stuval = document.getElementById("studentselect").value;
        let lvlval = document.getElementById("levelselect").value;
        let type = document.getElementById("typeselect").value;
        let url = "/dashboard/teacher/" + type + "/class/" + cllval + "/student/" + stuval + "/level/" + lvlval;
        window.location.href = url;
        alert("kullanıcıya gidiyor");

        return true;
    }

    return (
        <div>
            <br />
            <h1>Search Student Time Spent Detail</h1>
            <hr />
            <form className="needs-validation" onSubmit={handleSubmit}>
                <h4>Select Class</h4>
                {/* <select id="classselect" class="form-select" aria-label="Default select example" onChange={handleSelect} required>
                    <option value="0" id="cls0">Select to class</option>

                    {data && data.map((dt) => {
                        return (
                            <option key={dt.id} value={dt.id} >{dt.name} </option>
                        )
                    })}
                </select>
                <hr /> */}

                <h4>Select Student</h4>
                <select id="studentselect" class="form-select" aria-label="Default select example" onChange={handleSelect2} required>
                    <option value="" id="stu0">Select to Student</option>
                    <option value="" >..</option>


                </select>
                <hr />

                <h4>Select Level</h4>
                <select id="levelselect" class="form-select" aria-label="Default select example" required>
                    <option value="" id="lvl0">Select to level</option>

                    <option value="">..</option>

                </select>
                <hr />
                <h4>Select Detail Type</h4>
                <select id="typeselect" class="form-select" aria-label="Default select example" required>
                    <option value="" id="type0">Select to Type</option>
                    <option value="studentskills" id="studentskills">Skills</option>
                    <option value="studenttime" id="studenttime">Time Spent</option>
                    <option value="studentvocabulary" id="studentvocabulary">Vocabulary</option>
                    <option value="studentprogress" id="studentprogress">Progress</option>

                </select>
                <hr />

                <div className="d-flex justify-content-center" >
                    <button type="submit" className="btn btn-primary">
                        Get Details
                    </button>
                </div>
            </form>

        </div>
    );
}

export default TeacherInfoSearch;
