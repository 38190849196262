import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";

export const SearchTicket = () => {
    const { setUser } = useAuth()
    const { t, i18n } = useTranslation();
    const [msg, setMsg] = useState([]);
    const { user } = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = React.useMemo(
        () => [
            {
                Header: t("ticketNo"),
                Footer: t("ticketNo"),
                accessor: 'ticketCode',
            },
            {
                Header: t("userEmail"),
                Footer: t("userEmail"),
                accessor: 'clientUsername',
            },
            {
                Header: t("userName"),
                Footer: t("userName"),
                accessor: 'clientName',
            },
            {
                Header: t("tickettitle"),
                Footer: t("tickettitle"),
                accessor: 'title',
            },
            {
                Header: t("ticketCreatedDate"),
                Footer: t("ticketCreatedDate"),
                accessor: 'creationDate',
            },
            {
                Header: t("showdetails"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handlegetdetails(originalRow)}

                        >
                            {t("showdetails")}
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: t("showdetails"),
            },
        ],
        [],
    )
    const handlegetdetails = (row) => {
        window.location.href = "ticketdetail/" + row['ticketCode'];
    }



    const handleSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/Ticket/Filter";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    start: document.getElementById("startdate").value,
                    end: document.getElementById("finishdate").value,
                    status: [
                        document.getElementById("status").value
                    ]
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            data.push({
                                "ticketCode": res.data['data'][i]['ticketCode'],
                                "clientUsername": res.data['data'][i]['clientUsername'],
                                "clientName": res.data['data'][i]['clientName'],
                                "title": res.data['data'][i]['title'],
                                "creationDate": res.data['data'][i]['creationDate']
                            })
                        }
                        if (res.data['data'].length === 0) {
                            setMsg("Sonuç bulunamadı");

                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });

        } catch (err) {
            setMsg(err);
        }
        setLoading(false);

    }








    useEffect(() => {
        const fetchData = async () => {

        }
        fetchData();

    }, []);




    return (
        <div>
            <br />
            <form onSubmit={handleSubmit} style={{ border: "1px solid white", padding: "10px", backgroundColor: "white" }}>
                <div class="mb-3">
                    <label class="form-label" for="startdate">{t("startdate")}</label>
                    <input type="datetime-local" class="form-control" id="startdate" name="startdate" required />
                </div>
                <div class="mb-3">
                    <label class="form-label" for="finishdate">{t("finishdate")}</label>
                    <input type="datetime-local" class="form-control" id="finishdate" name="finishdate" required />
                </div>

                <div class="mb-3" id="statusselection" >
                    <label for="status" class="form-label">{t('selectTicketType')}</label>
                    <select id="status" class="form-select" aria-label="Default select example" required>
                        <option key="0" >{t('selectTicketType')}</option>
                        <option key="1" value="open">{t('openTickets')}</option>
                        <option key="2" value="answered">{t('answeredTickets')}</option>
                        <option key="3" value="waiting">{t('waitingticket')}</option>
                    </select>
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('getList')}</button>
                </div>
            </form>
            <br />
            <h3>
                {msg}

            </h3>
            {!loading && (
                <div>
                    <Datatable data={data} columns={columns} tableBaslik={t('ticketlist')} />
                </div>
            )}

        </div>
    );
};
export default SearchTicket;
