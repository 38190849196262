import YouTube from "react-youtube";
import React, { Component } from "react";

class QuestionTypeOne extends Component {
    componentDidMount() { }
    render() {
        const options = {
            height: '720',
            width: '1080',
            playerVars: {
                autoplay: 1,
                controls: 1,
            },
        };

        return (

            <div>
                <br/>
                <div class="alert alert-light" role="alert" style={{textAlign:"center"}}>
                    A simple light alert—check it out!
                </div>
                <div class="container">
        </div>
                <div class="d-flex justify-content-center">

                <iframe src="https://player.vimeo.com/video/346802568?h=fe9c8a401c&color=e89152" width="100%" height="580" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

{/* 
                <iframe 
                        width="80%"
                        height="750"
                        src="https://www.youtube.com/embed/htnI8RprBT4"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; 
                    autoplay; 
                    fullscreen;

                    clipboard-write; 
                    encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share"
                allowfullscreen>
                    </iframe>
          */}
                </div>
            </div>



        );
    }
    _onReady(event) {
        event.target.pauseVideo();
    }
}

export default QuestionTypeOne;