import React, { useState, useEffect } from "react";


export const AdminCreateQuestionDetail2 = () => {
    const [usertypecheck, setusertypecheck] = useState(0);

    function handleSubmit(event) {

        console.log(event);
        window.location.href = "/admin/question/createquestiondetail";
        //alert(document.getElementById("exampleInputEmail2").value);
    }
    function handleChange(event) {
        //alert(event.target.value);
        if (event.target.value === "questiontitlecheck")
        {
            //alert("sa");
            let x = document.getElementById("questiontitle").value;
            //alert(x);
            document.getElementById("questiontitleq").value = x;
            document.getElementById("questiontitleq").innerHTML = x;
            document.getElementById("questiontitleq").innerText = x;
        }
    }
    useEffect(() => {
        if (usertypecheck) {
           // document.getElementById("multipleuser").style.visibility = "visible";
        }
        else {
          //      document.getElementById("multipleuser").style.visibility = "hidden";
        }

    });
    return (
        <div>
            <h2>sayfanın alt kısmından oluşan soru tipini teyit ederek oluşturunuz lütfen</h2>
            <hr />
            <div class="mb-3">
                <label for="questiontitle" class="form-label">Soru Başlığı giriniz</label>
                <textarea class="form-control" id="questiontitle" />
            </div>
            <input type="checkbox" id="questiontitlecheck" name="multipleuser" value="questiontitlecheck" onChange={handleChange} />
            <label for="questiontitlecheck"> Başlığı Dahil et</label><br></br>

            <h2>Soru İçeriği</h2>
            <input type="checkbox" id="questiontitlecheck" name="multipleuser" value="questiontitlecheck" onChange={handleChange} />
            <label for="questiontitlecheck"> Video</label>
            <input type="checkbox" id="questiontitlecheck" name="multipleuser" value="questiontitlecheck" onChange={handleChange} />
            <label for="questiontitlecheck"> Ses</label>
            <input type="checkbox" id="questiontitlecheck" name="multipleuser" value="questiontitlecheck" onChange={handleChange} />
            <label for="questiontitlecheck"> Resim</label>
            <br/>
            çoklu resim? <br/>
            sayfayı böl?
            <h2>soruyu yazınız</h2>
            <div class="mb-3">
                <label for="questiontitle" class="form-label">Soru Başlığı giriniz</label>
                <textarea class="form-control" id="questiontitle" />
            </div>
            <hr />
            <div id="soru" style={{ border: "1px solid" }}>
                
                <div  style={{textAlign:"center", marginTop:"10px"}}>
                    <h2 id="questiontitleq"></h2>
                </div>
            </div>
        </div>
    )
}
export default AdminCreateQuestionDetail2;