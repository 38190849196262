export default function Page404() {
    return (
        <section class="py-5">
            <div class="d-flex justify-content-center 
                    align-items-center flex-column 
                    text-center w-100">
                <div class="bg_img w-50">
                </div>
                <div>
                    <p class="display-4">Looks Like You're Lost</p>
                    <p>The page you are looking for not available...</p>
                    <a href="/"
                        class="text-white text-decoration-none px-4 py-3 
                        bg-success d-inline-block mt-2 rounded">
                        Go to Home
                    </a>
                </div>
            </div>
        </section>
    )
}   