import React, { useState, useEffect } from "react";
import Datatable from "../../../Components/Datatable"

import { UseTranslationOptions, useTranslation } from "react-i18next";


export const AdminQuestionList = () => {
    const [levelSelectdiv, setlevelSelectdiv] = useState(0);
    const [subjectSelectdiv, setsubjectSelectdiv] = useState(0);
    const [lessonSelectdiv, setlessonSelectdiv] = useState(0);
    const [getList, setgetList] = useState(0);
    const [selectedCourse, setSelectedCourse] = useState(0);
    const [selectedLevel, setSelectedLevel] = useState(0);
    const [selectedSubject, setSelectedSubject] = useState(0);
    const [selectedLesson, setSelectedLesson] = useState(0);
    const [courseList, setcourseList] = useState([]);
    const [subjectlist, setsubjectlist] = useState([]);
    const [sectionlist, setsectionlist] = useState([]);
    const { t, i18n } = useTranslation()

    const datalar = [
        {
            "id": 1,
            "name": "what is your name",
            "description": "teaching questions",
            "courseId": 1,
            "levelId": 1,
            "subjectId": 1,
            "lessonId": 1,
            "arrangement": 1
        }
        ,
        {
            "id": 1,
            "name": "what is your age",
            "description": "teaching questions",
            "courseId": 1,
            "levelId": 1,
            "subjectId": 1,
            "lessonId": 1,
            "arrangement": 1
        }
    ];

    const data = React.useMemo(() => {
        return datalar
    }, [])
    const columns = React.useMemo(
        () => [
            {
                Header: t("question"),
                Footer: 'Question',
                accessor: 'name',
            },
            {
                Header: t("questionDescription"),
                Footer: 'Question Description',
                accessor: 'description',
            },
            {
                Header: t("questionArrangement"),
                Footer: 'Question arrangement',
                accessor: 'arrangement',
            },
            {
                Header: t("course"),
                Footer: 'Course',
                accessor: 'courseId',
            },
            {
                Header: t("level"),
                Footer: 'Level',
                accessor: 'levelId',
            },
            {
                Header: t("subject"),
                Footer: 'subject',
                accessor: 'subjectId',
            },
            {
                Header: t("lesson"),
                Footer: 'lesson',
                accessor: 'lessonId',
            },
            {
                Header: t("deleteQuestion"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t("delete")}
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: 'Delete Question',
            },

            {
                Header: t("editQuestion"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>
                    </div>
                ),
                id: 'actionEdit',
                Footer: 'Edit Question',
            },

        ],
        [],
    )


    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/editquestion/" + row['id'];
    }

    const handleDelete = (row) => {

        alert('DELETE: ' + JSON.stringify(row))
        console.log(row['id'])
    }
    function handleSelect(event) {

        if (event.target.id === "courseselect") {
            setSelectedCourse(document.getElementById("courseselect").value);
            setlevelSelectdiv(true);
        }
        else if (event.target.id === "levelSelect") {
            setSelectedLevel(document.getElementById("levelSelect").value);
            setsubjectSelectdiv(true);
        }
        else if (event.target.id === "subjectSelect") {
            setSelectedSubject(document.getElementById("subjectSelect").value);
            setlessonSelectdiv(true);
        }
        else if (event.target.id === "lessonSelect") {
            setSelectedLesson(document.getElementById("lessonSelect").value);
        }
    }
    function handleSubmit(event) {
        event.preventDefault();
        alert("Kurs: " + selectedCourse + " Level: " + selectedLevel + " Konu: " + selectedSubject + " ders: " + selectedLesson + " bu bilgilere göre soru listesi geliyor");
        setgetList(true);
    }
    return (
        <div className="container">
            <div style={{ textAlign: "center" }}>
                <h1>Question List</h1>
            </div>
            <form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="courseselect" class="form-label">Select Course</label>
                    <select id="courseselect" class="form-select" aria-label="Default select example" onChange={handleSelect}>
                        <option key="" >Select Course</option>

                        {courseList ? (
                            courseList && courseList.map((dt) => {
                                return (
                                    <option key={dt.id} value={dt.id}>{dt.name}</option>
                                );
                            })
                        ) : (
                            <p>{t("reloadWarning")}</p>
                        )
                        }
                    </select>
                </div>

                <div class="mb-3" id="levelSelectdiv" style={{ visibility: levelSelectdiv ? "visible" : "hidden" }}>
                    <label for="levelSelect" class="form-label">{t("selectLevel")}</label>
                    <select id="levelSelect" class="form-select" aria-label="Default select example" onChange={handleSelect}>
                        <option key="" >{t("selectLevel")}</option>

                        {sectionlist ? (
                            sectionlist && sectionlist.map((dt) => {
                                return (
                                    <option key={dt.id} value={dt.id}>{dt.name}</option>
                                );
                            })
                        ) : (
                            <p>{t("reloadWarning")}</p>
                        )
                        }
                    </select>
                </div>

                <div class="mb-3" id="subjectSelectdiv" style={{ visibility: subjectSelectdiv ? "visible" : "hidden" }}>
                    <label for="subjectSelect" class="form-label">{t("selectSubject")}</label>
                    <select id="subjectSelect" class="form-select" aria-label="Default select example" onChange={handleSelect}>
                        <option key="" >{t("selectSubject")}</option>

                        {subjectlist ? (
                            subjectlist && subjectlist.map((dt) => {
                                return (
                                    <option key={dt.id} value={dt.id}>{dt.name}</option>
                                );
                            })
                        ) : (
                            <p>{t("reloadWarning")}</p>
                        )
                        }
                    </select>
                </div>


                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("getQuestionList")}</button>
                </div>

            </form>
            <div className="container-fluid" style={{ visibility: getList ? "visible" : "hidden" }}>
                <Datatable data={data} columns={columns} tableBaslik={t("questionList")} />
            </div>
        </div>
    );
};
export default AdminQuestionList;
