import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";

export const ModeratorList = () => {
    const [data, setData] = useState([]);

    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);

    const { t, i18n } = useTranslation()

    const columns = React.useMemo(
        () => [
            {
                Header: t("namesurname"),
                Footer: t("namesurname"),
                accessor: 'name',
            },
            {
                Header: t("email"),
                Footer: t("email"),
                accessor: 'email',
            },
            {
                Header: t("schoolId"),
                Footer: t("schoolId"),
                accessor: 'SchoolId',
            },
            {
                Header: t("deleteUser"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t("delete")}
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: 'Delete User',
            },

            {
                Header: t("editUser"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>
                    </div>
                ),
                id: 'actionEdit',
                Footer: 'Edit User',
            },

        ],
        [],
    )
    const handleEdit = (row) => {
        window.location.href = "editcorporateuser/" + row['id'];

    }

    const handleDelete = (row) => {

        alert('DELETE: ' + JSON.stringify(row))
        console.log(row['id'])
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/User/GetUsers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            console.log();
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['user']['roleName'] === "moderator") {
                                    data.push({
                                        "id": res.data['data'][i]['user']['id'],
                                        "name": res.data['data'][i]['user']['fullName'],
                                        "email": res.data['data'][i]['user']['username'],
                                    })
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);




    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h1>{t('moderatorlist')}</h1>
                {msg}
            </div>

            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}

            {!loading && (
                <div>
                    <Datatable data={data} columns={columns} tableBaslik={t('moderatorlist')} />
                </div>
            )}

        </div>
    );
};
export default ModeratorList;
