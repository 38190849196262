import React from "react";
import { useAuth } from '../../context/AuthContext';
import { Outlet } from "react-router-dom";

function ProfileLayout() {
    const { user } = useAuth();
    function Navbar() {
        return (
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid" style={{ backgroundColor: "white" }}>
                    <a className="navbar-brand" href="/dashboard">
                    <img src="images/mainlogo.png" alt="logo" style={{ maxWidth: "220px", padding:"5%"}} class="img-fluid" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >

                        <ul className="navbar-nav me-auto ">                        
                        </ul>
                        <form className="d-flex">
                            <a href="/profile">
                                <i
                                    class="fa fa-user-circle"
                                    
                                    style={{ fontSize: 40 }}
                                    aria-hidden="true"
                                >
                                </i>

                                &nbsp;
                                <span>

                                    <strong>{user.name}</strong>
                                    <br />
                                    {user.role}
                                </span>
                            </a>

                        </form>
                    </div>
                </div>
            </nav>
        );
    }
    if (!user) {
        window.location.href = "/login";
    }
    else {
        return (
            <div>
                <Navbar />
                <div class="wrapper">
                    <div id="content">
                        <Outlet />
                    </div>
                </div>
            </div>
        );
    }

}
export default ProfileLayout;