import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";
import { useParams } from 'react-router-dom';


export const AdminEditUser = () => {
    const { t, i18n } = useTranslation()
    const [data, setData] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams();
    const [loading, setLoading] = useState(true);

    function handleSubmit(event) {
        alert("Succesfully Updated!");
    }

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/User/GetUsers";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['user']['id'] == params["id"]) {
                                    setData(res.data['data'][i])
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err);
            }
            setLoading(false);

        })()
    }, [])

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("editUser")}</h1>
            </div>

            <h4>{msg}</h4>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}

            {!loading && (
                <form onSubmit={handleSubmit} >
                    <div class="mb-3">
                        <label for="username" class="form-label">{t("email")}</label>
                        <textarea class="form-control" id="username" defaultValue={data.user.username} />
                    </div>

                    <div class="mb-3">
                        <label for="fullName" class="form-label"> {t('namesurname')} </label>
                        <textarea class="form-control" id="fullName" defaultValue={data.user.fullName} />
                    </div>

                    <div class="mb-3">
                        <label class="form-label">{t('createdAt')}: {data.user.createdAt}</label>
                    </div>
                    <div class="mb-3">
                        <label class="form-label"> {t('lastLogin')} : {data.user.lastLogin}</label>
                    </div>

                    <div class="mb-3">
                        <label class="form-label"> {t('roleName')} : {data.user.roleName}</label>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="expiryDate">{t("expiryDate")}</label>
                        <input type="datetime-local" class="form-control" id="expiryDate" name="expiryDate" defaultValue={data.user.expiryDate} />
                    </div>


                    <div class="mb-3">
                        <label for="isTrial" class="form-label">{t('isActive')}</label>
                        {data.user.isTrial ? (

                            <select id="isTrial" class="form-select" required >
                                <option key="0" value="true" selected>{t('passive')}</option>
                                <option key="1" value="false" >{t('active')}</option>
                            </select>
                        ) : (
                            <select id="isTrial" class="form-select" required >
                                <option key="1" value="false" selected>{t('active')}</option>
                                <option key="0" value="true" >{t('passive')}</option>
                            </select>
                        )}
                    </div>

                    <div class="mb-3">
                        <label for="schoolId" class="form-label"> {t('schoolId')} </label>
                        <textarea class="form-control" id="schoolId" defaultValue={data.user.schoolId} />
                    </div>
                    <div class="mb-3">
                        <label for="sectionName" class="form-label"> {t('sectionName')} </label>
                        <textarea class="form-control" id="sectionName" defaultValue={data.detail.sectionName} />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                    </div>

                </form>
            )}

        </div>
    );
};
export default AdminEditUser;
