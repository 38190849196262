import React from "react";
import $ from 'jquery';
import { VictoryPie, VictoryLabel } from 'victory';
import data from '../ExampleData/skilldata.json';

function UserSkill () {

    const handleClick = event => {
        console.log(event.currentTarget.id);
        $('#GrammerButton').css("backgroundColor", "GhostWhite");
        $('#VocabularyButton').css("backgroundColor", "GhostWhite");
        $('#ListeningButton').css("backgroundColor", "GhostWhite");
        $('#ReadingButton').css("backgroundColor", "GhostWhite");
        let buttonBackground = "#" + event.currentTarget.id + "Button";
        $(buttonBackground).css("backgroundColor", "white");
        let detaylar = event.currentTarget.id + "detay";
        
            data && data.map((dt) => {
                let detay = document.getElementById(dt.typeInfoId);
                console.log(document.getElementById(dt.typeInfoId));
                detay.style.display = "none";
            })
        
        let detay = document.getElementById(detaylar);
        detay.style.visibility = "visible";
        detay.style.display = ""
    };    
    return (
        <div style={{ marginLeft: "3%", marginRight: "3%" }}>
            <div class="alert alert-light" role="alert">
                <div className="row">
                    <div className="col-sm">
                        <svg viewBox="0 0 400 300">
                            <VictoryPie
                                standalone={false}
                                width={400} height={400}
                                data={[
                                    { x: " ", y: 46 }, { x: " ", y: 11 }
                                ]}
                                startAngle={90}
                                endAngle={-90}
                                colorScale={["Blue", "Aqua"]}
                                innerRadius={111} labelRadius={100}
                                style={{ labels: { fontSize: 20, fill: "white" } }}
                            />
                            <VictoryLabel
                                textAnchor="middle"
                                style={{ fontSize: 25 }}
                                x={200} y={200}
                                text="11/57 
                                Grammar"
                            />
                        </svg>
                    </div>
                    <div className="col-sm">
                        <svg viewBox="0 0 400 300">
                            <VictoryPie
                                standalone={false}
                                width={400} height={400}
                                data={[
                                    { x: " ", y: 33 }, { x: " ", y: 22 }
                                ]}
                                startAngle={90}
                                endAngle={-90}
                                colorScale={["Blue", "Aqua"]}
                                innerRadius={111} labelRadius={100}
                                style={{ labels: { fontSize: 20, fill: "white" } }}
                            />
                            <VictoryLabel
                                textAnchor="middle"
                                style={{ fontSize: 25 }}
                                x={200} y={200}
                                text="13/47
                                Vocabulary"
                            />
                        </svg>

                    </div>
                    <div className="col-sm">
                        <svg viewBox="0 0 400 300">
                            <VictoryPie
                                standalone={false}
                                width={400} height={400}
                                data={[
                                    { x: " ", y: 46 }, { x: " ", y: 21 }
                                ]}
                                startAngle={90}
                                endAngle={-90}
                                colorScale={["Blue", "Aqua"]}
                                innerRadius={111} labelRadius={100}
                                style={{ labels: { fontSize: 20, fill: "white" } }}
                            />
                            <VictoryLabel
                                textAnchor="middle"
                                style={{ fontSize: 25 }}
                                x={200} y={200}
                                text="3/59
                                Reading"
                            />
                        </svg>
                    </div>
                    <div className="col-sm">
                        <svg viewBox="0 0 400 300">
                            <VictoryPie
                                standalone={false}
                                width={400} height={400}
                                data={[
                                    { x: " ", y: 24 }, { x: " ", y: 55 }
                                ]}
                                startAngle={90}
                                endAngle={-90}
                                colorScale={["Blue", "Aqua"]}
                                innerRadius={111} labelRadius={100}
                                style={{ labels: { fontSize: 20, fill: "white" } }}
                            />
                            <VictoryLabel
                                textAnchor="middle"
                                style={{ fontSize: 25 }}
                                x={200} y={200}
                                text="9/82
                                Listening"
                            />
                        </svg>

                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: "GhostWhite", margin: "center", marginLeft: "0%", marginRight: "0%" }}>
                <div className="row"><br /></div>
                <div className="col-sm">
                    <div
                        class="alert alert-light"
                        role="alert" id="GrammerButton"
                        style={{ alignItems: "center", textAlign: "center", backgroundColor: "white" }}
                    >
                        <button type="button" id="Grammer" class="btn btndark" onClick={handleClick}>
                            Grammar
                        </button>
                    </div>
                </div>

                <div className="col-sm">
                    <div
                        class="alert alert-light"
                        role="alert" id="VocabularyButton"
                        style={{ alignItems: "center", textAlign: "center", backgroundColor: "GhostWhite" }}
                    >
                        <button type="button" id="Vocabulary" class="btn btndark" onClick={handleClick}>
                            Vocabulary
                        </button>
                    </div>
                </div>

                <div className="col-sm">
                    <div
                        class="alert alert-light"
                        role="alert" id="ReadingButton"
                        style={{ alignItems: "center", textAlign: "center", backgroundColor: "GhostWhite" }}
                    >
                        <button type="button" id="Reading" class="btn btndark" onClick={handleClick}>
                            Reading
                        </button>
                    </div>
                </div>

                <div className="col-sm">
                    <div id="ListeningButton" class="alert alert-light" role="alert" style={{ alignItems: "center", textAlign: "center", backgroundColor: "GhostWhite" }} >
                        <button type="button" id="Listening" onClick={handleClick} class="btn btndark">
                            Listening
                        </button>
                    </div>
                </div>
            </div>
            <br />

            {data && data.map((dt) => {
                if (dt.typeId === 1) {
                    return (
                        <div key={dt.typeId} style={{ visibility: "visible" }}>
                            <div class="alert alert-light" role="alert" id={dt.typeInfoId} style={{ backgroundColor: "white" }}>
                                {dt.data.map((details) => {
                                    return (
                                        <div key={details.id} role="alert">
                                            <div className="row" style={{ marginLeft: "0%" }}>
                                                <div className="col-md-9">
                                                    <h5>{details.title}</h5>
                                                </div>
                                                <div className="col-md-3" style={{ textAlign: "right" }}>
                                                    <h5>{details.ratio}</h5>
                                                </div>
                                            </div>
                                            <div className="row progress" style={{ marginLeft: "1%", marginTop: "1%", marginRight: "1%", color: "white", height: "2%" }}>
                                                <div class="progress-bar" role="progressbar" className={details.color} style={{ width: details.realratio }}>
                                                    <h5>
                                                        {details.realratio}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: "center", marginTop: "1%" }}>
                                                <i class="fa fa-arrow-down" style={{ fontSize: "20" }} aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + details.titleaccordion} aria-expanded="true" aria-controls={details.titleaccordion}></i>
                                            </div>
                                            <div id={details.titleaccordion} class="accordion-collapse collapse">
                                                <div class="accordion-body" style={{ marginTop: "3%" }}>
                                                    {details.details.map((detaylar) => {
                                                        return (
                                                            <div className="row" key={detaylar.id} style={{ marginTop: "1%" }}>
                                                                <div className="col-sm" style={{ textAlign: "right" }}>
                                                                    <h6>{detaylar.name}</h6>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div class="progress" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                        <div class="progress-bar" className={detaylar.color} style={{ width: detaylar.ratio }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    )
                }
                else {
                    return (
                        <div key={dt.typeId} style={{ visibility: "hidden" }}>
                            <div class="alert alert-light" role="alert" id={dt.typeInfoId} style={{ backgroundColor: "white" }}>
                                {dt.data.map((details) => {
                                    return (
                                        <div key={details.id} role="alert">
                                            <div className="row" style={{ marginLeft: "0%" }}>
                                                <div className="col-md-9">
                                                    <h5>{details.title}</h5>
                                                </div>
                                                <div className="col-md-3">
                                                    <h5>{details.ratio}</h5>
                                                </div>
                                            </div>
                                            <div className="row progress" style={{ marginLeft: "1%", marginTop: "1%", marginRight: "1%", color: "white", height: "2%" }}>
                                                <div class="progress-bar" role="progressbar" className={details.color} style={{ width: details.realratio }}>
                                                    <h5>{details.realratio}</h5>
                                                </div>

                                            </div>
                                            <div style={{ textAlign: "center", marginTop: "1%" }}>
                                                <i class="fa fa-arrow-down" style={{ fontSize: "20" }} aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + details.titleaccordion} aria-expanded="true" aria-controls={details.titleaccordion}></i>
                                            </div>
                                            <div id={details.titleaccordion} class="accordion-collapse collapse">
                                                <div class="accordion-body" style={{ marginTop: "3%" }}>
                                                    {details.details.map((detaylar) => {
                                                        return (
                                                            <div className="row" key={detaylar.id} style={{ marginTop: "1%" }}>
                                                                <div className="col-sm" style={{ textAlign: "right" }}>
                                                                    <h6>{detaylar.name}</h6>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div class="progress" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                        <div class="progress-bar" className={detaylar.color} style={{ width: detaylar.ratio }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })}

                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }

            })
            }

        </div>
    );
}
export default UserSkill;
