


import { useParams } from "react-router-dom"
import './Lesson.css'
export default function CourseLesson() {

    const params = useParams();
    console.log(params);
    //bu paramlara göre ilgili kısmı sorgulara ve tüm bölümleri al 
    //sonra step step göster ekranda
    const myStyle = {
        position: "relative"
    };
    const myStyle2 = {
        position: "absolute",
        top: 15,
        right: 15
    };
    const myStyle3 = {
        width: "50%",
        margin: "auto",
        marginTop: "10%",
    };

    return (
        <div style={myStyle}>
            <div style={myStyle2}>

                <button style={{ borderRadius: "50%", border: "1px solid black", fontSize: "20" }}>
                    X
                </button>
            </div>
            <br />
            <div style={myStyle3}>
                <div class="alert alert-light" role="alert" style={{ textAlign: "center" }}>
                    A simple light alert—check it out!
                </div>

                <div class="d-flex justify-content-center">
                    <iframe src="https://player.vimeo.com/video/346802568?h=fe9c8a401c&color=e89152" width="100%" height="580" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            <br />
            <br />
            <div class="center">
                <div class="pagination">
                    <a href="#">1</a>
                    <a href="#" class="active">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#">5</a>
                    <a href="#">6</a>
                </div>
            </div>

        </div>

    )
}