import React, { useState, useEffect } from "react";
import Datatable from "../../../Components/Datatable"
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

export const AdminCourseList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingg, setLoadingg] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [sections, setSections] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t('courseName'),
                Footer: t('courseName'),
                accessor: 'courseName',
            },
            {
                Header: t('courseDescription'),
                Footer: t('courseDescription'),
                accessor: 'courseDesc',
            },
            {
                Header: t('sectionName'),
                Footer: t('sectionName'),
                accessor: 'sectionName',
            },
            {
                Header: t('deleteCourse'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t('delete')}
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: t('deleteCourse'),
            },
            {
                Header: t('editCourse'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editCourse'),
            },

        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/editcourse/" + row['id'];
    }



    const handleDelete = (row) => {
        window.location.reload();
    }



    useEffect(() => {
        const fetchsections = async () => {
            setLoadingg(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/GetList";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            sections.push({
                                "id": res.data['data'][i]['id'],
                                "sectionName": res.data['data'][i]['sectionName'],
                            })
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoadingg(false);
        }
        fetchsections();



        if (loadingg) {
            const fetchdata = async () => {
                setLoading(true);
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/GetCourses";

                    await axios({
                        method: 'GET',
                        url: url,
                        headers: headerss
                    }).then(res => {

                        if (res.status === 200) {

                            if (res.data['success']) {
                                for (let i = 0; i < res.data['data'].length; i++) {
                                    if (res.data['data'][i]['isDeleted'] === false) {
                                        let activity = "";
                                        let sectionName = "";
                                        for (let j = 0; j < sections.length; j++) {
                                            if (sections[j]['id'] === res.data['data'][i]['sectionId']) {
                                                sectionName = sections[j]['sectionName'];
                                            }
                                        }
                                        if (res.data['data'][i]['isActive']) {
                                            activity = t('active');
                                        }
                                        else {
                                            activity = t('passive');
                                        }

                                        data.push({
                                            "id": res.data['data'][i]['id'],
                                            "courseName": res.data['data'][i]['courseName'],
                                            "courseDesc": res.data['data'][i]['courseDesc'],
                                            "sortId": res.data['data'][i]['sortId'],
                                            "isActive": activity,
                                            "sectionName": sectionName,

                                        })
                                    }
                                }
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }
                setLoading(false);
            }
            fetchdata();
        }

    }, [])



    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("courseList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("courseList")} />
                    </div>
                )}
            </div>



        </div>
    );
};
export default AdminCourseList;
