import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import axios from "axios";
import { useTranslation } from 'react-i18next';

export const Packages = () => {
    const { t, i18n } = useTranslation();
    const [package1, setPackage1] = useState([]);
    const [package2, setPackage2] = useState([]);
    const [contact, setContact] = useState([]);


    function handleindividual(event) {
        const data = {
            "twitter": document.getElementById("twitter").value,

        }
        RequestHelper("/socialMedia", "put", data, "1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handlecorporate(event) {
        const data = {
            "youtube": document.getElementById("youtube").value,

        }
        RequestHelper("/socialMedia", "put", data, "1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function mainSettings(event) {
        let title = document.getElementById("title").value;
        let subtitle = document.getElementById("subtitle").value;

        if (title === "") {
            title = contact.title;
        }
        if (subtitle === "") {
            subtitle = contact.subtitle;
        }
        const data = {
            "id":contact.id,
            "isActive": contact.isActive,
            "title":title,
            "subtitle":subtitle

        }
        RequestHelper("/notRegister", "put", data,"1").
            then(
                response => {
                    alert(response.statusText)
                });
    }
    function handleSubmit(event) {
        const data = {
            "id": contact.id,
            "isActive": document.getElementById("activite").value,
            "title": contact.title,
            "subtitle": contact.subtitle

        }
        RequestHelper("/mainpagepackageSettings", "put", data, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }
    useEffect(() => {
        (async () => {
            const package1res = await RequestHelper("/mainpagepackages", "get", "", "1")
            const package2res = await RequestHelper("/mainpagepackages", "get", "", "2")
            const mainset = await RequestHelper("/mainpagepackageSettings", "get", "", "1")

            setContact(mainset.data);
            setPackage1(package1res.data);
            setPackage2(package2res.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('mainpagesetting')}</h2>
            <h6>{t('currentStatus')} : {contact.isActive}</h6><br/><br/>
                    <form onSubmit={handleSubmit}  style={{border:"1px solid"}}>
                        <div class="mb-3">
                            <label for="activite" class="form-label">{t("sectionActivite")}</label>
                            <select id="activite" class="form-select" aria-label="Default select example">
                                <option key="0" value="0">{t("sectionActivite")}</option>
                                <option key="1" value="pasif">{t('passive')}</option>
                                <option key="2" value="aktif">{t('active')}</option>

                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div><br />
                    </form>
            <br />
            <form onSubmit={mainSettings} style={{border:"1px solid"}}>
                <div class="mb-3">
                    <label for="title" class="form-label">Başlık</label>
                    <textarea class="form-control" id="title" placeholder={contact.title} />
                </div>
                <div class="mb-3">
                    <label for="subtitle" class="form-label">Alt Başlık</label>
                    <textarea class="form-control" id="subtitle" placeholder={contact.subtitle} />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
                <br />
            </form><br />
            <hr />
            <div className='col-sm-6'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>{t('individualPackage')}</h3>
                    <form onSubmit={handleindividual} >
                        <div class="mb-3">
                            <label for="title" class="form-label">{t('packageTitle')}</label>
                            <textarea class="form-control" id="title" placeholder={package1.title} />
                        </div>
                        <div class="mb-3">
                            <label for="price" class="form-label">{t('packagePrice')}</label>
                            <textarea class="form-control" id="price" placeholder={package1.price} />
                        </div>
                        <div class="mb-3">
                            <label for="features1" class="form-label">{t('features')} 1</label>
                            <textarea class="form-control" id="features1" placeholder={package1.features1} />
                        </div>
                        <div class="mb-3">
                            <label for="features2" class="form-label">{t('features')} 2</label>
                            <textarea class="form-control" id="features2" placeholder={package1.features2} />
                        </div>
                        <div class="mb-3">
                            <label for="features3" class="form-label">{t('features')} 3</label>
                            <textarea class="form-control" id="features3" placeholder={package1.features3} />
                        </div>
                        <div class="mb-3">
                            <label for="features4" class="form-label">{t('features')} 4</label>
                            <textarea class="form-control" id="features4" placeholder={package1.features4} />
                        </div>
                        <div class="mb-3">
                            <label for="features5" class="form-label">{t('features')} 5</label>
                            <textarea class="form-control" id="features5" placeholder={package1.features5} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
            <div className='col-sm-6'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>{t('individualPackage')}</h3>
                    <form onSubmit={handlecorporate} >
                        <div class="mb-3">
                            <label for="title" class="form-label">{t('packageTitle')}</label>
                            <textarea class="form-control" id="title" placeholder={package2.title} />
                        </div>
                        <div class="mb-3">
                            <label for="price" class="form-label">{t('packagePrice')}</label>
                            <textarea class="form-control" id="price" placeholder={package2.price} />
                        </div>
                        <div class="mb-3">
                            <label for="features1" class="form-label">{t('features')} 1</label>
                            <textarea class="form-control" id="features1" placeholder={package2.features1} />
                        </div>
                        <div class="mb-3">
                            <label for="features2" class="form-label">{t('features')} 2</label>
                            <textarea class="form-control" id="features2" placeholder={package2.features2} />
                        </div>
                        <div class="mb-3">
                            <label for="features3" class="form-label">{t('features')} 3</label>
                            <textarea class="form-control" id="features3" placeholder={package2.features3} />
                        </div>
                        <div class="mb-3">
                            <label for="features4" class="form-label">{t('features')} 4</label>
                            <textarea class="form-control" id="features4" placeholder={package2.features4} />
                        </div>
                        <div class="mb-3">
                            <label for="features5" class="form-label">{t('features')} 5</label>
                            <textarea class="form-control" id="features5" placeholder={package2.features5} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Packages;