import React, { Component } from "react";
import sozlesme from "../../kullanimsozlesmesi.json"

class KullanimSozlesmesi extends Component {
    componentDidMount() {
    }
    render() {
        return (


            <div style={{ backgroundColor: "white", marginTop: "-1%" }}>
                <div className="container" >
                    <div style={{ textAlign: "center", padding: "10px" }}>
                        <h2>Kullanıcı Sözleşmesi</h2>
                    </div>

                    <p style={{ padding: "10px" }}>
                    {sozlesme.p1}
                    </p>
                    <p style={{ padding: "10px" }}>
                    {sozlesme.p2}
                    </p>
                    <p style={{ padding: "10px" }}>
                    {sozlesme.p3}
                    </p>
                    <p style={{ padding: "10px" }}>
                    {sozlesme.p4}
                    </p>
                    <p style={{ padding: "10px" }}>
                    {sozlesme.p5}
                    </p>
                    <hr />
                </div>
            </div>
        );
    }
}

export default KullanimSozlesmesi;