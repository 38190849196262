import React, { useEffect, useState } from 'react';
import RequestHelper from '../../Helpers/RequestHelper';
import './assets/css/style.css';
import './assets/css/icomoon.css'
import './assets/css/vendor.css'
import './assets/css/fonts.css'
import './assets/css/price.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import db from "../../db.json"

export const HomePage = () => {
    const [about, setAbout] = useState([]);
    const [costumerCount, setCostumerCount] = useState([]);
    const [usage, setUsage] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [corefeatures, setCorefeatures] = useState([]);
    const [registerSection, setRegisterSection] = useState([]);
    const [package1, setPackage1] = useState([]);
    const [package2, setPackage2] = useState([]);
    const [contact, setContact] = useState([]);
    const [notRegister, setnotRegister] = useState([]);
    const [packagemainsettings, setpackagemainsettings] = useState([]);
    const [testimonials1, setTestimonials1] = useState([]);
    const [testimonials2, setTestimonials2] = useState([]);
    const [testimonials3, setTestimonials3] = useState([]);



    const { t, i18n } = useTranslation();
    function getContact(event) {
        const data = {
            "nameSurname": document.getElementById("nameSurname").value,
            "email": document.getElementById("email").value,
            "message": document.getElementById("message").value,
            "isActive": true
        }
        RequestHelper("/getContacts", "post", data).then(
            response => {
                if (response.statusText === "Created") {
                    document.getElementById("nameSurname").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("message").value = "";
                }

            });

        event.preventDefault();
    }
    const options = {
        height: '720',
        width: '1080',
        playerVars: {
            autoplay: 1,
            controls: 1,
        },
    };


    // useEffect(() => {
    //     (async () => {
    //         const about = await RequestHelper("/aboutus", "get", "", "1")
    //         setAbout(about.data);

    //         //const registerSectionResponse = await RequestHelper("/sectionDetails", "get", "", "10")
    //         //setRegisterSection(registerSectionResponse.data);

    //         //const costumerCountReponse = await RequestHelper("/customerCount", "get","","30b6")
    //         //setCostumerCount(costumerCountReponse.data);
    //     })()

    // }, []);

    // useEffect(() => {
    //     (async () => {
    //         // const data = {
    //         //     username: 'root@root.com',
    //         //     password: 'root',
    //         // };

    //         // axios.post('https://api.heryerdeingilizce.com//api/User/Authorize', data)
    //         //     .then(response => {
    //         //         console.log(response.data);
    //         //     })
    //         //     .catch(error => {
    //         //         console.error(error);
    //         //     });


    //         const about = await RequestHelper("/aboutus", "get", "", "1")
    //         const usage = await RequestHelper("/usage", "get", "", "1")
    //         const count = await RequestHelper("/customerCount", "get", "", "1")
    //         const testimonials = await RequestHelper("/testimonials", "get", "", "1")
    //         const corefeatures = await RequestHelper("/coreFeatures", "get", "", "1")
    //         const package1res = await RequestHelper("/mainpagepackages", "get", "", "1")
    //         const package2res = await RequestHelper("/mainpagepackages", "get", "", "2")
    //         const contactt = await RequestHelper("/contactUs", "get", "", "1")
    //         const mainset = await RequestHelper("/mainpagepackageSettings", "get", "", "1")

    //         const notregisterrr = await RequestHelper("/notRegister", "get", "", "1")
    //         const testimonials1 = await RequestHelper("/mainpagetestimonials", "get", "", "1")          
    //         setTestimonials1(testimonials1.data);

    //         const testimonials2 = await RequestHelper("/mainpagetestimonials", "get", "", "2")          
    //         setTestimonials2(testimonials2.data);

    //         const testimonials3 = await RequestHelper("/mainpagetestimonials", "get", "", "3")          
    //         setTestimonials3(testimonials3.data);
    //         setpackagemainsettings(mainset.data);

    //         setnotRegister(notregisterrr.data);
    //         setContact(contactt.data);
    //         setPackage1(package1res.data);
    //         setPackage2(package2res.data);
    //         setCorefeatures(corefeatures.data);
    //         setTestimonials(testimonials.data);
    //         setCostumerCount(count.data)
    //         setAbout(about.data);
    //         setUsage(usage.data);

    //     })()
    // }, [])

    return (
        <div>
            <section id="slider" style={{ marginTop: "-2%" }}>
                <div id="carouselExampleIndicators" class="carousel slide">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>

                    </div>

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="images/slider1.png" class="d-block w-100 h-75" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="images/slider2.png" class="d-block w-100 h-75" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="images/slider3.png" class="d-block w-100 h-75" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="images/slider4.png" class="d-block w-100 h-75" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="images/slider5.png" class="d-block w-100 h-75" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </section>

            <section id="features" style={{ marginTop: "4%" }}>
                <div class="feature-box container">
                    <div class="row ">
                        <div class="col-md-12 col-sm-6 mb-5 mb-md-0">
                            <div class="feature-item py-5  rounded-4">
                                <div class="feature-detail text-center">
                                    <h2 class="feature-title" style={{ color: "green" }}>
                                        {db.customerCount[0]['count']}+</h2>
                                    <h6 class="feature-info text-uppercase">{t("MembersTitle")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" class="padding-medium mt-xl-5" >
                <div class="container">
                    <div class="row align-items-center mt-xl-5">
                        <div class="offset-md-1 col-md-5">
                            <img src="images/about.png" alt="img" class="img-fluid rounded-circle" />
                        </div>
                        <div class="col-md-5 mt-5 mt-md-0">
                            <div class="mb-3">
                                <h3 class="text-secondary ">{db.aboutus[0]['title']}</h3>
                            </div>
                            <p>

                                {db.aboutus[0]['mainText']}
                            </p>
                            {db.aboutus[0]['subTexts'] && db.aboutus[0]['subTexts'].map((dt) => {
                                if (dt.id < 4) {
                                    return (
                                        <div className='row'>
                                            <div className='col-md-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
                                                    <use href="#tick-circle" class="text-secondary" />
                                                </svg>
                                            </div>
                                            <div className='col-md-11'>
                                                <p class="ps-4">{dt.text}</p>

                                            </div>


                                        </div>
                                    )
                                }
                            })
                            }
                            {/* <div style={{textAlign:"center"}}>
                            <Link to="/aboutus" class="btn btn-primary px-5 py-3 mt-4" >
                            Learn more
                            </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <BrowserView>
                <img src='photo/f1f5e27d-2c72-4319-99fc-2f05ceb5494b.jpg' />
                <section id="features" >
                    <div class="container">
                        <div class="row" >
                            <div class="col-md-12 col-sm-6 mb-5 mb-md-0">
                                <div class=" py-5  rounded-4">
                                    <div
                                        class="ud-single-pricing first-item wow fadeInUp"
                                        data-wow-delay=".1s"
                                    >
                                        <h2 class="feature-title" style={{ color: "green" }}>{db.usage[0]['title']}</h2>
                                        <br />

                                        <div>
                                            {db.usage[0]['paragraphs'] && db.usage[0]['paragraphs'].map((dt) => {
                                                return (
                                                    <p>
                                                        {dt.text}
                                                    </p>
                                                )
                                            })
                                            }

                                        </div>
                                        <br />
                                        <div class="justify-content-center">
                                            <iframe src={db.usage[0]['videoLink']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BrowserView>

            <MobileView>
                <section id="features" >
                    <div style={{ padding: "5", backgroundColor: "white" }}>
                        <div
                        >
                            <h2 class="feature-title" style={{ color: "green" }}>{db.usage[0]['title']}</h2>
                            <br />

                            <div style={{ marginBottom: "-30%" }}>
                                {db.usage[0]['paragraphs'] && db.usage[0]['paragraphs'].map((dt) => {
                                    return (
                                        <p>
                                            {dt.text}
                                        </p>
                                    )
                                })
                                }
                            </div>
                            <div style={{ marginBottom: "-30%" }}>
                                <iframe src={db.usage[0]['videoLink']} width="100%" height="580" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                            </div>

                        </div>
                    </div>
                </section>
            </MobileView>

            {db.testimonials[0]['isActive'] === "aktif" &&
                <section id="testimonials" class="ud-testimonials" >
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title mx-auto text-center">
                                    <span>{db.testimonials[0]['title']} </span>
                                    <h3>{db.testimonials[0]['subTitle']}</h3>
                                    <p>
                                        {db.testimonials[0]['text']}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="ud-single-testimonial wow fadeInUp"
                                    data-wow-delay=".1s"
                                >
                                    <div class="ud-testimonial-ratings">
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />

                                    </div>
                                    <div class="ud-testimonial-content">
                                        <p>
                                            "{db.mainpagetestimonials[0]['message']}"

                                        </p>
                                    </div>
                                    <div class="ud-testimonial-info">

                                        <div class="ud-testimonial-meta">
                                            <h4>{db.mainpagetestimonials[0]['nameSurname']}</h4>
                                            <p>{db.mainpagetestimonials[0]['info']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="ud-single-testimonial wow fadeInUp"
                                    data-wow-delay=".15s"
                                >
                                    <div class="ud-testimonial-ratings">
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />

                                    </div>
                                    <div class="ud-testimonial-content">
                                        <p>
                                            "{db.mainpagetestimonials[1]['message']}"

                                        </p>
                                    </div>
                                    <div class="ud-testimonial-info">

                                        <div class="ud-testimonial-meta">
                                            <h4>{db.mainpagetestimonials[1]['nameSurname']}</h4>
                                            <p>{db.mainpagetestimonials[1]['info']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="ud-single-testimonial wow fadeInUp"
                                    data-wow-delay=".2s"
                                >
                                    <div class="ud-testimonial-ratings">
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />

                                    </div>
                                    <div class="ud-testimonial-content">
                                        <p>
                                            "{db.mainpagetestimonials[2]['message']}"

                                        </p>
                                    </div>
                                    <div class="ud-testimonial-info">

                                        <div class="ud-testimonial-meta">
                                            <h4>{db.mainpagetestimonials[2]['nameSurname']}</h4>
                                            <p>{db.mainpagetestimonials[2]['info']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title text-center mx-auto">
                                    <span><a href="/reviews">{t('showallreview')}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {db.mainpagepackageSettings[0]['isActive'] === "aktif" &&
                <section id="pricing" class="ud-pricing" style={{ marginTop: "-4%" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title mx-auto text-center">
                                    <h2>{db.mainpagepackageSettings[0]['title']}</h2>
                                    <p>
                                        {db.mainpagepackageSettings[0]['subtitle']}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row g-0 align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-6 col-sm-10">
                                <div
                                    class="ud-single-pricing first-item wow fadeInUp"
                                    data-wow-delay=".15s"
                                >

                                    <div class="ud-pricing-header">
                                        <h3>{db.mainpagepackages[0]['title']}</h3>
                                        <h4>{db.mainpagepackages[0]['price']}</h4>
                                    </div>
                                    <div class="ud-pricing-body">
                                        <ul>
                                            <li>{db.mainpagepackages[0]['features1']}</li>
                                            <li>{db.mainpagepackages[0]['features2']}</li>
                                            <li>{db.mainpagepackages[0]['features3']}</li>
                                            <li>{db.mainpagepackages[0]['features4']}</li>
                                            <li>{db.mainpagepackages[0]['features5']}</li>
                                        </ul>
                                    </div>
                                    <div class="ud-pricing-footer">
                                        <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                            <Link to="auth/register" >
                                                {t('register')}
                                            </Link>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-4 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing active wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <span class="ud-popular-tag">POPULAR</span>
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 30.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-white-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div> */}
                            <div class="col-lg-6 col-md-6 col-sm-10">
                                <div
                                    class="ud-single-pricing last-item wow fadeInUp"
                                    data-wow-delay=".15s"
                                >
                                    <div class="ud-pricing-header">
                                        <h3>{db.mainpagepackages[1]['title']}</h3>
                                        <h4>{db.mainpagepackages[1]['price']}</h4>
                                    </div>
                                    <div class="ud-pricing-body">
                                        <ul>
                                            <li>{db.mainpagepackages[1]['features1']}</li>
                                            <li>{db.mainpagepackages[1]['features2']}</li>
                                            <li>{db.mainpagepackages[1]['features3']}</li>
                                            <li>{db.mainpagepackages[1]['features4']}</li>
                                            <li>{db.mainpagepackages[1]['features5']}</li>
                                        </ul>
                                    </div>
                                    <div class="ud-pricing-footer">
                                        <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                            <Link to="auth/register" >
                                                {t('register')}
                                            </Link>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <hr />

            <section id="points" class="padding-medium pt-0">
                <div class="container">
                    <div class="text-center">

                        <p class="text-secondary">{db.coreFeatures[0]['title']}</p>
                        <h2 class="display-6 fw-semibold">{db.coreFeatures[0]['subtitle']}</h2>
                    </div>
                    <div class="row align-items-center mt-5">
                        <div class="col-md-6 pe-md-5">
                            {db.coreFeatures[0]['features'] && db.coreFeatures[0]['features'].map((dt) => {
                                if (dt.id % 2 == 1) {
                                    if (dt.isActive) {
                                        return (
                                            <div className='row' style={{ padding: "3px" }}>
                                                <div className='col-md-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
                                                        <use href="#tick-circle" class="text-secondary" />
                                                    </svg>
                                                </div>
                                                <div className='col-md-11' >
                                                    <h5 class="ps-4">{dt.text}</h5>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })}
                        </div>
                        <div class="col-md-6 pe-md-5">
                            {db.coreFeatures[0]['features'] && db.coreFeatures[0]['features'].map((dt) => {
                                if (dt.id % 2 == 0) {
                                    if (dt.isActive) {
                                        return (
                                            <div className='row' style={{ padding: "3px" }}>
                                                <div className='col-md-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
                                                        <use href="#tick-circle" class="text-secondary" />
                                                    </svg>
                                                </div>
                                                <div className='col-md-11' >
                                                    <h5 class="ps-4">{dt.text}</h5>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })}
                        </div>


                    </div>
                </div>
            </section>
            <hr />

            {db.contactUs[0]['isActive'] === "aktif" &&
                <section id="points" class="padding-medium pt-0">
                    <div class="container">
                        <div
                            class="ud-single-pricing last-item wow fadeInUp"
                            data-wow-delay=".15s"
                        >
                            <div class="text-center">
                                <p class="text-secondary">Sorunuz mu Var?</p>
                                <h2 class="display-6 fw-semibold">İletişime Geç!</h2>
                            </div>
                            <div class="row align-items-center mt-5" style={{ alignItems: "center" }}>
                                <div class="pe-md-5">

                                    <form onSubmit={getContact} style={{ padding: "20px" }}>
                                        <div class="mb-3">
                                            <label for="nameSurname" class="form-label">Ad Soyad:</label>
                                            <textarea class="form-control" id="nameSurname" required />
                                        </div>
                                        <div class="mb-3">
                                            <label for="email" class="form-label">Email Adresi:</label>
                                            <input class="form-control" id="email" type='email' required />
                                        </div>
                                        <div class="mb-3">
                                            <label for="message" class="form-label">Mesajınız:</label>
                                            <textarea class="form-control" id="message" rows="5" required />
                                        </div>

                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <hr />

            {db.notRegister[0]['isActive'] === "aktif" &&
                <section id="register">
                    <div class="container padding-medium">
                        <div class="row align-items-center">
                            <div class="offset-md-1 col-md-5">
                                <div>
                                    {/* {
                                    registerSection.mainTitleValid == "true" ?
                                        <h2 class="display-6 fw-semibold">{registerSection.mainTitleText}</h2>
                                        : null
                                } */}
                                    <h2 class="display-6 fw-semibold">Henüz Üye Değil misiniz?</h2>
                                    {/* {
                                    registerSection.subTitleValid2 == "true" ?
                                        <p class="text-secondary ">{registerSection.subTitleText2}</p> :
                                        null
                                } */}
                                    <h3 class="text-secondary ">
                                        <Link to="auth/register" >
                                            Hemen aramıza katılın
                                        </Link>

                                    </h3>
                                    {/* <h2 class="display-6 fw-semibold">{registerSection.mainTitleText}</h2>
                                <p class="text-secondary ">{registerSection.subTitleText2}</p> */}
                                </div>
                            </div>
                            <div class="col-md-5">
                                {
                                    registerSection.emailValid == "true" ?
                                        <form id="form">
                                            {/* <input type="email" name="email" placeholder={registerSection.emailText}
                                            class="form-control bg-white border-0 rounded-4 shadow-none rounded-pill text-center px-4 py-3 w-100 mb-4" /> */}
                                            <div class="d-grid">
                                                <button class="btn btn-primary px-5 py-3">
                                                    <Link to="auth/register" style={{ textAlign: "center" }}>
                                                        {t("register")}
                                                    </Link>
                                                </button>

                                            </div>
                                        </form>
                                        : null
                                }
                                {/* <form id="form">
                                <input type="email" name="email" placeholder={registerSection.emailText}
                                    class="form-control bg-white border-0 rounded-4 shadow-none rounded-pill text-center px-4 py-3 w-100 mb-4" />
                                <div class="d-grid">
                                    <button class="btn btn-primary px-5 py-3"> Get Started now</button>
                                </div>
                            </form> */}
                            </div>

                        </div>
                    </div>
                </section>
            }
        </div >
    );
}

export default HomePage;

{/* <section id="pricing" class="ud-pricing" style={{ marginTop: "-4%" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ud-section-title mx-auto text-center">
                                <h2>Üyelik Paketlerimiz</h2>
                                <p>
                                    Bireysel ve kurumsal çözümlerimiz. Paket detayları hakkında bilgi almak için lütfen iletişime geçiniz.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row g-3 align-items-center justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-10">
                            <div
                                class="ud-single-pricing first-item wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>Bireysel Kullanıcı</h3>
                                    <h4>299.99 ₺ / Yıl</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>Tam Erişim</li>
                                        <li>Aynı kur kıyaslama </li>
                                        <li>Onlarca etkinlik</li>
                                        <li>Oyun oynayarak öğrenme</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Üye Ol!
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="col-lg-6 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing active wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <span class="ud-popular-tag">POPULAR</span>
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 30.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-white-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing last-item wow fadeInUp"
                                data-wow-delay=".15s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 70.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row g-0 align-items-center justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-10">
                            <div
                                class="ud-single-pricing first-item wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 19.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-10">
                            <div
                                class="ud-single-pricing active wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>OKUL & KURS</h3>
                                    <h4>999.99 ₺ / Yıl</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>Öğretmen / Sınıf Oluşturma</li>
                                        <li>Toplu öğrenci üyelikleri oluşturma</li>
                                        <li>Öğrenci raporlarını düzenleme görüntüleme</li>
                                        <li>Tüm kurslara erişim</li>

                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-white-btn">
                                        Üye Ol!
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing last-item wow fadeInUp"
                                data-wow-delay=".15s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 70.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
