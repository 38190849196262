import React from "react";
import { useTranslation } from 'react-i18next';

function TeacherCreateWord() {
    const { t, i18n } = useTranslation();

    return (
        <div>

            <br />

            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t("createword")}</h2>
                </div>
                <div className="card-body">
                    <form>
                        <div class="mb-3">
                            <label for="wordname" class="form-label">Word</label>
                            <textarea type="text" class="form-control" id="wordname" />
                        </div>
                        <div class="mb-3">
                            <label for="worddes" class="form-label">word description</label>
                            <textarea type="text" class="form-control" id="worddes" />
                        </div>
                        <div class="mb-3">
                            <label for="wordtr" class="form-label">word translate</label>
                            <textarea type="text" class="form-control" id="wordtr" />
                        </div>
                        {/* <div class="mb-3">
                            <label for="selectClass" class="form-label">Select Class</label>
                            <select id="selectClass" class="form-select">
                                <option>select class</option>
                                {data && data.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.name} -- {dt.level}</option>
                                    );
                                })}
                            </select>
                        </div> */}
                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">Create</button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default TeacherCreateWord;
