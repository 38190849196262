import React, { useState, useEffect } from "react";
import Datatable from "../Components/Datatable"
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../context/AuthContext';

function TeacherClassList() {
    const [data, setData] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()

    const columns = React.useMemo(
        () => [
            {
                Header: 'Class Name',
                Footer: 'Class Name',
                accessor: 'classname', // accessor is the "key" in the data
            },
            {
                Header: 'Course Name',
                Footer: 'Course Name',
                accessor: 'courseId',
            },
            {
                Header: 'Level Name',
                Footer: 'Level Name',
                accessor: 'levelId',
            },
            {
                Header: 'School Name',
                Footer: 'School Name',
                accessor: 'schoolId',
            },
            {
                Header: 'Delete Class',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            Delete
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: 'Delete Course',
            },
            {
                Header: 'Edit Class',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            Edit Class
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: 'Edit Course',
            },
            {
                Header: 'Show Students',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-success btn-sm"
                            onClick={() => handleShowStudents(originalRow)}
                        >
                            Show
                        </button>

                    </div>
                ),
                id: 'actionShowStudents',
                Footer: 'Show Students',
            },
        ],
        [],
    )


    const handleShowStudents = (row) => {
        window.location.href = "/dashboard/teacher/studentlist/" + row['id'];
    }

    const handleEdit = (row) => {
        window.location.href = "/dashboard/teacher/classsettings/" + row['id'];
    }
    const handleDelete = (row) => {
        // RequestHelper("/classes", "delete", "", row['id']).then(response => alert(response.statusText))
        window.location.reload();
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/Cupon/AllList";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {

                            for (let i = 0; i < res.data['data'].length; i++) {
                                let a = "";
                                if (res.data['data'][i]['isUsed']) {
                                    a = "kullanıldı";
                                }
                                else {
                                    a = "kullanılabilir";
                                }
                                data.push({

                                    "id": res.data['data'][i]['id'],
                                    "coupencode": res.data['data'][i]['cuponCode'],
                                    "coupendescription": res.data['data'][i]['cuponDescription'],
                                    "coupenexpiryDate": res.data['data'][i]['expiryDate'],
                                    "coupenpercentage": res.data['data'][i]['percentage'],
                                    "coupenusagelimit": res.data['data'][i]['usageLimit'],
                                    "coupenusage": a,

                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);

    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>

                <h1>{t("classlist")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("coupenlist")} />
                    </div>
                )}
            </div>

            <br />
        </div>
    );
}
export default TeacherClassList;
