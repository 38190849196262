import React, { Component } from "react";

class AboutPage extends Component {
    componentDidMount() {
    }
    render() {
        return (
            
            <div>
                about sayfası
            </div>
        );
    }
}

export default AboutPage;