import React, { Component } from 'react'
import Swal from 'sweetalert2'
import question from '../../../images/question2.jpg';
import $ from 'jquery';
import './test.css';
import { BrowserView, MobileView } from 'react-device-detect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class Read extends Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        console.log(window.getComputedStyle(document.body, null).getPropertyValue('background-color'));
    }
    render() {
        return (
            <div>
                <nav style={{ backgroundColor: "rgb(238, 238, 238)" }}>
                    <div style={{ position: "absolute", right: "3%", top: "3%" }}>

                        <FontAwesomeIcon icon="fas fa-times-circle" style={{ fontSize: "30" }} />
                    </div>

                </nav>

                <BrowserView>
                    <div className='container'>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "10%", width: "100%", maxWidth: "880px" }}>



                                <div className='row'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ backgroundColor: 'white', fontSize: "20", color: "gray" }}>
                                                    Read the text and complete.
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <span>
                                                        Read the text and complete the centences.

                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='row' style={{ backgroundColor: "white", maxHeight: "590", marginTop: "1%" }} >
                                    <div className='col-7' style={{ padding: "2%" }}>
                                        <div class="overflow-y-scroll" style={{ maxHeight: "560px" }}>
                                            <br />
                                            <h2 style={{ color: "blue", marginTop: "1%", fontFamily: "Anta" }}>Who knows you better -your mother or your best friend</h2>
                                            <h4 style={{ font: "bold", marginTop: "3%" }}>In our weekly experiment, single people who are looking for a partner ask their mother and their best friend to help.</h4>
                                            <span style={{ marginTop: "3%" }}>
                                                This week’s single person is Charlotte Ramirez, a 25-year-old web designer. Her father is Spanish and her mother is English. She lives in Brighton and she doesn’t have a partner at the moment. Her mother, Alice, chooses a man she thinks is perfect for her daughter and her best friend, Katie, chooses another. Then Charlotte goes on a date with each man. Which one does she prefer
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘What kind of men do I like? Well, I like interesting men who can make me laugh. Physically, I prefer men with a really nice smile who are taller than me.And I don’t usually like men with beards! I like men who are into literature and art, and classical music.’

                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘What kind of men do I like? Well, I like interesting men who can make me laugh. Physically, I prefer men with a really nice smile who are taller than me.And I don’t usually like men with beards! I like men who are into literature and art, and classical music.’

                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘What kind of men do I like? Well, I like interesting men who can make me laugh. Physically, I prefer men with a really nice smile who are taller than me.And I don’t usually like men with beards! I like men who are into literature and art, and classical music.’

                                            </span>
                                            <button style={{marginBottom:"5%", marginTop:"5%",right:"0px"}}>
                                                I'm Ready!
                                            </button>
                                        </div>

                                    </div>
                                    <div className='col-5' style={{ padding: "1%", backgroundImage: question }}>
                                        <div>
                                            <img src={question} style={{ width: "100%", height: "auto" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2'></div>
                        </div>




                    </div>
                </BrowserView>
                <MobileView>
                    <br />
                    <div className='container' style={{ marginTop: "5%" }}>

                        <div className='row'>
                            <div className='col-md-2'></div>

                            <div className='col-md-8' style={{ height: "auto", marginTop: "10%" }}>



                                <div className='row'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ backgroundColor: 'white', fontSize: "20", color: "gray" }}>
                                                    Read the text and complete.
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <span>
                                                        Read the text and complete the centences.

                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-label="Segment one" style={{ width: "15%" }}
                                                aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='row' style={{ backgroundColor: "white", maxHeight: "590", marginTop: "1%" }} >
                                    <div className='col-md-7' style={{ padding: "2%" }}>
                                        <div class="overflow-y-scroll" style={{ maxHeight: "580px" }}>
                                            <br />
                                            <h2 style={{ color: "blue", marginTop: "1%" }}>Who knows you better -your mother or your best friend</h2>
                                            <h4 style={{ font: "bold", marginTop: "3%" }}>In our weekly experiment, single people who are looking for a partner ask their mother and their best friend to help.</h4>
                                            <span style={{ marginTop: "3%" }}>
                                                This week’s single person is Charlotte Ramirez, a 25-year-old web designer. Her father is Spanish and her mother is English. She lives in Brighton and she doesn’t have a partner at the moment. Her mother, Alice, chooses a man she thinks is perfect for her daughter and her best friend, Katie, chooses another. Then Charlotte goes on a date with each man. Which one does she prefer
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘I love going to the cinema, but I often
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘I love going to the cinema, but I often
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘I love going to the cinema, but I often
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘I love going to the cinema, but I often
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘I love going to the cinema, but I often
                                            </span>
                                            <span style={{ marginTop: "3%" }}>
                                                ‘I love going to the cinema, but I often
                                            </span>
                                        </div>

                                    </div>
                                    <div className='col-md-5' style={{ padding: "1%", backgroundImage: question }}>
                                        <div>
                                            <img src={question} style={{ width: "100%", height: "auto" }} />
                                        </div>
                                    </div>

                                </div>




                            </div>
                            <div className='col-md-2'></div>
                        </div>

                    </div>
                </MobileView>

                <footer className='justify-content-center' style={{ position: "fixed", bottom: "0", width: "100%", backgroundColor: "white", display: "flex" }} >

                    <div className='row'>


                        <nav aria-label="Page navigation example">
                            <th class="pagination ">
                                <td class="page-item disabled">
                                    <a class="page-link" >Previous</a>
                                </td>
                                <td class="page-item" ><a class="page-link" style={{ backgroundColor: "blue", color: "white" }} href="#">1</a></td>
                                <td class="page-item"><a class="page-link" href="#">2</a></td>
                                <td class="page-item"><a class="page-link" href="#">3</a></td>
                                <td class="page-item">
                                    <a class="page-link" href="#">Next</a>
                                </td>
                            </th>
                        </nav>

                    </div>


                </footer>
            </div>

        )
    }
}
