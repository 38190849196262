import React, { useState, useEffect } from "react";
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';
import Datatable from "../../Components/Datatable"

function GetContact () {
    const [data, setData] = useState([]);

    const { t, i18n } = useTranslation();

    function onClick (event) {
        var exp = [];
        for (var i = 0, len = data.length; i < len; i++) {
            if(data[i]['id'] === event.currentTarget.id)
            {
                exp = data[i];
            }
        }
        if(exp['isActive'] === true)
        {
            exp['isActive'] = false;
        }
        else{
            exp['isActive'] = true;
        }
        RequestHelper("/getContacts", "put", exp,event.currentTarget.id).
        then(
            response => {
                alert(response.statusText)
            });
            window.location.reload();
    }

    const handleAddLevel = () => {
        window.location.href = "/dashboard/admin/createlevel";
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t('name'),
                Footer: t('name'),
                accessor: 'nameSurname',
            },
            {
                Header: t('email'),
                Footer: t('email'),
                accessor: 'email',
            },
            {
                Header: t('message'),
                Footer: t('message'),
                accessor: 'message',
            },
            {
                Header: t('isActive'),
                Footer: t('isActive'),
                accessor: 'isActive',
            },
            {
                Header: 'Add Level',
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-success btn-sm"
                            onClick={() => handleAddLevel(originalRow)}
                        >
                            Add Level
                        </button>

                    </div>
                ),
                id: 'actionAddLevel',
                Footer: 'Add Level',
            },
        ],
        [],
    )



    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/getContacts", "get","")
            setData(res.data);
        })()
    }, [])


    return (
        <div style={{backgroundColor:"white"}}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("getcontacts")}</h1>
            </div>
            <br/>
            <h3>Aktif Talepler</h3>

            <div style={{ overflowX: "auto", border: "1px solid black" }}>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('message')}</th>
                            <th scope="col">{t('isActive')}</th>
                            <th scope="col">{t('changeActivity')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((dt) => {
                            if(dt.isActive === true)
                                {
                            return (
                                <tr key={dt.id}>
                                    <td>{dt.nameSurname}</td>
                                    <td>{dt.email}</td>
                                    <td>{dt.message}</td>
                                    <td>{dt.isActive ? t('active'):t('passive')}</td>
                                    <td>
                                        <button className="btn btn-success" id={dt.id} onClick={onClick}>
                                            {t('change')}
                                        </button>
                                    </td>
                                    
                                </tr>
                            )
                        }
                        })}
                    </tbody>
                </table>
            </div>
            <hr/>
            <h3>Pasif Talepler</h3>
            <div style={{ overflowX: "auto", border: "1px solid black" }}>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('email')}</th>
                            <th scope="col">{t('message')}</th>
                            <th scope="col">{t('isActive')}</th>
                            <th scope="col">{t('changeActivity')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((dt) => {
                            if(dt.isActive === false)
                            {
                                return (
                                    <tr key={dt.id}>
                                        <td>{dt.nameSurname}</td>
                                        <td>{dt.email}</td>
                                        <td>{dt.message}</td>
                                        <td>{dt.isActive ? t('active'):t('passive')}</td>
                                        <td>
                                            <button className="btn btn-success" id={dt.id} onClick={onClick}>
                                                {t('change')}
                                            </button>
                                        </td>
                                        
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
            </div>
            {/* <div className="container-fluid">
                <Datatable data={data} columns={columns} tableBaslik={t("courseList")} />
            </div> */}
        </div>
    );
};
export default GetContact;
