import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';

export const Testimonials = () => {
    const { t, i18n } = useTranslation();
    const [testimonials, setTestimonials] = useState([]);
    const [testimonials1, setTestimonials1] = useState([]);
    const [testimonials2, setTestimonials2] = useState([]);
    const [testimonials3, setTestimonials3] = useState([]);

    function testimonialsHandle(e) {
        let title = document.getElementById("title").value;
        let subTitle = document.getElementById("subtitle").value;
        let text = document.getElementById("text").value;
        if (title === "") {
            title = testimonials.title;
        }
        if (subTitle === "") {
            subTitle = testimonials.subTitle;
        } 
        if (text === "") {
            text = testimonials.text;
        } 

        const data = {
            "id":1,
            "title": title,
            "subTitle": subTitle,
            "text": text,
            "isActive":testimonials.isActive
        }
        RequestHelper("/testimonials", "put", data, "1").
        then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    function test1(e) {
        let star = document.getElementById("ratio1").value;
        let message = document.getElementById("message1").value;
        let nameSurname = document.getElementById("name1").value;
        let info = document.getElementById("info1").value;

        if (star === "") {
            star = testimonials1.star;
        }
        if (message === "") {
            message = testimonials1.message;
        }
        if (nameSurname === "") {
            nameSurname = testimonials1.nameSurname;
        }
        if (info === "") {
            info = testimonials1.info;
        }
        const data = {
            "id":"1",
            "star": star,
            "message":message,
            "nameSurname":nameSurname,
            "info":info
        }
        RequestHelper("/mainpagetestimonials", "put", data, "1").
        then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    function test2(e) {
        let star = document.getElementById("ratio2").value;
        let message = document.getElementById("message2").value;
        let nameSurname = document.getElementById("name2").value;
        let info = document.getElementById("info2").value;

        if (star === "") {
            star = testimonials2.star;
        }
        if (message === "") {
            message = testimonials2.message;
        }
        if (nameSurname === "") {
            nameSurname = testimonials2.nameSurname;
        }
        if (info === "") {
            info = testimonials2.info;
        }
        const data = {
            "id":"2",
            "star": star,
            "message":message,
            "nameSurname":nameSurname,
            "info":info
        }
        RequestHelper("/mainpagetestimonials", "put", data, "2").
        then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    function test3(e) {
        let star = document.getElementById("ratio3").value;
        let message = document.getElementById("message3").value;
        let nameSurname = document.getElementById("name3").value;
        let info = document.getElementById("info3").value;

        if (star === "") {
            star = testimonials3.star;
        }
        if (message === "") {
            message = testimonials3.message;
        }
        if (nameSurname === "") {
            nameSurname = testimonials3.nameSurname;
        }
        if (info === "") {
            info = testimonials3.info;
        }
        const data = {
            "id":"3",
            "star": star,
            "message":message,
            "nameSurname":nameSurname,
            "info":info
        }
        RequestHelper("/mainpagetestimonials", "put", data, "3").
        then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    function handleSubmit(event) {
        const data = {
            "id": testimonials.id,
            "isActive": document.getElementById("activite").value,
            "title": testimonials.title,
            "subTitle": testimonials.subTitle,
            "text": testimonials.text

        }
        RequestHelper("/testimonials", "put", data, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }


    useEffect(() => {
        (async () => {
            const testimonials = await RequestHelper("/testimonials", "get", "", "1")          
            setTestimonials(testimonials.data);

            const testimonials1 = await RequestHelper("/mainpagetestimonials", "get", "", "1")          
            setTestimonials1(testimonials1.data);

            const testimonials2 = await RequestHelper("/mainpagetestimonials", "get", "", "2")          
            setTestimonials2(testimonials2.data);

            const testimonials3 = await RequestHelper("/mainpagetestimonials", "get", "", "3")          
            setTestimonials3(testimonials3.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('testimonials')}</h2>

            <form onSubmit={testimonialsHandle}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">{t('testimonialsTitle')}</label>
                        <textarea class="form-control" id="title" placeholder={testimonials.title} />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="subtitle" class="form-label">{t('testimonialssubTitle')}</label>
                        <textarea class="form-control" id="subtitle" placeholder={testimonials.subTitle} />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="text" class="form-label">{t('testimonialsText')}</label>
                        <textarea class="form-control" id="text" placeholder={testimonials.text} />
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">Güncelle</button>
                </div>
            </form>
            <br/>
            <hr/><br/>                    
            <h6>{t('currentStatus')} : {testimonials.isActive}</h6>

            <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="activite" class="form-label">{t("sectionActivite")}</label>
                            <select id="activite" class="form-select" aria-label="Default select example">
                                <option key="0" value="0">{t("sectionActivite")}</option>
                                <option key="1" value="pasif">{t('passive')}</option>
                                <option key="2" value="aktif">{t('active')}</option>

                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                    </form>
                    <br/><hr/>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>Ana Sayfa Yorum 1</h3>
                    <form onSubmit={test1} >
                        <div class="mb-3">
                            <label for="ratio1" class="form-label">{t('reviewRatePoint')} (Sadece Rakam)</label>
                            <textarea class="form-control" id="ratio1" placeholder={testimonials1.star}/>
                        </div>
                        <div class="mb-3">
                            <label for="message1" class="form-label">{t('reviewRate')} </label>
                            <textarea class="form-control" id="message1" placeholder={testimonials1.message}/>
                        </div>
                        <div class="mb-3">
                            <label for="name1" class="form-label">{t('namesurname')} </label>
                            <textarea class="form-control" id="name1" placeholder={testimonials1.nameSurname}/>
                        </div>
                        <div class="mb-3">
                            <label for="info1" class="form-label">{t('userInfo')} </label>
                            <textarea class="form-control" id="info1" placeholder={testimonials1.info}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('save')}</button>
                        </div>
                        <br/>
                    </form>
                </div>
            </div>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>Ana Sayfa Yorum 2</h3>
                    <form onSubmit={test2} >
                        <div class="mb-3">
                            <label for="ratio2" class="form-label">{t('reviewRatePoint')} (Sadece Rakam)</label>
                            <textarea class="form-control" id="ratio2" placeholder={testimonials2.star}/>
                        </div>
                        <div class="mb-3">
                            <label for="message2" class="form-label">{t('reviewRate')} </label>
                            <textarea class="form-control" id="message2" placeholder={testimonials2.message}/>
                        </div>
                        <div class="mb-3">
                            <label for="name2" class="form-label">{t('namesurname')} </label>
                            <textarea class="form-control" id="name2" placeholder={testimonials2.nameSurname}/>
                        </div>
                        <div class="mb-3">
                            <label for="info2" class="form-label">{t('userInfo')} </label>
                            <textarea class="form-control" id="info2" placeholder={testimonials2.info}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('save')}</button>
                        </div>
                        <br/>
                    </form>
                </div>
            </div>
            <div className='col-sm-4'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>Ana Sayfa Yorum 3</h3>
                    <form onSubmit={test3} >
                        <div class="mb-3">
                            <label for="ratio3" class="form-label">{t('reviewRatePoint')} (Sadece Rakam)</label>
                            <textarea class="form-control" id="ratio3" placeholder={testimonials3.star}/>
                        </div>
                        <div class="mb-3">
                            <label for="message3" class="form-label">{t('reviewRate')} </label>
                            <textarea class="form-control" id="message3" placeholder={testimonials3.message}/>
                        </div>
                        <div class="mb-3">
                            <label for="name3" class="form-label">{t('namesurname')} </label>
                            <textarea class="form-control" id="name3" placeholder={testimonials3.nameSurname}/>
                        </div>
                        <div class="mb-3">
                            <label for="info3" class="form-label">{t('userInfo')} </label>
                            <textarea class="form-control" id="info3" placeholder={testimonials3.info}/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('save')}</button>
                        </div>
                        <br/>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Testimonials;