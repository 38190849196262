import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

export const AdminEditCourse = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingg, setLoadingg] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [sections, setSections] = useState([]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/Update";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    id: data.id,
                    isDeleted: data.isDeleted,
                    courseName: document.getElementById("courseName").value,
                    courseDesc: document.getElementById("courseDesc").value,
                    sortId: document.getElementById("sortId").value,
                    isActive: document.getElementById("isActive").value,
                    sectionId: document.getElementById("sectionId").value,

                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }



    useEffect(() => {
        const fetchsections = async () => {
            setLoadingg(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/GetList";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            sections.push({
                                "id": res.data['data'][i]['id'],
                                "sectionName": res.data['data'][i]['sectionName'],
                            })
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoadingg(false);
        }
        fetchsections();



        if (loadingg) {
            const fetchdata = async () => {
                setLoading(true);
                try {
                    const headerss = {
                        "accept": "*/*",
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': 'false',
                        'Authorization': 'Bearer ' + user.token
                    }
                    let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/GetCourseById";

                    await axios({
                        method: 'GET',
                        url: url,
                        params: {
                            id: params["id"]
                        },
                        headers: headerss
                    }).then(res => {

                        if (res.status === 200) {

                            if (res.data['success']) {
                                setData(res.data['data']);
                            }
                            else {
                                setMsg(res.data['message']);
                            }
                        }
                        else {
                            setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                        }
                    });
                }
                catch (err) {
                    setMsg(err);
                }
                setLoading(false);
            }
            fetchdata();
        }

    }, [])


    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("editCourse")}</h1>
            </div>
            <h4>{msg}</h4>

            <form onSubmit={handleSubmit} >
                <div class="mb-3">
                    <label for="courseName" class="form-label">{t("courseName")}</label>
                    <textarea class="form-control" id="courseName" defaultValue={data.courseName} required />
                </div>
                <div class="mb-3">
                    <label for="courseDesc" class="form-label">{t("courseDescription")}</label>
                    <textarea class="form-control" id="courseDesc" defaultValue={data.courseDesc} required />
                </div>
                <div class="mb-3">
                    <label for="sortId" class="form-label">{t("courseArrangement")}</label>
                    <textarea class="form-control" id="sortId" defaultValue={data.sortId} required />
                </div>
                <div class="mb-3">
                    <label for="sectionId" class="form-label">{t("selectSection")}</label>
                    <select id="sectionId" class="form-select" aria-label="Default select example">

                        {sections ? (
                            sections && sections.map((dt) => {
                                if (dt.id === data.sectionId) {
                                    return (
                                        <option key={dt.id} value={dt.id} selected>{dt.sectionName}</option>
                                    );
                                }
                                else {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.sectionName}</option>
                                    );
                                }
                            })
                        ) : (
                            <p>{t("reloadWarning")}</p>
                        )
                        }
                    </select>
                </div>
                <div class="mb-3">
                    <label for="isActive" class="form-label">{t('isActive')}</label>

                    {data.isActive ? (
                        <select id="isActive" class="form-select" required >
                            <option key="1" value="true" selected>{t('active')}</option>
                            <option key="0" value="false" >{t('passive')}</option>
                        </select>

                    ) : (
                        <select id="isActive" class="form-select" required >
                            <option key="0" value="false" selected>{t('passive')}</option>
                            <option key="1" value="true" >{t('active')}</option>
                        </select>
                    )}
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>
        </div>
    );
};
export default AdminEditCourse;
