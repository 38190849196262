import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const CreateCoupen = () => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const handleSubmitSingle = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/Cupon/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    expiryDate: document.getElementById("expiryDate").value,
                    percentage: document.getElementById("coupenpercentage").value,
                    usageLimit: document.getElementById("coupenusagelimit").value,
                    cuponDescription: document.getElementById("coupendescription").value
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        document.getElementById("coupendescription").value = "";
                        document.getElementById("coupenpercentage").value = "";
                        document.getElementById("coupenusagelimit").value = "";
                        document.getElementById("expiryDate").value = "";
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }

    const handleSubmitMulti = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/Cupon/CreateBulk?number=" + document.getElementById("howmanycoupencreate").value;
            await axios({
                method: 'Post',
                url: url,
                data: {
                    expiryDate: document.getElementById("expiryDatemulti").value,
                    percentage: document.getElementById("coupenpercentagemulti").value,
                    usageLimit: document.getElementById("coupenusagelimitmulti").value,
                    cuponDescription: document.getElementById("coupendescriptionmulti").value
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        document.getElementById("coupendescriptionmulti").value = "";
                        document.getElementById("coupenpercentagemulti").value = "";
                        document.getElementById("coupenusagelimitmulti").value = "";
                        document.getElementById("expiryDatemulti").value = "";
                        document.getElementById("howmanycoupencreate").value = "";
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }
    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createCoupen")}</h1>
            </div>
            <h2>{msg}</h2>

            <form onSubmit={handleSubmitSingle} style={{ border: "1px solid gray", padding: "10px", backgroundColor: "white" }}>
                <h5>{t('createsinglecode')}</h5>
                <div class="mb-3">
                    <label for="coupendescription" class="form-label">{t("coupendescription")}</label>
                    <textarea class="form-control" id="coupendescription" name="coupendescription" required />
                </div>
                <div class="mb-3">
                    <label class="form-label" for="expiryDate">{t("coupenexpiryDate")}</label>
                    <input type="datetime-local" class="form-control" id="expiryDate" name="expiryDate" required />
                </div>
                <div class="mb-3">
                    <label class="form-label" for="coupenpercentage">{t("coupenpercentage")}</label>
                    <textarea class="form-control" id="coupenpercentage" name="coupenpercentage" required />
                </div>

                <div class="mb-3">
                    <label class="form-label" for="coupenusagelimit">{t("coupenusagelimit")}</label>
                    <textarea class="form-control" id="coupenusagelimit" name="coupenusagelimit" required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                </div>

            </form>
            <hr />
            <form onSubmit={handleSubmitMulti} style={{ border: "1px solid gray", padding: "10px", backgroundColor: "white" }}>
                <h5>{t('createmultiplecode')}</h5>

                <div class="mb-3">
                    <label for="howmanycoupencreate" class="form-label">{t("howmanycoupencreate")}</label>
                    <textarea class="form-control" id="howmanycoupencreate" name="howmanycoupencreate" required />
                </div>

                <div class="mb-3">
                    <label for="coupendescriptionmulti" class="form-label">{t("coupendescription")}</label>
                    <textarea class="form-control" id="coupendescriptionmulti" name="coupendescriptionmulti" required />
                </div>

                <div class="mb-3">
                    <label class="form-label" for="expiryDatemulti">{t("coupenexpiryDate")}</label>
                    <input type="datetime-local" class="form-control" id="expiryDatemulti" name="expiryDatemulti" required />
                </div>
                <div class="mb-3">
                    <label class="form-label" for="coupenpercentagemulti">{t("coupenpercentage")}</label>
                    <textarea class="form-control" id="coupenpercentagemulti" name="coupenpercentagemulti" required />
                </div>

                <div class="mb-3">
                    <label class="form-label" for="coupenusagelimitmulti">{t("coupenusagelimit")}</label>
                    <textarea class="form-control" id="coupenusagelimitmulti" name="coupenusagelimitmulti" required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                </div>

            </form>
        </div>
    );
};
export default CreateCoupen;
